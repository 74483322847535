import { SecurityCultureSurvey } from '@/client/security-culture/types';
import { SemiDoughnutChart } from '@/ui/charts/semi-doughnut-chart';
import { FlexContainer } from '@/ui/styled-ui';
import { parseFloatingNumber } from '@/utils/helpers/ui.helper';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CardInfo } from './CardInfo';
import { SCLegend } from './SCLegend';

const StyledCardContainer = styled(FlexContainer)`
  grid-area: sc;
  width: 100%;
  min-width: min-content;
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: var(--medium-padding);

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }
    }
  }
`;

const StyledValueSpan = styled.span<{ isMainDashboard?: boolean }>`
  font-size: ${({ isMainDashboard }) =>
    isMainDashboard
      ? 'calc(2 * var(--medium-font-size))'
      : 'var(--heading-xlarge-font-size)}'};
  font-weight: 700;
  color: ${({ color }) =>
    color || 'inherit'}; // Using the prop with a fallback value
`;

const StyledValueChangeSpan = styled.span<{
  difference: number;
  isMainDashboard?: boolean;
}>`
  font-size: ${({ isMainDashboard }) =>
    isMainDashboard ? 'var(--small-font-size)' : 'var(--body-font-size)'};
  font-weight: ${({ isMainDashboard }) =>
    isMainDashboard ? '500' : undefined};
  color: var(--green-main);
  color: ${({ difference }) =>
    difference > 0
      ? 'var(--green-main)'
      : difference < 0
      ? 'var(--red-main)'
      : 'black'};
`;

type SecurityCultureScoreProps = {
  survey: SecurityCultureSurvey | undefined;
  isLoading?: boolean;
  isMainDashboard?: boolean;
  isEmpty?: boolean;
  hasPreviousScore?: boolean;
};

export const SecurityCultureScore: React.FC<SecurityCultureScoreProps> = ({
  survey,
  isLoading = false,
  isMainDashboard = false,
  isEmpty = false,
  hasPreviousScore,
}) => {
  const { t } = useTranslation();
  const [scoreDifference, setScoreDifference] = useState(0);

  useEffect(() => {
    const scoreDiff = Number(survey?.score) - Number(survey?.previousScore);
    setScoreDifference(Number(scoreDiff));
  }, [survey]);

  return (
    <StyledCardContainer
      id="sc-score-card"
      direction="column"
      gap={24}
      justify="space-between"
    >
      {isLoading ? (
        <Skeleton size="100%" />
      ) : (
        <>
          <CardInfo
            id="security-culture-score"
            title={
              isMainDashboard
                ? t('security.culture')
                : t('security.culture.score')
            }
            redTitle={isEmpty ? t('security.culture.notMeasured') : undefined}
            subtitle={
              isMainDashboard && !isEmpty
                ? t('security.culture.score.subtitle')
                : undefined
            }
            tooltip={t('security.culture.score.tooltip')}
            style={isMainDashboard ? { fontSize: '14px', fontWeight: 500 } : {}}
          />
          <FlexContainer
            align="flex-start"
            justify="space-between"
            gap={isMainDashboard ? 0 : 24}
          >
            <FlexContainer direction="column" align="flex-start">
              <StyledValueSpan isMainDashboard={isMainDashboard}>
                {parseFloatingNumber(survey?.score)}
              </StyledValueSpan>
              {survey?.score !== null &&
                !!survey?.previousScore &&
                hasPreviousScore && (
                  <StyledValueChangeSpan
                    difference={scoreDifference}
                    isMainDashboard={isMainDashboard}
                  >
                    {scoreDifference > 0 ? '+' : '-'}{' '}
                    {parseFloatingNumber(Math.abs(scoreDifference))}
                  </StyledValueChangeSpan>
                )}
            </FlexContainer>

            <SemiDoughnutChart
              id="sc-score"
              value={parseFloatingNumber(survey?.score)}
              controlValue={parseFloatingNumber(survey?.benchmarkScore)}
              valueTooltipSuffix={t('security.culture.target.group')}
              controlValueTooltipSuffix={t(
                'security.culture.other.organisations',
              )}
              isMainDashboard={isMainDashboard}
            />
          </FlexContainer>
          {isMainDashboard && <SCLegend isMainDashboard />}
        </>
      )}
    </StyledCardContainer>
  );
};
