import { client } from '@/client';
import {
  AccountAD,
  AccountGoogleSSOConfig,
  UpdateAccountADRequest,
  UpdateGoogleSSOConfigRequest,
} from '@/client/accounts/types';
import {
  AccountGoogleWorkspace,
  UpdateGoogleWorkspaceRequest,
} from '@/client/accounts/types/account-google-workspace.type';
import { MessageResponseModel } from '@/client/models';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  ForceAccountSyncMutation,
  GenericForbiddenApiError,
  SaveAccountADMutation,
  SaveAccountMutation,
} from './types';

const queryKey = 'accountGoogleWorkspace';

export const useGoogleWorkspaceConfig = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<AccountGoogleWorkspace> & {
  accountGoogleWorkspace: AccountGoogleWorkspace | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, accountId],
    () =>
      accountId
        ? client.googleWorkspace.getAccountGoogleWorkspaceConfig(accountId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accountGoogleWorkspace: data,
    ...(rest as UseQueryResult<AccountGoogleWorkspace>),
  };
};

export const useSavGoogleWorkspaceConfig = (): {
  create: (payload: UpdateGoogleWorkspaceRequest) => Promise<AccountAD>;
} & SaveAccountADMutation => {
  const { mutateAsync, ...rest } = useMutation<
    AccountGoogleWorkspace,
    GenericForbiddenApiError,
    UpdateGoogleWorkspaceRequest
  >(
    'addAccountGoogleWorkspaceConfig',
    (payload: UpdateGoogleWorkspaceRequest) =>
      client.googleWorkspace.saveAccountGoogleWorkspaceConfig(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useForceGoogleWorkspaceSync = (): {
  sync: (accountId: string) => Promise<MessageResponseModel>;
} & ForceAccountSyncMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('forceADSync', (accountId: string) =>
    client.googleWorkspace.forceSync(accountId),
  );

  return { sync: mutateAsync, ...(rest as any) };
};

export const useGoogleSSOConfig = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<AccountGoogleSSOConfig> & {
  ssoConfig: AccountGoogleSSOConfig | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    ['googleSSOConfig', accountId],
    () =>
      accountId
        ? client.googleWorkspace.getGoogleSSOConfig(accountId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    ssoConfig: data,
    ...(rest as UseQueryResult<AccountGoogleSSOConfig>),
  };
};

export const useUpdateGoogleSSOConfig = (): {
  update: (
    payload: UpdateGoogleSSOConfigRequest,
  ) => Promise<MessageResponseModel>;
} & SaveAccountMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    UpdateGoogleSSOConfigRequest
  >('ssoConfig', (payload: UpdateGoogleSSOConfigRequest) =>
    client.googleWorkspace.saveGoogleSSOConfig(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};
