import { SurveyFeedback } from '@/client/security-culture/types';
import { FlexContainer } from '@/ui/styled-ui';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const FeedbackContainer = styled(FlexContainer)`
  align-items: flex-start;
  justify-content: space-between;
  margin-top: var(--default-padding);
  flex-direction: column;
  gap: var(--medium-padding);
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding)
    calc(2 * var(--medium-padding));
  border: 1px solid var(--beige-main);
  border-radius: var(--medium-border-radius);
  overflow: hidden;

  .feedback-title {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: var(--xsmall-padding);
  }

  .questions {
    margin-bottom: 6px;
    height: 100%;
    border-bottom: 1px solid var(--gray-dark) !important;
  }
`;

type SecurityCultureCardProps = {
  title: string;
  content?: ReactNode;
  titleAddition?: ReactNode;
  take?: number;
  skip?: number;
  count?: number;
  onPageChange?: (value: number) => void;
  onRowsChange?: (value: number) => void;
  className?: string;
  id?: string;
};

export const SecurityCultureCard: React.FC<SecurityCultureCardProps> = ({
  title,
  content,
  titleAddition,
  take,
  skip,
  count,
  onPageChange,
  onRowsChange,
  className,
  id,
}) => {
  const { t } = useTranslation();

  return (
    <div id={id}>
      <FeedbackContainer className={className}>
        {titleAddition ? (
          <FlexContainer justify="space-between" align="flex-start">
            <div className="feedback-title">{title}</div>
            {titleAddition}
          </FlexContainer>
        ) : (
          <div className="feedback-title">{title}</div>
        )}

        <>{content}</>
      </FeedbackContainer>
      {!!count && count > 10 && onRowsChange && onPageChange && (
        <Paginator
          className="justify-content-end pb-0"
          rows={take}
          first={skip}
          totalRecords={count}
          pageLinkSize={3}
          onPageChange={(event: PaginatorPageChangeEvent) => {
            onRowsChange(event.rows);
            onPageChange(event.first);
          }}
          template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
          currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
            'filter.of',
          )} {totalRecords}`}
          rowsPerPageOptions={[10, 20]}
        />
      )}
    </div>
  );
};
