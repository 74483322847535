import { languageDisplayCode, LanguagesEnum } from '@/api/enums';
import { CourseFormState } from '@/client/courses';
import { File } from '@/client/files';
import { DateFormats } from '@/system-settings/enums';
import { CourseFileUpload } from '@/ui/course-file-upload';
import { FlexContainer } from '@/ui/styled-ui';
import { TabSwitchButtons } from '@/ui/tab-switch-buttons';
import { sortLanguagesWithPriority } from '@/utils/helpers';
import { FormikValues } from 'formik';
import { t } from 'i18next';
import { without } from 'lodash';
import moment from 'moment';
import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppButton } from '../buttons';

const StyledFlexContainer = styled(FlexContainer)`
  position: relative;
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  padding: var(--default-padding);
  margin-top: 6px;
`;

const StyledLabel = styled.span`
  display: block;
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  font-weight: 500;
`;

const StyledPanel = styled(Panel)`
  .p-panel-header,
  .p-panel-content {
    padding: 0;
    border: 0;
  }

  .p-panel-header {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &.p-panel .p-panel-header {
    background: transparent;
    &:has(:not(.p-panel-icons)) {
      color: var(--black-main);
    }
  }

  .p-panel-icons {
    display: flex;
    margin-left: var(--small-padding);
  }

  .p-panel-header-icon {
    width: 20px;
    height: 20px;
  }
`;

const StyledaAppButton = styled(AppButton)`
  position: absolute;
  top: var(--xxsmall-padding);
  right: var(--xxsmall-padding);
`;

const StyledTextSpan = styled.span`
  font-size: var(--xxsmall-font-size);
  line-height: var(--small-line-height);
  color: var(--black-main);
`;

type CourseFilesProps = {
  values: CourseFormState;
  label: string;
  selfHosted?: boolean;
  isCreate?: boolean;
  isDisabled: boolean;
  isModule?: boolean;
  index?: number;
  setFieldValue: (field: string, value: any) => void;
  setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
};

export const CourseFiles: React.FC<CourseFilesProps> = ({
  values,
  label,
  selfHosted,
  isCreate,
  isDisabled,
  isModule,
  index = 0,
  setFieldValue,
  setIsSubmitDisabled,
}) => {
  const [selectedFileTab, setSelectedFileTab] = useState<
    keyof typeof LanguagesEnum
  >(languageDisplayCode[LanguagesEnum.EN] as keyof typeof LanguagesEnum);

  const [requiredTranslations, setRequiredTranslations] = useState([
    languageDisplayCode[LanguagesEnum.EN],
  ]);

  const [preselectedFile, setPreselectedFile] = useState<File | null>(null);

  const [isTabsDisabled, setIsTabsDisabled] = useState<boolean>(false);

  const [validOptions, setValidOptions] = useState([] as string[]);
  const checkValidOptions = (values: FormikValues) => {
    return values.courseLanguages
      .filter((languageCode: string) => {
        if (
          values.courseFiles[index] &&
          values.courseFiles[index][languageCode]
        ) {
          return !!values.courseFiles[index][languageCode].file;
        }
      })
      .map((languageCode: string) => languageDisplayCode[languageCode]);
  };

  useEffect(() => {
    if (values?.courseLanguages) {
      setRequiredTranslations(
        values.courseLanguages.map(
          (languageCode: string) => languageDisplayCode[languageCode],
        ),
      );
    }
    setValidOptions(checkValidOptions(values));
    if (values?.courseFiles) {
      const language =
        isCreate || selfHosted
          ? LanguagesEnum[selectedFileTab]
          : LanguagesEnum.EN;

      setPreselectedFile(
        values.courseFiles[index] && values.courseFiles[index][language]?.file,
      );
    }
  }, [values, selectedFileTab]);

  const prevSelfHosted = useRef(selfHosted);
  useEffect(() => {
    if (prevSelfHosted.current === false && selfHosted === true) {
      setFieldValue('courseFiles', [
        {
          [LanguagesEnum.EN]: {
            file: null,
          },
        },
      ]);
    }

    prevSelfHosted.current = selfHosted; // Update the previous value
  }, [selfHosted]);

  useEffect(() => {
    const fileTab = values.courseLanguages?.length
      ? languageDisplayCode[values.courseLanguages[0]]
      : languageDisplayCode[LanguagesEnum.EN];
    setSelectedFileTab(fileTab as keyof typeof LanguagesEnum);
  }, [values.courseLanguages]);

  useEffect(() => {
    setIsSubmitDisabled(isTabsDisabled);
  }, [isTabsDisabled]);

  const handleFileUpload = (file: File | null) => {
    if (file?.id) {
      setFieldValue(
        `courseFiles.${index}.${LanguagesEnum[selectedFileTab]}.file`,
        file,
      );
    } else {
      setFieldValue(
        `courseFiles.${index}.${LanguagesEnum[selectedFileTab]}.file`,
        null,
      );
    }
  };

  // Using created date to show last updated date, because on upload / reupload new file object is created.
  const FileLastUpdated = () => {
    if (
      preselectedFile &&
      preselectedFile?.signedUrl &&
      preselectedFile?.created &&
      !isDisabled
    )
      return (
        <StyledTextSpan className="mt-1">
          {t('course.file.lastUpdated', {
            date: moment(preselectedFile?.created).format(DateFormats.DATEONLY),
          })}
        </StyledTextSpan>
      );
  };

  return (
    <>
      <StyledPanel
        toggleable={isModule}
        collapseIcon="pi pi-chevron-up"
        expandIcon="pi pi-chevron-down"
        header={
          <StyledLabel className="mt-4">
            {label} {!values.releaseDate && <span className="red"> *</span>}
          </StyledLabel>
        }
      >
        {isCreate || selfHosted ? (
          <StyledFlexContainer justify="flex-start" direction="column">
            <TabSwitchButtons
              options={sortLanguagesWithPriority(
                values?.courseLanguages?.map(
                  (languageCode: string) => languageDisplayCode[languageCode],
                ) ?? [LanguagesEnum.EN],
              )}
              requiredOptions={requiredTranslations}
              validOptions={validOptions}
              isDisabled={isDisabled || isTabsDisabled}
              onToggle={(option) =>
                setSelectedFileTab(option as keyof typeof LanguagesEnum)
              }
              defaultSelected={selectedFileTab}
            />
            {isModule && (
              <StyledaAppButton
                icon="pi pi-times"
                severity="secondary"
                size="xs"
                type="text"
                rounded
                onClick={() =>
                  setFieldValue(
                    'courseFiles',
                    without(values.courseFiles, values.courseFiles[index]),
                  )
                }
                isDisabled={isDisabled || values?.courseFiles?.length === 1}
              />
            )}
            <div
              className={
                values?.courseLanguages?.length
                  ? 'field w-full mt-4 mb-0'
                  : 'field w-full mb-0'
              }
            >
              <CourseFileUpload
                key={selectedFileTab}
                onFileUpload={handleFileUpload}
                preselectedFile={preselectedFile}
                type={values.type}
                isDisabled={
                  isDisabled || (selfHosted && !values?.courseLanguages?.length)
                }
                isModule={isModule}
                setIsTabsDisabled={setIsTabsDisabled}
              />
              {FileLastUpdated()}
            </div>
          </StyledFlexContainer>
        ) : (
          <StyledFlexContainer justify="flex-start" direction="column">
            <div className="field w-full mt-0 mb-0">
              <CourseFileUpload
                key={selectedFileTab}
                onFileUpload={(file: File | null) => {
                  setFieldValue(
                    `courseFiles.${index}.${LanguagesEnum.EN}.file`,
                    file,
                  );
                }}
                setIsTabsDisabled={setIsTabsDisabled}
                preselectedFile={preselectedFile}
                type={values.type}
                isDisabled={isDisabled}
              />
              {FileLastUpdated()}
            </div>
          </StyledFlexContainer>
        )}
      </StyledPanel>
    </>
  );
};
