import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account, displayAccountSyncStatus } from '@/client/accounts';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { CYBERPEDIA_AD_AND_SSO_URL } from '@/common/constants/external-urls';
import { TranslationFunctionType } from '@/common/types';
import { AzureADForm } from '@/components/accounts/forms/AzureADForm';
import { SSOForm } from '@/components/accounts/forms/SSOForm';
import { useAzureADHandler } from '@/hooks/integrations/useAzureAD.hook';
import { useSSOHandler } from '@/hooks/integrations/useSSOConfig.hook';
import { useAccount } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { InlineMenu } from '@/ui/inline-menu';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import moment from 'moment';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountHeader } from './AccountHeader';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
): MenuItem[] => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('microsoft'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS_MICROSOFT](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountMicrosoftPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { can } = usePermission();
  const { account, isLoading: isAccountLoading } = useAccount({
    accountId: id,
  });

  const { initialValues, isLoading, loadingState, handleSubmit } =
    useSSOHandler(id as string);
  const {
    initialValues: initialValuesAD,
    syncState,
    saveADConfig,
    handleSubmit: handleSubmitAzureAD,
    handleForceADSync,
    isADLoading,
    accountAd,
  } = useAzureADHandler(id as string);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const menuItems = [{ label: t('user.manage') }, { label: t('sso') }];

  const items = menuItems.map((item, index) => ({
    ...item,
    className: activeIndex === index ? 'active' : '',
    command: () => setActiveIndex(index),
  }));

  return (
    <>
      {isLoading || isAccountLoading || !account || isADLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && (
            <AppBreadCrumb
              model={getPathItems(account, currentAccount, can, t)}
            />
          )}

          <AccountHeader selectedAccount={account} />

          <h3 className="mt-4 mb-4">{t('account.microsoft.config')}</h3>
          <FlexContainer
            className="mb-5"
            gap={24}
            justify="flex-start"
            align="flex-start"
          >
            <InlineMenu items={items} />
            <div>
              {activeIndex === 0 && (
                <p className="m-0">
                  {t(
                    'account.microsoft.integration.userManagement.description',
                    {
                      platformName: account?.platformSettings?.name,
                    },
                  )}
                </p>
              )}
              {activeIndex === 1 && (
                <p className="m-0">
                  {t('account.microsoft.integration.sso.description', {
                    platformName: account?.platformSettings?.name,
                  })}
                </p>
              )}
              <p className="m-0">
                {t('generic.readReadMoreHere', {
                  platformName: account?.platformSettings?.name,
                })}
                <span className="ml-1 mr-1">&gt;</span>
                <a
                  href={CYBERPEDIA_AD_AND_SSO_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {CYBERPEDIA_AD_AND_SSO_URL}
                </a>
              </p>
            </div>
          </FlexContainer>
          {activeIndex === 0 && (
            <>
              <>
                {accountAd?.meta?.lastSync && accountAd?.meta?.status && (
                  <Message
                    severity="info"
                    text={`${t('sync.lastSync')} ${moment(
                      accountAd?.meta?.lastSync,
                    ).format(DateFormats.TIMEDATE)}, ${displayAccountSyncStatus(
                      accountAd?.meta?.status,
                      t,
                    )}`}
                  />
                )}

                {accountAd?.meta?.lastSyncErrorLog && (
                  <Message
                    className="mt-2 mb-4"
                    severity="warn"
                    text={accountAd?.meta?.lastSyncErrorLog}
                  />
                )}

                <AzureADForm
                  initialValues={initialValuesAD}
                  onSubmit={handleSubmitAzureAD}
                  onSync={() => handleForceADSync(id as string)}
                  account={account}
                  state={queryStateConverter(saveADConfig)}
                  syncState={syncState}
                  disabled={
                    account?.isSystem &&
                    currentUser?.role.code !== SystemRoles.DEVELOPER
                  }
                />
              </>
            </>
          )}
          {activeIndex === 1 && (
            <>
              <h3>{t('sso.config')}</h3>
              <SSOForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                state={loadingState}
                account={account}
                disabled={
                  !account?.subdomain ||
                  (account?.isSystem &&
                    currentUser?.role.code !== SystemRoles.DEVELOPER)
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};
