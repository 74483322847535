import { CourseSchedulePeriodUnit } from '@/client/planner/types';
import { FlexContainer } from '@/ui/styled-ui';
import { parsePeriodStringToUserReadable } from '@/utils/helpers';
import { t } from 'i18next';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledPeriodLabel = styled.label`
  display: flex;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid var(--gray-dark);
  background: var(--white-main);
  padding: 10px var(--default-padding);
  align-items: center;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);

  &:hover {
    cursor: pointer;
    border-color: var(--red-main);
  }
`;

const StyledH2 = styled.h2`
  font-size: var(--heading-small-font-size);
  line-height: var(--heading-small-line-height);
  margin-top: 0;
`;

const StyledDropdown = styled(Dropdown)`
  width: 150px;
`;

type PlannerPeriodSelectorProps = {
  periodValue?: number;
  periodUnit?: CourseSchedulePeriodUnit;
  onChange: (periodValue: number, periodUnit: CourseSchedulePeriodUnit) => void;
};

export const PlannerPeriodSelector: React.FC<PlannerPeriodSelectorProps> = ({
  periodValue,
  periodUnit,
  onChange,
}) => {
  const op = useRef<OverlayPanel>(null);
  const [periodNumber, setPeriodNumber] = useState<number>(0);
  const [periodString, setPeriodString] = useState<CourseSchedulePeriodUnit>(
    CourseSchedulePeriodUnit.DAYS,
  );
  const [periodReadableString, setPeriodReadableString] = useState(
    parsePeriodStringToUserReadable(periodValue, periodUnit),
  );

  const availablePeriodStrings = [
    { label: t('days'), value: CourseSchedulePeriodUnit.DAYS },
  ];

  useEffect(() => {
    if (periodValue !== undefined && periodUnit) {
      setPeriodNumber(periodValue);
      setPeriodString(periodUnit);
      setPeriodReadableString(
        parsePeriodStringToUserReadable(periodValue, periodUnit),
      );
    }
  }, [periodValue, periodUnit]);

  return (
    <>
      <StyledPeriodLabel
        className="w-full"
        onClick={(e: SyntheticEvent) => op?.current?.toggle(e)}
      >
        {periodReadableString}
      </StyledPeriodLabel>
      <OverlayPanel
        ref={op}
        dismissable
        showCloseIcon
        onHide={() => onChange(periodNumber, periodString)}
      >
        <Tooltip target=".pi-info-circle" />
        <StyledH2>
          {t('planner.enrollAfter')}
          <i
            className="pi pi-info-circle ml-2"
            data-pr-tooltip={t('planner.autoEnroll.tooltip')}
          />
        </StyledH2>
        <FlexContainer>
          <InputNumber
            min={0}
            value={periodNumber}
            onValueChange={(e: InputNumberValueChangeEvent) =>
              setPeriodNumber(e.value || 0)
            }
          />
          <StyledDropdown
            value={periodString}
            options={availablePeriodStrings}
            optionValue="value"
            optionLabel="label"
            className="ml-3"
            onChange={(e: DropdownChangeEvent) => setPeriodString(e.value)}
          />
        </FlexContainer>
      </OverlayPanel>
    </>
  );
};
