import { Skeleton } from 'primereact/skeleton';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCardReports = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: min-content;

  .reports-card-title {
    font-weight: 500;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    text-transform: uppercase;
    margin: 0;
  }

  .reports-card-count {
    font-weight: 700;
    font-size: calc(2 * var(--medium-font-size));
    line-height: calc(2 * var(--xsmall-line-height));
    margin: var(--small-padding) 0;
  }

  .reports-card-description {
    font-size: var(--xsmall-font-size);
    line-height: var(--xsmall-line-height);
    font-weight: 500;
  }
`;

type CardReportsProps = {
  title: string;
  count: number | undefined;
  isLoading: boolean;
  description?: string | ReactNode | undefined;
  id?: string;
};

const CardReports: React.FC<CardReportsProps> = ({
  title,
  count,
  isLoading,
  description,
  id,
  ...rest
}) => (
  <StyledCardReports id={id} {...rest}>
    <h3 className="reports-card-title title">{title}</h3>
    {isLoading ? (
      <>
        <Skeleton className="reports-card-count" width="15%" height="32px" />
      </>
    ) : (
      <>
        <span className="reports-card-count">{count}</span>
        {description && (
          <span className="reports-card-description">{description}</span>
        )}
      </>
    )}
  </StyledCardReports>
);

export default CardReports;
