import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { SendTestEmailFormValues } from '@/client/campaigns';
import {
  EmailType,
  SaveSystemEmailTemplate,
  SystemEmailsTemplatesFormValues,
} from '@/client/system-emails';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TemplateSystemEmailsTemplatesBuilderLegend,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { BeePluginEmailContainer } from '@/components/bee-plugin';
import { SendTestEmailModal } from '@/components/campaigns';
import { ClipboardContainer } from '@/components/templates';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { default as plainTemplate } from '@/pages/email-templates/templates/plain-template.json';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AbsoluteLoader, FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { Steps } from 'primereact/steps';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { SystemEmailTemplateInformationForm } from '../components/forms';
import { isNotValidHandlebarsEmailTemplateCheck } from '../helpers';

const StyledFormWrapper = styled.div`
  padding: var(--default-padding);
`;

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.system.emails'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('templates'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('button.create'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_CREATE](),
    template: AppBreadCrumbTemplate,
  },
];

const steps = (t: TranslationFunctionType) => [
  {
    label: t('templates.info'),
  },
  {
    label: t('templates.structure'),
  },
];

const initialValues = (location: Location) => ({
  type: location?.state?.type || undefined,
  language: location?.state?.language || undefined,
  subject: location?.state?.subject || '',
  text: location?.state?.text || '',
  active: location?.state?.active || false,
});

export const SystemEmailsTemplatesCreatePage = () => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const { setDialogData } = useContext(DialogContext);

  const [activeStep, setActiveStep] = useState(0);
  const [blockNextStep, setBlockNextStep] = useState(true);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // this is used to prevent the form from reinitializing on every change made
  const [initialFormValues, setInitialFormValues] =
    useState<SystemEmailsTemplatesFormValues>(initialValues(location));
  const [formValues, setFormValues] = useState<SystemEmailsTemplatesFormValues>(
    initialValues(location),
  );

  // Set initial template for Bee
  const [initialTemplate, setInitialTemplate] = useState(
    (location?.state?.editor ?? plainTemplate) as object,
  );

  // Unblock step 2 if the form is valid
  const toggleStepBlocked = (isValid: boolean) => {
    setBlockNextStep(!isValid);
  };

  const showExitPrompt = () => {
    const formValuesToCompare = initialValues(location);
    return !isTemplateChanged && isEqual(formValuesToCompare, formValues);
  };

  // Track changes to the template
  const handleChangeTemplate = (page: any) => {
    setInitialTemplate(page);
    setIsTemplateChanged(true);
  };

  const handleNextStep = async (
    type: EmailType,
    language: string,
    step: number,
  ) => {
    try {
      const systemEmailsTemplates =
        await client.systemEmailsTemplatesService.getSystemEmailsTemplates({
          filters: [
            equal('type', type),
            equal('language', language),
            equal('account', account?.isSystem ? null : account?.id),
          ],
        });

      if (systemEmailsTemplates.count) {
        toast?.error(
          t('system.email.template.combination'),
          t('system.email.template.unavailable.combination'),
        );

        return;
      }

      setActiveStep(step);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const triggerNextStep = async () =>
    await handleNextStep(formValues.type as EmailType, formValues.language, 1);

  const handleSaveEmailTemplate = async (page: object, html: string) => {
    try {
      if (!account) {
        return;
      }

      const isNotValid = isNotValidHandlebarsEmailTemplateCheck(
        html,
        toast,
        formValues.subject,
        formValues.text,
        true,
      );

      if (isNotValid) {
        setShowLoader(false);
        return;
      }

      const params: SaveSystemEmailTemplate = {
        type: formValues?.type as EmailType,
        language: formValues?.language as string,
        subject: formValues?.subject as string,
        text: formValues?.text as string,
        editor: page,
        html: html,
        // TODO: Uncomment when we integrate our own Save button
        // editor: initialTemplate || plainTemplate,
        // html: '<!DOCTYPE html></html>', // Find a way to get the html form BEE
        active: formValues?.active,
        account: account?.isSystem ? null : (account?.id as string),
      };

      setShowLoader(true);
      await client.systemEmailsTemplatesService.saveSystemEmailTemplate(params);
      setShowLoader(false);

      toast?.success(t('toast.success'), t('templates.email.created'));

      navigate(RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES]());
    } catch (e) {
      setShowLoader(false);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleExit = () => {
    const navigateUrl =
      RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES]();

    if (showExitPrompt()) {
      navigate(navigateUrl);

      return;
    }

    setDialogData({
      type: 'confirmation',
      show: true,
      header: t('dialog.unsavedChanges'),
      message: t('dialog.unsavedChanges.confirm'),
      onAccept: () => navigate(navigateUrl),
    });
  };

  const [showSendTestEmail, setShowSendTestEmail] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [testHtml, setTestHtml] = useState('');
  const {
    lastMessage,
    setNotificationParam: setNotifyId,
    notificationParam: notifyId,
  } = useNotifications(
    client.systemEmailsTemplatesService.systemEmailNotifyUrl,
  );

  useEffect(() => {
    if (!notifyId) return;
    handleNotifyEmailSent(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyEmailSent = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'system-email.template.send.test.email.success',
        'system-email.template.send.test.email.failed',
      ].includes(messages?.data?.event)
    )
      return;

    if (
      messages?.data?.event === 'system-email.template.send.test.email.success'
    )
      toast?.success(
        t('toast.success'),
        t('system.email.testEmail.sent'),
        5000,
      );
    if (
      messages?.data?.event === 'system-email.template.send.test.email.failed'
    )
      toast?.info(t('toast.info'), t('system.email.testEmail.notSent'), 5000);

    setNotifyId(undefined);
    setShowSendTestEmail(false);
    setIsSendingTestEmail(false);
  };

  const handleSendTestEmail = async (email: string, html: string) => {
    try {
      const notifyId = v4();
      setNotifyId(notifyId);
      await client.systemEmailsTemplatesService.sendTestSystemEmail({
        email,
        notifyId,
        subject: formValues.subject,
        text: formValues.text,
        html,
      });
      setIsSendingTestEmail(true);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <FlexContainer
      height="100%"
      direction="column"
      align="flex-start"
      justify="flex-start"
    >
      {showLoader && <AbsoluteLoader message={t('templates.saving')} />}
      <div id="template-header-container">
        <AppBreadCrumb model={pathItems(account as Account, t)} />
        <h1>{t('system.email.templates.create')}</h1>

        <Steps
          model={steps(t)}
          activeIndex={activeStep}
          onSelect={async (e) => {
            // Check if the combination of type and language is available only when switching from step 0 to 1
            if (e.index === 1) {
              await triggerNextStep();
              return;
            }

            setInitialFormValues(formValues);
            setActiveStep(e.index);
          }}
          readOnly={blockNextStep}
        />
        <ClipboardContainer
          clipboardItems={TemplateSystemEmailsTemplatesBuilderLegend(
            t,
            formValues?.type as EmailType,
          )}
        />
      </div>
      <div id="template-content-container">
        {activeStep === 0 && (
          <StyledFormWrapper>
            <SystemEmailTemplateInformationForm
              initialValues={
                initialFormValues as SystemEmailsTemplatesFormValues
              }
              onInputChange={setFormValues}
              onValidateForm={toggleStepBlocked}
            />
          </StyledFormWrapper>
        )}
        {activeStep === 1 && (
          <BeePluginEmailContainer
            template={initialTemplate || plainTemplate}
            handleChange={handleChangeTemplate}
            handleSave={handleSaveEmailTemplate}
            handleSendTest={(html) => {
              setShowSendTestEmail(true);
              setTestHtml(html);
            }}
          />
        )}
      </div>
      <FlexContainer id="template-footer-container" justify="space-between">
        <div>
          <AppButton
            type="outlined"
            severity="secondary"
            label={t('button.exit')}
            className="mr-3"
            onClick={handleExit}
          />
          {/* TODO: Uncomment when we find a way to get the html onChange */}
          {/* <AppButton
            severity="secondary"
            label={t('button.save)}
            onClick={handleSaveEmailTemplate}
            isDisabled={blockNextStep}
          /> */}
        </div>

        <SendTestEmailModal
          email={currentUser?.email as string}
          visible={showSendTestEmail}
          extended={false}
          isSending={isSendingTestEmail}
          onSubmit={(values: SendTestEmailFormValues) => {
            handleSendTestEmail(values.email, testHtml);
          }}
          onHide={() => setShowSendTestEmail(false)}
        />

        <div>
          {activeStep > 0 && (
            <AppButton
              severity="secondary"
              label={t('button.back')}
              icon="pi pi-arrow-left"
              iconPos="left"
              onClick={() => {
                setInitialFormValues(formValues);
                setActiveStep(0);
              }}
              className="mr-3"
            />
          )}
          {activeStep < 1 && (
            <AppButton
              severity="secondary"
              label={t('button.next')}
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={triggerNextStep}
              isDisabled={blockNextStep || activeStep === 1}
            />
          )}
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};
