import {
  ListSurveyFeedbackResponse,
  SurveyFeedback,
} from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { ChartIcon } from '@/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CardInfo } from './CardInfo';

const StyledCardContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: var(--medium-padding);

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }
    }
  }
`;

const StyledFeedbackContainer = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  padding: var(--xsmall-padding) 0;
  gap: var(--medium-padding);

  &:last-child {
    border-bottom: none;
  }

  svg {
    flex-shrink: 0;
  }
`;

type SCFeedbackProps = {
  feedback: ListSurveyFeedbackResponse | undefined;
  surveyId: string;
};

export const SCFeedback: React.FC<SCFeedbackProps> = ({
  feedback,
  surveyId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledCardContainer
      id="sc-feedback-card"
      direction="column"
      gap={24}
      justify="flex-start"
      flex="0.7"
    >
      <CardInfo
        id="security-culture-feedback"
        title={t('security.culture.feedback.from.employees')}
        tooltip={t('security.culture.feedback.from.employees.tooltip')}
      />
      <FlexContainer direction="column" className="children" height="100%">
        {feedback?.result?.map((item: SurveyFeedback) => (
          <StyledFeedbackContainer justify="flex-start" key={item.id}>
            <ChartIcon />
            <p>{item.description}</p>
          </StyledFeedbackContainer>
        ))}
        {!feedback?.count && (
          <p className="m-auto">{t('security.culture.no.data')}</p>
        )}
      </FlexContainer>
      {!!feedback?.count && (
        <FlexContainer direction="row" justify="flex-end" className="mt-auto">
          <AppButton
            type="outlined"
            label={t('button.view.all')}
            onClick={() =>
              navigate(
                RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_FEEDBACK](
                  surveyId,
                ),
              )
            }
          />
        </FlexContainer>
      )}
    </StyledCardContainer>
  );
};
