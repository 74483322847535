import { equal, handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import {
  ListSurveyCategoriesResponse,
  SecurityCultureSurvey,
  SurveyStatus,
} from '@/client/security-culture/types';
import { Subjects } from '@/client/users';
import {
  SCScoreBars,
  SecurityCultureDemoScore,
  SecurityCultureScore,
} from '@/components/security-culture';
import { useSurveys } from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import React, { useEffect, useState } from 'react';

export const SCMainDashboard = () => {
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const [survey, setSurvey] = useState<SecurityCultureSurvey>();
  const [categories, setCategories] = useState<ListSurveyCategoriesResponse>();
  const [areCategoriesLoading, setAreCategoriesLoading] = useState(true);
  const { canUseFeature } = useFeatureFlag();

  const { surveys, isLoading, refetch } = useSurveys({
    accountId: account?.id,
    sort: ['date,desc'],
    filters: [equal('status', SurveyStatus.PUBLISHED), equal('isDemo', false)],
    take: 1,
  });

  const isDemoSCDashboard = canUseFeature(
    Subjects.SECURITY_CULTURE_DASHBOARD_NON_CUSTOMERS,
  );

  const isEmptySCDashboard =
    canUseFeature(Subjects.SECURITY_CULTURE_DASHBOARD) &&
    (!surveys?.count || !categories?.count);

  const fetchCategories = async (survey?: SecurityCultureSurvey) => {
    if (!survey) {
      setCategories(undefined);
      return;
    }
    try {
      setAreCategoriesLoading(true);
      const response = await client.securityCulture.getSurveyCategories(
        account?.id,
        survey?.id,
      );

      setCategories(response);
    } catch (e) {
      handleAxiosError(e, toast);
    } finally {
      setAreCategoriesLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) return;

    if (!surveys?.count) {
      setSurvey(undefined);
      setCategories(undefined);
      return;
    }

    setSurvey(surveys.result[0]);
    fetchCategories(surveys.result[0]);
  }, [surveys, isLoading]);

  useEffect(() => {
    refetch();
  }, [account]);

  return (
    <>
      {isDemoSCDashboard ? (
        <SecurityCultureDemoScore />
      ) : (
        <SecurityCultureScore
          survey={isEmptySCDashboard ? undefined : surveys?.result[0]}
          isLoading={isLoading}
          isMainDashboard
          isEmpty={isEmptySCDashboard}
          hasPreviousScore={surveys && surveys.count > 1}
        />
      )}
      <SCScoreBars
        categories={categories}
        surveyId={survey?.id}
        isLoading={areCategoriesLoading}
        isMainDashboard
        isDemo={isDemoSCDashboard}
        isEmpty={isEmptySCDashboard}
      />
    </>
  );
};
