import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import {
  Account,
  AccountHubspotProductsOnActiveOrders,
  HubspotConfig,
  lifecycleStageDisplayLabel,
} from '@/client/accounts';
import { HubspotValue } from '@/client/hubspot';
import { SystemRoles } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { hubspotSchema } from '@/components/accounts/forms/validations';
import { FormikInput } from '@/components/form';
import { FormikChips } from '@/components/form/FormikChips';
import { useAccount, useUpdateAccounts } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountHeader } from './AccountHeader';

const StyledLink = styled.a`
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  text-decoration: none;
  color: var(--red-main);
  cursor: pointer;

  &:hover {
    color: var(--red-dark);
  }
`;

const getPathItems = (
  account: Account,
  currentAccount: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  if (currentAccount?.isSystem && account) {
    items.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  items.push(
    {
      label: t('account.internal'),
      url: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTERNAL](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('hubspot'),
      url: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTERNAL_HUBSPOT](
        account?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
  );

  return items;
};

export const AccountHubspot: React.FC = () => {
  const { t } = useTranslation();
  const { id: accountId } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const {
    account,
    isLoading,
    refetch: accountRefetch,
  } = useAccount({ accountId });

  const toast = useToast();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<HubspotConfig>({
    hubspotId: '',
    hubspotDomains: [],
    lifeCycleStage: undefined,
    awtStatus: undefined,
    phishingStatus: undefined,
    companyOwner: undefined,
  });

  useEffect(() => {
    if (!account && !accountId && currentAccount) {
      setInitialValues({
        hubspotId: currentAccount?.hubspot?.id,
        hubspotDomains: [
          currentAccount?.hubspot?.primaryDomain || '',
          ...(currentAccount?.hubspot?.additionalDomains || []),
        ].filter(Boolean),
        lifeCycleStage: currentAccount?.hubspot?.lifeCycleStage
          ? lifecycleStageDisplayLabel[currentAccount?.hubspot?.lifeCycleStage]
          : undefined,
        awtStatus: currentAccount?.hubspot?.awtStatus ?? undefined,
        phishingStatus: currentAccount?.hubspot?.phishingStatus ?? undefined,
        companyOwner: currentAccount?.hubspot?.companyOwner
          ? `${currentAccount?.hubspot?.companyOwner?.firstName || ''} ${
              currentAccount?.hubspot?.companyOwner?.lastName || ''
            }`
          : undefined,
      });
    }

    if (account) {
      setInitialValues({
        hubspotId: account?.hubspot?.id,
        hubspotDomains: [
          account?.hubspot?.primaryDomain || '',
          ...(account?.hubspot?.additionalDomains || []),
        ].filter(Boolean),
        lifeCycleStage: account?.hubspot?.lifeCycleStage
          ? lifecycleStageDisplayLabel[account?.hubspot?.lifeCycleStage]
          : undefined,
        awtStatus: account?.hubspot?.awtStatus ?? undefined,
        phishingStatus: account?.hubspot?.phishingStatus ?? undefined,
        companyOwner: account?.hubspot?.companyOwner
          ? `${account?.hubspot?.companyOwner?.firstName || ''} ${
              account?.hubspot?.companyOwner?.lastName || ''
            }`
          : undefined,
      });
    }
  }, [account, currentAccount]);

  const updateAccount = useUpdateAccounts();

  const handleUpdateHubspot = async (data: HubspotConfig) => {
    if (!accountId || !data) return;
    try {
      await updateAccount.update({
        updates: {
          hubspotId: data.hubspotId,
          hubspotPrimaryDomain: data?.hubspotDomains?.length
            ? data.hubspotDomains[0]
            : undefined,
          hubspotAdditionalDomains: data?.hubspotDomains?.slice(1),
        },
        accountId,
      });

      await accountRefetch();

      toast?.success(t('toast.success'), t('hubspot.account.updated'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      {isLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {account && currentAccount && (
            <>
              <AppBreadCrumb model={getPathItems(account, currentAccount, t)} />
              <AccountHeader selectedAccount={account} />
            </>
          )}

          <h3 className="mt-4 mb-4">{t('hubspot.config')}</h3>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleUpdateHubspot}
            validationSchema={hubspotSchema(t)}
          >
            {({ values, errors, validateForm, setFieldValue }) => {
              useEffect(() => {
                validateForm();
              }, [values]);
              return (
                <FormContainer>
                  <Form className="w-full">
                    <FlexContainer direction="column" align="flex-start">
                      <div className="field w-full">
                        <Field
                          id="hubspotId"
                          name="hubspotId"
                          label={t('hubspot.companyId')}
                          className="w-full"
                          required
                          component={FormikInput}
                          disabled={
                            account?.isSystem &&
                            currentUser?.role.code !== SystemRoles.DEVELOPER
                          }
                        />
                      </div>
                      <div className="field w-full mb-4">
                        <Field
                          id="hubspotDomains"
                          name="hubspotDomains"
                          label={t('hubspot.domains')}
                          className="w-full"
                          component={FormikChips}
                          placeholder={t('hubspot.domains')}
                          required
                          onChange={(event: FormEvent) =>
                            setFieldValue(
                              'hubspotDomains',
                              event.value?.map(
                                (domain: string) =>
                                  domain
                                    .replace(/https?:\/\//g, '')
                                    .split('/')[0],
                              ),
                            )
                          }
                          disabled={
                            account?.isSystem &&
                            currentUser?.role.code !== SystemRoles.DEVELOPER
                          }
                        />
                      </div>
                      <div className="field w-full mb-4">
                        <Field
                          id="lifeCycleStage"
                          name="lifeCycleStage"
                          label={t('hubspot.lifeCycleStage')}
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('hubspot.lifeCycleStage')}
                          disabled
                        />
                      </div>
                      <div className="field w-full mb-4">
                        <Field
                          id="awtStatus"
                          name="awtStatus"
                          label={t('hubspot.awtStatus')}
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('hubspot.awtStatus')}
                          disabled
                        />
                      </div>
                      <div className="field w-full mb-4">
                        <Field
                          id="phishingStatus"
                          name="phishingStatus"
                          label={t('hubspot.phishingStatus')}
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('hubspot.phishingStatus')}
                          disabled
                        />
                      </div>
                      <div className="field w-full mb-4">
                        <Field
                          id="companyOwner"
                          name="companyOwner"
                          label={t('hubspot.companyOwner')}
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('hubspot.companyOwner')}
                          disabled
                        />
                      </div>
                      {Object.values(AccountHubspotProductsOnActiveOrders).map(
                        (product, index) => (
                          <div className="field w-full mb-4" key={index}>
                            <Field
                              id={product}
                              name={product}
                              label={product}
                              className="w-full"
                              component={FormikInput}
                              value={
                                account?.hubspot?.productsOnActiveOrders?.includes(
                                  product,
                                )
                                  ? HubspotValue.YES
                                  : HubspotValue.NO
                              }
                              disabled
                            />
                          </div>
                        ),
                      )}
                      {account?.hubspot?.url && (
                        <StyledLink
                          href={account?.hubspot?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-2"
                        >
                          {t('hubspot.goTo')}
                          <i className="pi pi-external-link x2 ml-2" />
                        </StyledLink>
                      )}
                      <div className="w-full mt-3">
                        <AppButton
                          type="outlined"
                          label={t('button.back')}
                          icon="pi pi-arrow-left"
                          iconPos="left"
                          onClick={() =>
                            navigate(
                              RedirectPaths[RedirectPathsEnum.ACCOUNT_INTERNAL](
                                accountId as string,
                              ),
                            )
                          }
                        />
                        {!(
                          account?.isSystem &&
                          currentUser?.role.code !== SystemRoles.DEVELOPER
                        ) && (
                          <AppButton
                            isSubmit
                            severity="secondary"
                            label={t('button.save')}
                            className="ml-2 w-4"
                            state={
                              isLoading
                                ? LoadingStatuses.LOADING
                                : LoadingStatuses.IDLE
                            }
                            isDisabled={!!Object.keys(errors).length}
                          />
                        )}
                      </div>
                    </FlexContainer>
                  </Form>
                </FormContainer>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};
