import { FlexContainer } from '@/ui/styled-ui';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLegendContainer = styled(FlexContainer)<{
  isMainDashboard?: boolean;
}>`
  > div {
    display: flex;
    gap: var(--xxsmall-padding);
    font-size: ${({ isMainDashboard }) =>
      isMainDashboard ? 'var(--xsmall-font-size)' : 'var(--small-font-size)'};
    user-select: none;
  }
`;

const StyledDot = styled.div<{ isDemo?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;

  &.red {
    background-color: var(--red-main);
  }

  &.red-light {
    background-color: var(--red-light);
  }

  &.demo-red-light {
    background-color: var(--red-light);
  }

  ${({ isDemo }) =>
    isDemo &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--red-pale);
      opacity: 0.5;
      z-index: 1;
    }
  `}
`;

type SCLegendProps = {
  isMainDashboard?: boolean;
  isDemo?: boolean;
};

export const SCLegend: React.FC<SCLegendProps> = ({
  isMainDashboard,
  isDemo,
}) => {
  const { t } = useTranslation();
  return (
    <StyledLegendContainer
      isMainDashboard={isMainDashboard}
      justify={isMainDashboard ? 'flex-start' : 'flex-end'}
      gap={18}
    >
      <div id="legend-target-group">
        <StyledDot isDemo={isDemo} className="red" />
        <span>
          {isDemo
            ? t('security.culture.future.score')
            : t('security.culture.target.group')}
        </span>
      </div>
      <Tooltip
        target="#legend-target-group"
        position="bottom"
        content={t('security.culture.target.group.tooltip')}
      />
      <div id="legend-other-organisation">
        <StyledDot
          isDemo={isDemo}
          className={isDemo ? 'demo-red-light' : 'red-light'}
        />
        <span>{t('security.culture.other.organisations')}</span>
      </div>
      <Tooltip
        target="#legend-other-organisation"
        position="bottom"
        content={t('security.culture.other.organisations.tooltip')}
      />
    </StyledLegendContainer>
  );
};
