import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  SecurityCultureSurvey,
  SurveyCategory,
  SurveyCategoryEnum,
  SurveyQuestion,
} from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SecurityCultureCard } from '@/components/security-culture';
import { DataTable, DataTableColumnType } from '@/components/tables/crud';
import {
  useSurveyCategory,
  useSurveyCategoryQuestions,
  useSurveyCategoryRecommendations,
} from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { BarChart } from '@/ui/charts/bar-chart';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import { BackButton } from '@/ui/styled-ui/BackButton';
import { ImportantIcon } from '@/utils/helpers';
import { parseFloatingNumber } from '@/utils/helpers/ui.helper';
import { t } from 'i18next';
import { isNull } from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingPage } from '../LoadingPage';
import { SecurityCulturePageActionHeader } from './SecurityCulturePageActionHeader';

const StyledIconWrapper = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  background: var(--white-main);
  cursor: default;
  justify-content: center;
  align-items: center;
  border-radius: var(--xxsmall-border-radius);
  transition: all 0.4s;

  &:hover {
    background-color: var(--white-main);
  }

  i {
    font-size: 18px;
  }
`;

const FeedbackRow = styled(FlexContainer)`
  justify-content: flex-start;
  gap: var(--small-padding);
  padding-bottom: var(--medium-padding);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-dark);
  }

  svg {
    flex-shrink: 0;
    height: var(--default-padding);
    width: var(--default-padding);
  }
  .row-container {
    font-size: var(--small-font-size);
    font-weight: 500;
    line-height: var(--small-line-height);
  }
`;

const ScoreContainer = styled(FlexContainer)`
  .canvas-wrapper {
    position: relative;
    width: 100%;
    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      bottom: var(--xsmall-padding);
      display: block;
      background-color: var(--gray-dark);
    }
  }
  gap: var(--default-padding);
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;

  .score {
    flex: 1 1 30%;
    min-width: 362px;
  }
  .questions {
    flex: 1 1 60%;
  }
`;

const pathItems = (
  account: Account,
  t: TranslationFunctionType,
  surveyId: string,
  category: SurveyCategory,
) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('security.culture'),
    url: `${RedirectPaths[
      RedirectPathsEnum.SECURITY_CULTURE_SURVEY
    ]()}?surveyId=${surveyId}`,
    template: AppBreadCrumbTemplate,
  },
  {
    label: t(`security.culture.category.${category.name}.title`),
    url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_CATEGORY](
      surveyId,
      category.name,
    ),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCultureCategoryPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const { id, categoryName } = useParams();

  const { surveyCategory, isLoading } = useSurveyCategory({
    accountId: currentAccount?.id,
    surveyId: id,
    categoryName: categoryName as SurveyCategoryEnum,
  });

  const { questions, isLoading: areQuestionsLoading } =
    useSurveyCategoryQuestions({
      accountId: currentAccount?.id,
      surveyId: id,
      categoryName: categoryName as SurveyCategoryEnum,
      sort: [['priority', 'asc'].join(',')],
    });

  const { recommendations, isLoading: areRecommendationsLoading } =
    useSurveyCategoryRecommendations({
      accountId: currentAccount?.id,
      surveyId: id,
      categoryName: categoryName as SurveyCategoryEnum,
    });

  const columns: DataTableColumnType[] = [
    {
      field: 'question',
      header: t('security.culture.questions'),
      sortable: false,
      filterable: false,
      render: (row: SurveyQuestion) => <span>{row?.description}</span>,
    },
    {
      field: 'rating',
      header: t('security.culture.target.group'),
      sortable: false,
      filterable: false,
      className: 'text-center',
      headerClassName: 'text-center',
      render: (row: SurveyQuestion) => parseFloatingNumber(row.result),
    },
    {
      field: 'benchmark',
      header: t('security.culture.other.organisations'),
      sortable: false,
      filterable: false,
      className: 'text-center',
      headerClassName: 'text-center',
      render: (row: SurveyQuestion) => parseFloatingNumber(row.benchmarkResult),
    },
  ];

  if (
    isLoading ||
    areQuestionsLoading ||
    areRecommendationsLoading ||
    !surveyCategory
  ) {
    return <LoadingPage message={t('security.culture.survey.loading')} />;
  }

  const tooltipText = (category: SurveyCategory) => {
    const change = category.result - category.previousResult;
    return change > 0
      ? `+ ${parseFloatingNumber(change)}`
      : `- ${parseFloatingNumber(Math.abs(change))}`;
  };

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb
          model={pathItems(
            currentAccount as Account,
            t,
            id as string,
            surveyCategory,
          )}
        />
        <FlexContainer justify="flex-start" gap={12}>
          <BackButton
            to={`${RedirectPaths[
              RedirectPathsEnum.SECURITY_CULTURE_SURVEY
            ]()}?surveyId=${id}`}
          />
          <h1 className="m-0">
            {t(`security.culture.category.${surveyCategory.name}.title`)}
          </h1>
        </FlexContainer>

        <SecurityCulturePageActionHeader
          surveyIdToFetch={id}
          setVisibleSurvey={(survey: SecurityCultureSurvey) => {
            if (!survey?.id) return;
            navigate(
              RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_CATEGORY](
                survey.id,
                categoryName as string,
              ),
            );
          }}
        />

        <ScoreContainer>
          <SecurityCultureCard
            id="sc-category-score"
            className="score"
            title={t('security.culture.score.bars')}
            titleAddition={
              surveyCategory.isLow ? (
                <AppChip label={t('generic.low')} type="error" size={'sm'} />
              ) : undefined
            }
            content={
              <BarChart
                id="category-chart"
                value={parseFloatingNumber(surveyCategory?.result)}
                secondaryValue={parseFloatingNumber(
                  surveyCategory?.benchmarkResult,
                )}
                displayXAxis={false}
                height="154"
                tooltip={
                  !isNull(surveyCategory.previousResult)
                    ? t('security.culture.change.after.last.measurement', {
                        change: tooltipText(surveyCategory),
                      })
                    : undefined
                }
                defaultTooltip={t('security.culture.target.group')}
                benchmarkTooltip={t('security.culture.other.organisations')}
              />
            }
          />
          <SecurityCultureCard
            id="sc-category-questions"
            className="questions"
            title={t('security.culture.surveyQuestions')}
            titleAddition={
              <>
                <StyledIconWrapper id="security-culture-questions">
                  <i className="pi pi-info-circle" />
                </StyledIconWrapper>
                <Tooltip
                  target="#security-culture-questions"
                  position="bottom"
                  content={t('security.culture.surveyQuestions.tooltip')}
                />
              </>
            }
            content={
              <DataTable
                classNames="simple-datatable security-culture questions"
                data={questions?.result}
                count={questions?.count}
                isLoading={isLoading}
                columns={columns}
              />
            }
          />
        </ScoreContainer>
        <SecurityCultureCard
          id="sc-category-recommendations"
          title={t('security.culture.whatCanBeImproved')}
          titleAddition={
            <>
              <StyledIconWrapper id="security-culture-recommendations">
                <i className="pi pi-info-circle" />
              </StyledIconWrapper>
              <Tooltip
                target="#security-culture-recommendations"
                position="bottom"
                content={t('security.culture.whatCanBeImproved.tooltip')}
              />
            </>
          }
          content={
            <>
              {recommendations?.count ? (
                <>
                  {recommendations.result.map((recommendation) => (
                    <FeedbackRow key={recommendation.id}>
                      <ImportantIcon />
                      <div className="row-container">
                        <div className="font-bold">{recommendation.title}:</div>
                        <div>{recommendation.description}</div>
                      </div>
                    </FeedbackRow>
                  ))}
                </>
              ) : (
                <FeedbackRow>{t('filter.noItems')}</FeedbackRow>
              )}
            </>
          }
        />
      </div>
    </FlexContainer>
  );
};
