import { equal, nested } from '@/api/helpers';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { LoadingStatuses } from '@/common/constants';
import { PHISHING_WHITELISTING_GUIDE } from '@/common/constants/external-urls';
import { availableLanguagesDropdown } from '@/common/constants/languages';
import { testEmailSchema } from '@/components/campaigns/validations/test-email';
import {
  FormikCheckbox,
  FormikDropdown,
  FormikInput,
  FormikRadio,
} from '@/components/form';
import { UsersAutocompleteInput } from '@/components/form/autocomplete';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { preventDropdownBrowserTranslate } from '@/utils/helpers/ui.helper';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 550px;
`;

const StyledLink = styled.a`
  display: flex;
  justify-content: flex-start;
  min-width: max-content;
  text-decoration: none;
  color: var(--black-main);
  margin-top: 0;

  &:hover {
    cursor: pointer;
    color: var(--red-dark);
  }
`;

type SendTestEmailModalProps = {
  email: string;
  onSubmit: (data: SendTestEmailFormValues) => void;
  extended?: boolean;
  withName?: boolean;
  isSecurityCulture?: boolean;
  isSending?: boolean;
  handleWhitelisting?: (setValue: (isWhitelisted: boolean) => void) => void;
  isWhitelisted?: boolean;
} & DialogProps;

export const SendTestEmailModal: React.FC<SendTestEmailModalProps> = ({
  email,
  onSubmit,
  onHide,
  visible,
  extended = true,
  withName,
  isSecurityCulture,
  isSending = false,
  handleWhitelisting,
  isWhitelisted,
}) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const [initialValues, setInitialValues] = useState<SendTestEmailFormValues>({
    emailType: 'custom',
    email: email,
    firstName: undefined,
    lastName: undefined,
    position: undefined,
    admin: undefined,
    language: undefined,
    isWhitelisted: isWhitelisted,
  });

  useEffect(() => {
    setInitialValues((state) => ({
      ...state,
      email,
      isWhitelisted,
    }));
  }, [email, isWhitelisted]);
  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('camapign.testEmail.send')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="send-test-email-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={testEmailSchema(t, !!handleWhitelisting)}
        validateOnMount
      >
        {({ setFieldValue, values, errors, setFieldTouched }) => (
          <Form data-testid="send-test-email-form">
            {extended && (
              <FlexContainer gap={24} justify="flex-start" className="mb-4">
                <div className="field-radio">
                  <Field
                    id="emailType"
                    type="radio"
                    name="emailType"
                    value="custom"
                    label={t('campaign.testEmail.custom')}
                    shapeType="circle"
                    onChange={(e: RadioButtonChangeEvent) =>
                      setFieldValue('emailType', e.value)
                    }
                    checked={values.emailType === 'custom'}
                    component={FormikRadio}
                  />
                </div>
                <div className="field-radio">
                  <Field
                    id="emailType"
                    type="radio"
                    name="emailType"
                    value="admin"
                    label={t('roles.admin')}
                    shapeType="circle"
                    onChange={(e: RadioButtonChangeEvent) =>
                      setFieldValue('emailType', e.value)
                    }
                    checked={values.emailType === 'admin'}
                    component={FormikRadio}
                  />
                </div>
              </FlexContainer>
            )}

            {values.emailType === 'custom' ? (
              <>
                <div className="field w-full">
                  <Field
                    id="email"
                    name="email"
                    label={t('generic.email')}
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('email', e.target.value.replace(/ /g, ''))
                    }
                    component={FormikInput}
                    required
                    data-testid="send-test-email-custom-email-field"
                    disabled={!user?.account.isSystem}
                  />
                </div>
                {(withName || extended) && (
                  <>
                    <div className="field w-full">
                      <Field
                        id="firstName"
                        name="firstName"
                        label={t('generic.firstName')}
                        className="w-full"
                        component={FormikInput}
                        data-testid="send-test-email-custom-first-name-field"
                      />
                    </div>
                    <div className="field w-full">
                      <Field
                        id="lastName"
                        name="lastName"
                        label={t('generic.lastName')}
                        className="w-full"
                        component={FormikInput}
                        data-testid="send-test-email-custom-last-name-field"
                      />
                    </div>
                  </>
                )}
                {isSecurityCulture && (
                  <div className="field w-full">
                    <Field
                      id="language"
                      label={t('generic.lang')}
                      name="language"
                      filter
                      onChange={(e: DropdownChangeEvent) =>
                        setFieldValue('language', e.value)
                      }
                      onBlur={() => setFieldTouched('language', true)}
                      className="w-full"
                      component={FormikDropdown}
                      placeholder={t('generic.select')}
                      options={availableLanguagesDropdown}
                      {...preventDropdownBrowserTranslate}
                    />
                  </div>
                )}
                {extended && (
                  <>
                    <div className="field w-full">
                      <Field
                        id="position"
                        name="position"
                        label={t('generic.position')}
                        className="w-full"
                        component={FormikInput}
                        data-testid="send-test-email-custom-position-field"
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <UsersAutocompleteInput
                id="admin"
                name="admin"
                dropdown
                className="w-full mb-4"
                onChange={(value) => setFieldValue('admin', value)}
                additionalFilters={[
                  equal('active', true),
                  nested('role', [equal('code', 'admin')]),
                ]}
              />
            )}

            {!!handleWhitelisting && (
              <div className="field-checkbox mb-0">
                <Field
                  inputId="isWhitelisted"
                  name="isWhitelisted"
                  labelComponent={
                    <FlexContainer
                      gap={6}
                      align="flex-start"
                      justify="flex-start"
                    >
                      <span>
                        {t('campaign.test.email.confirm.whitelisted')}
                      </span>
                      <StyledLink
                        href={PHISHING_WHITELISTING_GUIDE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('campaign.whitelisted.linkToGuide')}
                      </StyledLink>
                      <span className="red"> *</span>
                    </FlexContainer>
                  }
                  component={FormikCheckbox}
                  disabled={values.isWhitelisted}
                  onChange={() =>
                    handleWhitelisting((isWhitelisted: boolean) =>
                      setFieldValue('isWhitelisted', isWhitelisted),
                    )
                  }
                  tooltipId="isWhitelisted-tooltip"
                  tooltip={t('campaign.test.email.confirm.whitelisted.tooltip')}
                />
              </div>
            )}

            <FlexContainer justify="flex-end" className="mt-5">
              <AppButton
                label={t('button.cancel')}
                severity="secondary"
                type="outlined"
                onClick={onHide}
                className="mr-3"
                isDisabled={isSending}
                data-testid="send-test-email-cancel-form"
              />
              <AppButton
                label={t('button.send')}
                severity="secondary"
                isSubmit
                state={
                  isSending ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                }
                data-testid="send-test-email-submit-form"
                isDisabled={!!Object.keys(errors).length}
              />
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
