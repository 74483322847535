import { FlexContainer } from '@/ui/styled-ui';
import {
  bigPadding,
  colorBlackMain,
  colorGrayDark,
  colorGrayDarker,
  colorRedLight,
  colorRedMain,
  headingMediumFontSize,
  smallPadding,
  xSmallFontSize,
} from '@/utils/helpers/css-variables.helper';
import { Chart as Chartjs } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';
import { tooltipDefaultSettings } from '../charts.helper';

Chartjs.register(ChartDataLabels);

export type BarChartProps = {
  id: string;
  value: number;
  secondaryValue: number;
  width?: string;
  height?: string;
  displayXAxis?: boolean;
  tooltip?: string;
  defaultTooltip?: string;
  benchmarkTooltip?: string;
  maxValue?: number;
  isMainDashboard?: boolean;
  isDemo?: boolean;
};

export const BarChart: React.FC<BarChartProps> = ({
  id,
  value,
  secondaryValue,
  width = '180',
  height = 'auto',
  displayXAxis,
  tooltip,
  defaultTooltip,
  benchmarkTooltip,
  maxValue,
  isMainDashboard,
  isDemo,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const docStyle = getComputedStyle(document.documentElement);

  useEffect(() => {
    const data = {
      labels: [id],
      datasets: [
        {
          backgroundColor: [colorRedMain(docStyle)],
          data: [value],
        },
        {
          backgroundColor: [colorRedLight(docStyle)],
          data: [secondaryValue],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: [colorBlackMain(docStyle)],
          font: {
            weight: 'bold',
            size: isMainDashboard
              ? xSmallFontSize(docStyle)
              : headingMediumFontSize(docStyle),
          },
          anchor: 'end',
          align: 'top',
          display: (context: any) => {
            return context.dataset.data[context.dataIndex] > 0;
          },
          formatter: (value: any, context: any) => {
            return context.datasetIndex === 0 && isDemo ? '?' : value;
          },
        },
        tooltip: isMainDashboard
          ? false
          : {
              ...tooltipDefaultSettings(),
              padding: 8,
              bodyFont: { size: 12 },
              callbacks: {
                title: () => null,
                label: (tooltipItem: any) =>
                  tooltipItem.datasetIndex === 0
                    ? tooltip
                      ? tooltip
                      : defaultTooltip
                    : benchmarkTooltip,
              },
            },
      },
      min: 0,
      max: 100,
      barPercentage: isMainDashboard ? 0.7 : 0.5,
      categoryPercentage: isMainDashboard ? 1 : 0.7,
      padding: 0,
      scales: {
        x: {
          ticks: {
            display: false,
          },
          border: {
            display: displayXAxis,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          beginAtZero: true,
          max: maxValue,
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      layout: {
        padding: {
          top: isMainDashboard
            ? smallPadding(docStyle)?.replace('px', '')
            : bigPadding(docStyle)?.replace('px', ''),
          left: 0,
          right: 0,
        },
      },
      responsive: false,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [value, secondaryValue]);

  return (
    <FlexContainer
      direction="column"
      width="min-content"
      className="canvas-wrapper"
    >
      <div className="demo-overlay">
        <Chart
          id={id}
          type="bar"
          data={chartData}
          options={chartOptions}
          className="chart"
          width={width}
          height={height}
        />
      </div>
    </FlexContainer>
  );
};
