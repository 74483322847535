import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import {
  AccountAD,
  AccountGoogleSSOConfig,
  UpdateAccountADRequest,
  UpdateGoogleSSOConfigRequest,
} from './types';
import {
  AccountGoogleWorkspace,
  UpdateGoogleWorkspaceRequest,
} from './types/account-google-workspace.type';

export const accountGoogleWorkspaceService = (client: AxiosInstance) => {
  const ACCOUNTS_BASE_URL = '/accounts';
  const GOOGLE_WORKSPACE_INTEGRATION = 'integrations/google-workspace';
  const GOOGLE_SSO_INTEGRATION = 'integrations/google-sso';

  const getAccountGoogleWorkspaceConfig = async (
    accountId: string,
  ): Promise<AccountGoogleWorkspace> => {
    try {
      const result = await client.get<
        AccountGoogleWorkspace,
        AxiosResponse<AccountGoogleWorkspace>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/${GOOGLE_WORKSPACE_INTEGRATION}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAccountGoogleWorkspaceConfig = async (
    request: UpdateGoogleWorkspaceRequest,
  ): Promise<AccountGoogleWorkspace> => {
    try {
      const result = await client.put<
        AccountGoogleWorkspace,
        AxiosResponse<AccountGoogleWorkspace>
      >(
        `${ACCOUNTS_BASE_URL}/${request.accountId}/${GOOGLE_WORKSPACE_INTEGRATION}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getGoogleSSOConfig = async (
    accountId?: string,
  ): Promise<AccountGoogleSSOConfig> => {
    try {
      const result = await client.get<
        AccountGoogleSSOConfig,
        AxiosResponse<AccountGoogleSSOConfig>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/${GOOGLE_SSO_INTEGRATION}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveGoogleSSOConfig = async (
    request: UpdateGoogleSSOConfigRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.put<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${request.accountId}/${GOOGLE_SSO_INTEGRATION}`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const forceSync = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/${GOOGLE_WORKSPACE_INTEGRATION}/force-sync`,
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const syncNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/${GOOGLE_WORKSPACE_INTEGRATION}/notify`;

  const googleSSONotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/${GOOGLE_SSO_INTEGRATION}/notify`;
  return {
    getAccountGoogleWorkspaceConfig,
    saveAccountGoogleWorkspaceConfig,
    getGoogleSSOConfig,
    saveGoogleSSOConfig,
    forceSync,
    syncNotifyUrl,
    googleSSONotifyUrl,
  };
};
