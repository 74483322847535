import { Branch } from '@/client/branches';
import { Group } from '@/client/groups';
import { Reseller } from '@/client/resellers';
import { User } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { UserManagementMeta } from './account-user-management.type';
import { AccountCourseDelivery } from './accounts.type';

export type AzureADConfig = {
  groupId?: string;
  clientId?: string;
  clientSecret?: string;
  tenantId?: string;
  syncEnabled?: boolean;
  hasAd?: boolean;
  isMigratedOutsideOfEFront?: boolean;
};

export type HubspotConfig = {
  hubspotId?: string;
  hubspotDomains?: string[];
  lifeCycleStage?: string;
  awtStatus?: string;
  phishingStatus?: string;
  companyOwner?: string;
};

export type AccountSSOConfig = {
  idpEntityId?: string;
  singleSignOnServiceUrl?: string;
  signingCertificate?: string;
  displayName?: string;
  importUrl?: string;
  isSSO?: boolean;
  isManual?: boolean;
};

export type AccountGoogleSSOConfig = {
  displayName?: string;
  clientId?: string;
  clientSecret?: string;
  isSSO?: boolean;
};

export type AccountAPIConfig = {
  active: boolean;
  username: string;
  password: string;
  clientId: string;
  clientSecret: string;
  authenticationUrl: string;
};

export type AccountKeycloak = {
  realmName: string;
  saml: {
    identityProviderUrl: string;
    certificateFingerprint: string;
    remoteSigninUrl: string;
    remoteSignoutUrl: string;
    targetedIdentifier: string;
    firstNameIdentifier: string;
    lastNameIdentifier: string;
    emailIdentifier: string;
  };
};

type AccountMeta = {
  eFrontSync: boolean;
  eFrontId: string;
  eFrontType: string;
  lastEFrontSync: Date;
  goPhishId: string;
  goPhishApiKey: string;
  goPhishPassword: string;
  goPhishURL: string;
  keycloak?: AccountKeycloak;
  isMigratedOutsideOfEFront?: boolean;
  autoCourseAvailability: boolean;
  isMigrationTriggered?: boolean;
  isSSO?: boolean;
  reportsLastGeneratedAt?: Date;
  migratedOutsideOfEFrontAt?: Date;
  notifyManagerForOverdueCourses?: boolean;
};

export type AccountHubspotCompanyOwner = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

export enum AccountHubspotProductsOnActiveOrders {
  AWT = 'AWT',
  PHISHING_SUBSCRIPTION = 'Phishing Subscription',
  AUTO_PHISHING = 'Auto Phishing',
  SECURITY_CULTURE = 'Security Culture',
}

export enum LifecycleStage {
  SUBSCRIBER = 'subscriber',
  LEAD = 'lead',
  MARKETING_QUALIFIED_LEAD = 'marketingqualifiedlead',
  SALES_QUALIFIED_LEAD = 'salesqualifiedlead',
  OPPORTUNITY = 'opportunity',
  CUSTOMER = 'customer',
  EVANGELIST = 'evangelist',
  OTHER = 'other',
}

export const lifecycleStageDisplayLabel: { [key: string]: string } = {
  [LifecycleStage.SUBSCRIBER]: 'Subscriber',
  [LifecycleStage.LEAD]: 'Lead',
  [LifecycleStage.MARKETING_QUALIFIED_LEAD]: 'Marketing Qualified Lead',
  [LifecycleStage.SALES_QUALIFIED_LEAD]: 'Sales Qualified Lead',
  [LifecycleStage.OPPORTUNITY]: 'Opportunity',
  [LifecycleStage.CUSTOMER]: 'Customer',
  [LifecycleStage.EVANGELIST]: 'Evangelist',
  [LifecycleStage.OTHER]: 'Other',
};

export type AccountHubspot = {
  id?: string;
  linkingFailed?: boolean;
  lastSyncedAt?: Date;
  lifeCycleStage?: LifecycleStage;
  awtStatus?: string;
  phishingStatus?: string;
  companyOwner?: AccountHubspotCompanyOwner;
  primaryDomain?: string;
  additionalDomains?: string[];
  url?: string;
  productsOnActiveOrders: AccountHubspotProductsOnActiveOrders[];
};

export type AccountSMTPSettings = {
  senderName?: string;
  fromAddress?: string;
  host?: string;
  username?: string;
  password?: string;
  ignoreCertErrors?: boolean;
  isCustom?: boolean;
  isRateLimited?: boolean | null;
};

export type AccountSettings = {
  smtp: AccountSMTPSettings;
};

export enum AccountTypeEnum {
  REAL = 'real',
  DEMO = 'demo',
}

export type PersonalizedTableViews = {
  key: TableNamesEnum;
  columns: string[];
};

export type Account = {
  id: string;
  name: string;
  subdomain: string;
  active: boolean;
  activeCampaignsCount: number;
  created: Date;
  updated: Date;
  isSystem: boolean;
  syncStatus?: SyncStatusEnum;
  ssoSettings: AccountSSOConfig;
  users: User[];
  groups: Group[];
  branches: Branch[];
  settings: AccountSettings;
  meta: AccountMeta;
  blockSystemEmails: boolean;
  isFreeTrial: boolean;
  reseller: Reseller | null;
  platformSettings: PlatformSettings | null;
  availableCoursesByType: Record<string, number> | null;
  mainAccountOfReseller: Reseller | null;
  defaultLanguage: string | null;
  type: AccountTypeEnum | null;
  hubspot?: AccountHubspot;
  activeDirectoryIntegration?: UserManagementMeta[] | null;
  activeUsersCount: number;
  courseDelivery?: AccountCourseDelivery;
  courseDueDays?: number;
  featureFlagAbility?: {
    action: string;
    subject: string | string[];
  }[];
  courseMinimumScore?: number | null;
  personalizedTableViews?: PersonalizedTableViews[];
};

export type PlatformSettings = {
  logo: string;
  name: string;
  url: string;
  baseUrl: string;
  theme: string;
  favIcon?: string;
  phone?: string;
  email?: string;
  subdomain?: string;
};

export enum AccountActionsEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export const displayAccountSyncStatus = (
  status: SyncStatusEnum,
  t: TranslationFunctionType,
): string => t(`sync.status.${status}`);

export enum SyncStatusEnum {
  SYNCED = 'synced',
  PENDING = 'pending',
  NOT_SYNCED = 'not-synced',
}
