import { LanguagesEnum } from '@/api/enums';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Course, CourseStatusAttemptEnum } from '@/client/courses';
import { Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { CourseLanguageChanger } from '@/components/courses/CourseLanguageChanger';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getTranslatedCourseStatusAttempt,
  getTranslationByLanguage,
} from '@/utils/helpers';
import { isMobile } from '@/utils/helpers/ui.helper';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledAppChip = styled(AppChip)`
  margin-left: var(--small-padding);

  &.mobile .p-chip-text {
    margin: var(--xxsmall-padding);
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--small-padding);

  h1 {
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
    }
  }
`;

const StyledP = styled.p`
  font-size: var(--small-font-size);
  color: var(--gray-darker);
  margin: 0;
`;

const getAppChipType = (status: string | undefined) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return 'secondary';
    case CourseStatusAttemptEnum.COMPLETED:
      return 'primary';
    case CourseStatusAttemptEnum.FAILED:
      return 'error';
    default:
      return 'secondary';
  }
};

type ScormActionHeaderProps = {
  course: Course;
  status?: CourseStatusAttemptEnum;
  selectedLanguage?: LanguagesEnum;
  setSelectedLanguage: (language: LanguagesEnum) => void;
  isScormStarted?: boolean;
  isDisabled?: boolean;
};

export const ScormActionHeader: React.FC<ScormActionHeaderProps> = ({
  course,
  status,
  selectedLanguage,
  setSelectedLanguage,
  isScormStarted,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canUseFeature } = useFeatureFlag();
  const location = useLocation();
  const currentUser = useAppSelector(selectCurrentUser);

  const courseName = getTranslationByLanguage(
    course?.courseTranslations ?? [],
    currentUser?.language
      ? (currentUser?.language as LanguagesEnum)
      : course.defaultLanguage,
    course.defaultLanguage,
  )?.name;

  const getBreadcrumbs = (t: TranslationFunctionType): MenuItem[] => [
    {
      label: t('courses'),
      url:
        location?.state?.from ||
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: courseName,
      url: RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id ?? ''),
      template: AppBreadCrumbTemplate,
      data: { state: location?.state },
    },
  ];

  const navigateToCourseInfo = async () => {
    if (course) {
      navigate(RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id), {
        state: location?.state,
      });
    }
  };

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(t)} className="no-space" />
      <FlexContainer justify="space-between" wrap="wrap">
        <StyledDiv>
          <AppButton
            type="outlined"
            icon="pi pi-arrow-left"
            size={isMobile() ? 'xs' : 'sm'}
            ariaLabel={t('course.player.back')}
            isDisabled={isDisabled}
            onClick={
              isMobile()
                ? () =>
                    navigate(
                      location?.state?.from ||
                        RedirectPaths[
                          RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                        ](),
                    )
                : navigateToCourseInfo
            }
          />
          <h1>{courseName}</h1>
          <StyledAppChip
            className={isMobile() ? 'mobile' : ''}
            key={status}
            label={getTranslatedCourseStatusAttempt(status)}
            type={getAppChipType(status)}
          />
          {canUseFeature(Subjects.COURSE_MINIMUM_SCORE_REQUIREMENT) &&
            !!currentUser?.account?.courseMinimumScore && (
              <StyledP>
                {t('course.minimum.score.to.complete.this.course', {
                  percent: currentUser.account.courseMinimumScore,
                })}
              </StyledP>
            )}
        </StyledDiv>
        <StyledDiv>
          {isScormStarted &&
            course.selfHosted &&
            course?.courseLanguages?.length > 1 &&
            !isMobile() && (
              <CourseLanguageChanger
                language={selectedLanguage}
                course={course}
                shouldShowWarning={true}
                isDisabled={isDisabled}
                onChange={(value) => setSelectedLanguage(value)}
              />
            )}

          {isScormStarted && !isMobile() && (
            <AppButton
              type="outlined"
              icon="pi pi-times"
              size={isMobile() ? 'xs' : 'sm'}
              ariaLabel={t('courses.back')}
              isDisabled={isDisabled}
              onClick={() =>
                navigate(
                  location?.state?.from ||
                    RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
                )
              }
            />
          )}
        </StyledDiv>
      </FlexContainer>
    </>
  );
};
