import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { SecurityCultureSurvey } from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SecurityCultureCard } from '@/components/security-culture';
import { useSurveyFeedback } from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { BackButton } from '@/ui/styled-ui/BackButton';
import { ChartIcon } from '@/utils/helpers';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingPage } from '../LoadingPage';
import { SecurityCulturePageActionHeader } from './SecurityCulturePageActionHeader';

const FeedbackRow = styled(FlexContainer)`
  justify-content: flex-start;
  gap: var(--small-padding);
  padding-bottom: var(--medium-padding);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-dark);
  }

  svg {
    flex-shrink: 0;
    height: var(--default-padding);
    width: var(--default-padding);
  }
  span {
    font-size: var(--small-font-size);
    font-weight: 500;
    line-height: var(--small-line-height);
  }
`;

const pathItems = (
  account: Account,
  t: TranslationFunctionType,
  surveyId: string,
) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('security.culture'),
    url: `${RedirectPaths[
      RedirectPathsEnum.SECURITY_CULTURE_SURVEY
    ]()}?surveyId=${surveyId}`,
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('security.culture.feedback'),
    url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_FEEDBACK](surveyId),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCultureFeedbackPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { id } = useParams();
  const navigate = useNavigate();

  const { skip, take, onGridPageChange, onGridRowChange } = useTable();

  const { feedback, isLoading } = useSurveyFeedback({
    take,
    skip,
    accountId: currentAccount?.id,
    surveyId: id as string,
    sort: [['priority', 'asc'].join(',')],
  });

  if (isLoading || !feedback) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb
          model={pathItems(currentAccount as Account, t, id as string)}
        />
        <FlexContainer justify="flex-start" gap={12}>
          <BackButton
            to={`${RedirectPaths[
              RedirectPathsEnum.SECURITY_CULTURE_SURVEY
            ]()}?surveyId=${id}`}
          />
          <h1 className="m-0">{t('security.culture.feedback')}</h1>
        </FlexContainer>

        <SecurityCulturePageActionHeader
          surveyIdToFetch={id}
          setVisibleSurvey={(survey: SecurityCultureSurvey) => {
            if (!survey?.id) return;
            navigate(
              RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_FEEDBACK](
                survey.id,
              ),
            );
          }}
        />

        <SecurityCultureCard
          id="sc-feedback-page"
          title={t('security.culture.feedback')}
          content={
            <>
              {feedback.count ? (
                <>
                  {feedback.result.map((x) => (
                    <FeedbackRow key={x.id}>
                      <ChartIcon />
                      <span>{x.description}</span>
                    </FeedbackRow>
                  ))}
                </>
              ) : (
                <FeedbackRow>{t('filter.noItems')}</FeedbackRow>
              )}
            </>
          }
          take={take}
          skip={skip}
          count={feedback.count}
          onPageChange={onGridPageChange}
          onRowsChange={onGridRowChange}
        />
      </div>
    </FlexContainer>
  );
};
