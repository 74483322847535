import { LanguagesEnum } from '@/api/enums';
import { equal } from '@/api/helpers';
import { CourseStatusAttemptEnum, MaterialType } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { CoursesFilters } from '@/components/courses/CoursesFilters';
import { useLearnerCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import CoursesComingSoonImage from '@/images/placeholders/courses-coming-soon.svg';
import CoursesNoActiveImage from '@/images/placeholders/courses-no-active.svg';
import CoursesNoCompleted from '@/images/placeholders/courses-no-completed.svg';
import { selectCurrentUser } from '@/store/features/users';
import AppButton from '@/ui/buttons/AppButton';
import { ScormCard } from '@/ui/scorm-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import {
  getCourseLanguages,
  getTranslationByLanguage,
  scormColumnsWidth,
} from '@/utils/helpers';
import { debounce } from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardGridContainer = styled(CardGridContainer)`
  @media screen and (max-width: ${scormColumnsWidth.THREE}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${scormColumnsWidth.TWO}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${scormColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  border-radius: var(--medium-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
  overflow-x: hidden;

  h2 {
    margin-top: var(--default-padding);
    margin-bottom: 0;
  }

  p {
    margin-top: var(--small-padding);
    margin-bottom: var(--default-padding);
  }
`;

const StyledImg = styled.img`
  height: auto;
  width: 100%;
`;

type LearnerCoursesListProps = {
  type: CourseStatusAttemptEnum.IN_PROGRESS | CourseStatusAttemptEnum.COMPLETED;
  atLeastOneCompleted?: boolean;
  isLoading?: boolean;
};

export const LearnerCoursesList: React.FC<LearnerCoursesListProps> = ({
  type,
  atLeastOneCompleted,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useAppSelector(selectCurrentUser);

  const [search, setSearch] = useState('');
  const debouncedSetSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearch(value);
      }, 500),
    [],
  );

  const { isLoading: isScormCoursesLoading, learnerCourses } =
    useLearnerCourses({
      skip: 0,
      type: type,
      search: search,
      filters: [equal('type', MaterialType.COURSE)],
      staleTime: Infinity,
    });

  useEffect(() => {
    setSearch('');
  }, [type]);

  return (
    <>
      <CoursesFilters onSearch={debouncedSetSearch} />
      {/* Active Courses Page when you don't have enrolled, in progress or completed courses */}
      {!isScormCoursesLoading &&
        !isLoading &&
        !learnerCourses?.result.length &&
        !atLeastOneCompleted &&
        !search &&
        type === CourseStatusAttemptEnum.IN_PROGRESS && (
          <StyledFlexContainer direction="column" className="mt-4" flex="1">
            <StyledImg
              style={{ maxHeight: '216px' }}
              src={CoursesComingSoonImage}
              alt={t('courses.noCourses.heading')}
            />
            <h2>{t('courses.noCourses.heading')}</h2>
            <p>{t('courses.noCourses.subheading')}</p>
          </StyledFlexContainer>
        )}

      {/* Active Courses Page when you don't have in progress courses but you have completed courses */}
      {!isScormCoursesLoading &&
        !isLoading &&
        !learnerCourses?.result.length &&
        atLeastOneCompleted &&
        !search && (
          <StyledFlexContainer direction="column" className="mt-4" flex="1">
            <StyledImg
              style={{ maxHeight: '190px' }}
              src={CoursesNoActiveImage}
              alt={t('courses.noActiveCourses.heading')}
            />
            <h2>{t('courses.noActiveCourses.heading')}</h2>
            <p>{t('courses.noActiveCourses.subheading')}</p>
            <AppButton
              label={t('courses.completed')}
              severity="danger"
              onClick={() =>
                navigate(
                  RedirectPaths[
                    RedirectPathsEnum.LEARNER_DASHBOARD_COMPLETED
                  ](),
                )
              }
            />
          </StyledFlexContainer>
        )}

      {/* Completed Courses Page when you don't have completed courses */}
      {!isScormCoursesLoading &&
        !isLoading &&
        !learnerCourses?.result.length &&
        !atLeastOneCompleted &&
        !search &&
        type === CourseStatusAttemptEnum.COMPLETED && (
          <StyledFlexContainer direction="column" className="mt-4" flex="1">
            <StyledImg
              style={{ maxHeight: '316px' }}
              src={CoursesNoCompleted}
              alt={t('courses.noCompleted.heading')}
            />
            <h2>{t('courses.noCompleted.heading')}</h2>
            <p>{t('courses.noCompleted.subheading')}</p>
          </StyledFlexContainer>
        )}

      {(isScormCoursesLoading || isLoading || !learnerCourses) && (
        <FlexContainer direction="column" className="mt-5 flex-1">
          <ProgressSpinner />
          <h3>{t('courses.loading')}</h3>
        </FlexContainer>
      )}
      {!isScormCoursesLoading && !learnerCourses?.result.length && !!search && (
        <StyledFlexContainer direction="column" className="mt-4" flex="1">
          <h2>{t('courses.search.noResults')}</h2>
        </StyledFlexContainer>
      )}
      {learnerCourses?.result.length !== 0 && (
        <div className="w-full">
          <StyledCardGridContainer>
            {learnerCourses?.result.map((course) => (
              <ScormCard
                key={course.id}
                id={course.id}
                thumbnail={course.courseThumbnail?.signedUrl}
                title={
                  getTranslationByLanguage(
                    course.courseTranslations,
                    currentUser?.language
                      ? (currentUser?.language as LanguagesEnum)
                      : course.defaultLanguage,
                    course.defaultLanguage,
                  )?.name ?? ''
                }
                language={getCourseLanguages(course.courseLanguages)}
                attempts={course.attempts}
                onClick={() => {
                  navigate(
                    RedirectPaths[RedirectPathsEnum.COURSE_INFO](course.id),
                    { state: { from: location.pathname } },
                  );
                }}
              />
            ))}
          </StyledCardGridContainer>
        </div>
      )}
    </>
  );
};
