import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account, displayAccountSyncStatus } from '@/client/accounts';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { AzureADForm } from '@/components/accounts/forms/AzureADForm';
import { useAzureADHandler } from '@/hooks/integrations/useAzureAD.hook';
import { useAccount } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import moment from 'moment';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountHeader } from './AccountHeader';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
) => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('account.microsoft'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountAzureADPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { account, isLoading: isAccountLoading } = useAccount({
    accountId: id,
  });
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { can } = usePermission();

  const {
    initialValues,
    syncState,
    saveADConfig,
    handleSubmit,
    handleForceADSync,
    isADLoading,
    accountAd,
  } = useAzureADHandler(id || currentAccount?.id || '');

  return (
    <>
      {isADLoading || isAccountLoading || !account || !id ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && (
            <AppBreadCrumb
              model={getPathItems(account, currentAccount, can, t)}
            />
          )}

          <AccountHeader selectedAccount={account} />

          {accountAd?.meta?.lastSync && accountAd?.meta?.status && (
            <Message
              severity="info"
              text={`${t('sync.lastSync')} ${moment(
                accountAd?.meta?.lastSync,
              ).format(DateFormats.TIMEDATE)}, ${displayAccountSyncStatus(
                accountAd?.meta?.status,
                t,
              )}`}
            />
          )}

          {accountAd?.meta?.lastSyncErrorLog && (
            <Message
              className="mt-2"
              severity="warn"
              text={accountAd?.meta?.lastSyncErrorLog}
            />
          )}

          <h3 className="mt-4 mb-4">{t('account.microsoft.config')}</h3>

          <AzureADForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onSync={() => handleForceADSync(id)}
            account={account}
            state={queryStateConverter(saveADConfig)}
            syncState={syncState}
            disabled={
              account?.isSystem &&
              currentUser?.role.code !== SystemRoles.DEVELOPER
            }
          />
        </>
      )}
    </>
  );
};
