import { Subjects } from '@/client/users';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledDashboardContainer = styled.div<{
  isSCDashboardFeatureEnabled: boolean;
}>`
  width: 100%;
  display: grid;
  gap: var(--default-padding);
  grid-template-columns: repeat(6, minmax(auto, 1fr));
  grid-template-areas: ${({ isSCDashboardFeatureEnabled }) =>
    isSCDashboardFeatureEnabled
      ? `
    'm m m m m m'
    'o1 o1 o2 o2 o3 o3'
    'sc sc sb sb sb sb'
    'cr1 cr1 cr1 cr2 cr2 cr2'
    'pr pr pr pr pr pr'
  `
      : `
    'm m m m m m'
    'o1 o1 o2 o2 o3 o3'
    'cr1 cr1 cr1 cr2 cr2 cr2'
    'pr pr pr pr pr pr'
  `};

  #sync-error-message {
    grid-area: m;
  }

  @media (max-width: 1439px) {
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    grid-template-areas: ${({ isSCDashboardFeatureEnabled }) =>
      isSCDashboardFeatureEnabled
        ? `
      'm m m m'
      'o1 o1 o2 o2'
      'o3 o3 o3 o3'
      'sc sc sc sc'
      'sb sb sb sb'
      'cr1 cr1 cr1 cr1'
      'cr2 cr2 cr2 cr2'
      'pr pr pr pr'
    `
        : `
      'm m m m'
      'o1 o1 o2 o2'
      'o3 o3 o3 o3'
      'cr1 cr1 cr1 cr1'
      'cr2 cr2 cr2 cr2'
      'pr pr pr pr'
    `};
  }

  @media (max-width: 799px) {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    grid-template-areas: ${({ isSCDashboardFeatureEnabled }) =>
      isSCDashboardFeatureEnabled
        ? `
      'm m'
      'o1 o1'
      'o2 o2'
      'o3 o3'
      'sc sc'
      'sb sb'
      'cr1 cr1'
      'cr2 cr2'
      'pr pr'
    `
        : `
      'm m'
      'o1 o1'
      'o2 o2'
      'o3 o3'
      'cr1 cr1'
      'cr2 cr2'
      'pr pr'
    `};
  }
`;

export const AdminDashboardLayout: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { canUseFeature } = useFeatureFlag();

  const isSCDashboardFeatureEnabled =
    canUseFeature(Subjects.SECURITY_CULTURE_DASHBOARD) ||
    canUseFeature(Subjects.SECURITY_CULTURE_DASHBOARD_NON_CUSTOMERS);

  return (
    <StyledDashboardContainer
      isSCDashboardFeatureEnabled={isSCDashboardFeatureEnabled}
    >
      {children}
    </StyledDashboardContainer>
  );
};
