import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import {
  useCourseCompletionReports,
  useUserAwarenessReports,
} from '@/hooks/query';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import { CardReportsSingleDoughnut } from '@/ui/styled-ui';
import {
  AwarenessIconPath,
  getCourseCompletionReports,
  getUserAwarenessReports,
  SummaryIconPath,
} from '@/utils/helpers';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardContainer = styled(CardReportsSingleDoughnut)`
  &#cr1 {
    grid-area: cr1;
  }
  &#cr2 {
    grid-area: cr2;
  }
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  .p-skeleton {
    flex-shrink: 0;
  }
`;

type AwarenessAndCompletionReportsPropsType = {
  accountId: string;
  t: TranslationFunctionType;
};

export const AwarenessAndCompletionReports = ({
  accountId,
  t,
}: AwarenessAndCompletionReportsPropsType) => {
  const navigate = useNavigate();

  const {
    courseCompletionReports,
    isLoading: courseCompletionReportsIsLoading,
  } = useCourseCompletionReports({
    accountId,
  });

  const { userAwarenessReports, isLoading: userAwarenessReportsIsLoading } =
    useUserAwarenessReports({ accountId });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!courseCompletionReportsIsLoading && !userAwarenessReportsIsLoading) {
      setIsLoading(false);
    }
  }, [courseCompletionReportsIsLoading, userAwarenessReportsIsLoading]);

  return (
    <>
      <StyledCardContainer
        id="cr1"
        onClick={() =>
          navigate(RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY]())
        }
        isLoading={isLoading}
        title={t('reports.courses.completion')}
        icon={<SummaryIconPath />}
        tableData={
          courseCompletionReports
            ? getCourseCompletionReports({ courseCompletionReports, t })
            : []
        }
      >
        <DoughnutChart
          allCount={courseCompletionReports?.totalEnrolments ?? 0}
          singleCount={courseCompletionReports?.totalCompletions ?? 0}
          id="1"
          label={t('reports.courses.completion')}
          cutout="70%"
          width="160px"
          isPercentCount
          isDashboard
        />
      </StyledCardContainer>
      <StyledCardContainer
        id="cr2"
        onClick={() =>
          navigate(RedirectPaths[RedirectPathsEnum.REPORTS_AWARENESS]())
        }
        isLoading={isLoading}
        title={t('reports.users.awareness')}
        icon={<AwarenessIconPath />}
        tableData={
          userAwarenessReports
            ? getUserAwarenessReports({ userAwarenessReports, t })
            : []
        }
      >
        <DoughnutChart
          allCount={userAwarenessReports?.usersWithEnrolledCourses ?? 0}
          singleCount={userAwarenessReports?.usersWithCompletedCourses ?? 0}
          id="2"
          label={t('reports.users.awareness')}
          cutout="70%"
          width="160px"
          isPercentCount
          isDashboard
        />
      </StyledCardContainer>
    </>
  );
};
