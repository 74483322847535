import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { MessageResponseModel } from '../models';
import {
  AddCoursePlannersRequest,
  CoursePlanner,
  DeletePlannerRequest,
  ListCoursePlannerRequest,
  ListCoursePlannerResponse,
  SavePlannerRequest,
  TargetSelectorPreviewRequest,
} from './types';

export const plannerService = (client: AxiosInstance) => {
  const PLANNER_BASE_URL = '/account';
  const COURSE_PLANNERS_URL = 'course-planners';

  const createPlanner = async (
    accountId: string,
    request: SavePlannerRequest,
  ): Promise<CoursePlanner> => {
    try {
      const result = await client.post(
        `${PLANNER_BASE_URL}/${accountId}/course-planner`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updatePlanner = async (
    request: SavePlannerRequest,
  ): Promise<CoursePlanner> => {
    try {
      const result = await client.patch(
        `${PLANNER_BASE_URL}/${request.accountId}/course-planner/${request.id}`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getPlanners = async (
    params: ListCoursePlannerRequest,
    accountId?: string,
  ): Promise<ListCoursePlannerResponse> => {
    try {
      const result = await client.get<
        ListCoursePlannerResponse,
        AxiosResponse<ListCoursePlannerResponse>
      >(`${PLANNER_BASE_URL}/${accountId}/course-planner`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deletePlanner = async (
    request: DeletePlannerRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete(
        `${PLANNER_BASE_URL}/${request.accountId}/course-planner/${request.planId}`,
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const addCoursePlanners = async (
    request: AddCoursePlannersRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post(
        `/${COURSE_PLANNERS_URL}/courses`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const targetSelectorPreview = async (
    params: TargetSelectorPreviewRequest,
    accountId?: string,
  ): Promise<{ usersCount: number }> => {
    try {
      const result = await client.get<
        { usersCount: number },
        AxiosResponse<{ usersCount: number }>
      >(`/target-selector/${accountId}/preview`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const accountCoursePlannersNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/course-planners/notify`;

  return {
    getPlanners,
    createPlanner,
    updatePlanner,
    deletePlanner,
    addCoursePlanners,
    targetSelectorPreview,
    accountCoursePlannersNotifyUrl,
  };
};
