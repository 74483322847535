import { FlexContainer } from '@/ui/styled-ui';
import { SCLockIcon } from '@/utils/helpers';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';

const StyledIconWrapper = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  background: var(--white-main);
  cursor: default;
  justify-content: center;
  align-items: center;
  border-radius: var(--xxsmall-border-radius);
  transition: all 0.4s;

  &:hover {
    background-color: var(--white-main);
  }

  i {
    font-size: 18px;
  }
`;

const StyledSubtitle = styled.div`
  color: var(--purple-dark);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 500;
  width: 100%;
  &.red {
    color: var(--red-main);
    font-weight: 700;
  }
`;

export const RedSpan = styled.span`
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 700;
  margin: 0;
`;

type CardInfoProps = {
  id: string;
  title: string;
  tooltip: string;
  style?: React.CSSProperties;
  subtitle?: string;
  redTitle?: string;
  isDemo?: boolean;
};

export const CardInfo: React.FC<CardInfoProps> = ({
  id,
  title,
  tooltip,
  style,
  subtitle,
  redTitle,
  isDemo,
}) => (
  <FlexContainer justify="space-between" gap={24} style={style}>
    <FlexContainer direction="column">
      <FlexContainer justify="flex-start" align="center" gap={8}>
        <span className="uppercase">{title}</span>
        {redTitle && subtitle && <RedSpan>{redTitle}</RedSpan>}
      </FlexContainer>

      {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {redTitle && !subtitle && (
        <StyledSubtitle className="red">{redTitle}</StyledSubtitle>
      )}
    </FlexContainer>

    {isDemo ? (
      <SCLockIcon />
    ) : (
      <>
        <StyledIconWrapper id={id}>
          <i className="pi pi-info-circle" />
        </StyledIconWrapper>
        <Tooltip target={`#${id}`} position="bottom" content={tooltip} />
      </>
    )}
  </FlexContainer>
);
