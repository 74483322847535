import { DateFormats } from '@/system-settings/enums';
import React from 'react';
import styled from 'styled-components';
import { FormatDate } from './FormatDate';

const StyledNoTime = styled.span`
  min-width: max-content;
  display: inline-block;
`;

const StyledTime = styled.span`
  margin-right: var(--xsmall-padding);
`;

export const CompletionDate: React.FC<{ completionDate: Date }> = ({
  completionDate,
}) => {
  return (
    <>
      {completionDate.toString().endsWith('T00:00:00.000Z') ? (
        <>
          <StyledTime>
            <FormatDate date={completionDate} format={DateFormats.DATEONLY} />
          </StyledTime>
          <StyledNoTime>--:--</StyledNoTime>
        </>
      ) : (
        <FormatDate date={completionDate} format={DateFormats.TIMEDATE} />
      )}
    </>
  );
};
