import { FlexContainer } from '@/ui/styled-ui';
import {
  colorGrayDark,
  colorGrayOpacity,
  colorRedLight,
  colorRedMain,
  defaultPadding,
  mediumPadding,
} from '@/utils/helpers/css-variables.helper';
import { Chart as ChartJs } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { t } from 'i18next';
import { Chart } from 'primereact/chart';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { tooltipDefaultSettings } from '../charts.helper';

ChartJs.register(ChartDataLabels);

const StyledChart = styled.div<{ isMainDashboard?: boolean }>`
  position: relative;
  .min,
  .max {
    position: absolute;
    font-size: var(--xsmall-font-size);
    bottom: var(--xxsmall-padding);
  }

  .min {
    left: ${({ isMainDashboard }) =>
      isMainDashboard ? '-8px' : 'calc(2 * var(--default-padding))'};
    bottom: ${({ isMainDashboard }) =>
      isMainDashboard ? 'var(--default-padding)' : '0px'};
  }
  .max {
    right: ${({ isMainDashboard }) =>
      isMainDashboard ? '-18px' : 'calc(2.75 * var(--medium-padding))'};
    bottom: ${({ isMainDashboard }) =>
      isMainDashboard ? 'var(--default-padding)' : '0px'};
  }

  @media screen and (max-width: 1800px) {
    .min {
      left: ${({ isMainDashboard }) =>
        isMainDashboard ? '-8px' : 'calc(1.3 * var(--default-padding))'};
      bottom: ${({ isMainDashboard }) =>
        isMainDashboard ? 'var(--default-padding)' : '0px'};
    }
    .max {
      right: ${({ isMainDashboard }) =>
        isMainDashboard ? '-18px' : 'calc(1.7 * var(--medium-padding))'};
      bottom: ${({ isMainDashboard }) =>
        isMainDashboard ? 'var(--default-padding)' : '0px'};
    }
  }
`;

export type SemiDoughnutChartProps = {
  id: string;
  value: number;
  controlValue: number;
  valueTooltipSuffix?: string;
  controlValueTooltipSuffix?: string;
  min?: number;
  max?: number;
  isMainDashboard?: boolean;
};

export const SemiDoughnutChart: React.FC<SemiDoughnutChartProps> = ({
  id,
  value,
  controlValue,
  valueTooltipSuffix,
  controlValueTooltipSuffix,
  min = 0,
  max = 100,
  isMainDashboard,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const isSmallChartCheck = window.matchMedia('(max-width: 1800px)');
  const [isSmallChart, setIsSmallChart] = useState(isSmallChartCheck.matches);
  const docStyle = getComputedStyle(document.documentElement);

  const handleWindowSizeChange = () => {
    setIsSmallChart(isSmallChartCheck.matches);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const remainingFromValue = max - value;
    const values = [value, remainingFromValue];
    const remainingFromControlValue = max - controlValue;
    const controlValues = [controlValue, remainingFromControlValue];

    const data = {
      datasets: [
        {
          label: null,
          data: values,
          backgroundColor: [colorRedMain(docStyle), colorGrayDark(docStyle)],
          borderColor: 'transparent',
        },
        {
          label: null,
          data: controlValues,
          backgroundColor: [
            colorRedLight(docStyle),
            colorGrayOpacity(docStyle),
          ],
          borderColor: 'transparent',
        },
      ],
      labels: null,
    };
    const options = {
      cutout: '70%',
      plugins: {
        legend: {
          display: false,
        },
        datalabels: false,
        tooltip: {
          ...tooltipDefaultSettings(),
          padding: isMainDashboard ? 0 : 8,
          callbacks: {
            title: () => null,
            label: (tooltipItem: any) =>
              `${t('generic.score')} ${
                tooltipItem.datasetIndex === 0 ? value : controlValue
              }`,
            afterLabel: (tooltipItem: any) =>
              tooltipItem.datasetIndex === 0
                ? valueTooltipSuffix
                : controlValueTooltipSuffix,
          },
        },
      },
      layout: {
        padding: {
          left: isMainDashboard
            ? '0'
            : mediumPadding(docStyle)?.replace('px', ''),
          right: isMainDashboard
            ? '0'
            : mediumPadding(docStyle)?.replace('px', ''),
          bottom: defaultPadding(docStyle)?.replace('px', ''),
        },
      },
      rotation: -90,
      circumference: 180,
      responsive: false,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [value, controlValue]);

  return (
    <FlexContainer direction="column" width="min-content">
      <StyledChart
        key={isSmallChart.toString()}
        isMainDashboard={isMainDashboard}
      >
        <Chart
          id={id}
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="chart"
          width={isMainDashboard ? '200px' : isSmallChart ? '280px' : '400px'}
          height={isMainDashboard ? '120px' : isSmallChart ? '150px' : '200px'}
        />

        <span className="min">{min}</span>
        <span className="max">{max}</span>
      </StyledChart>
    </FlexContainer>
  );
};
