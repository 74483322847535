import { FilePurpose } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { isKeycloakEnabled } from '@/auth';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { FilterNamesEnum } from '@/client/helpers';
import {
  Actions,
  EntityEnum,
  Subjects,
  SystemRoles,
  User,
  UsersBulk,
} from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import {
  notSystemRoleOptions,
  roleTranslations,
  systemRoleOptions,
} from '@/common/constants/roles';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterType,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccountUsers,
  useAccountUsersImport,
  useBranchesPartialRequest,
  useBranchUsersImport,
  useDeleteUser,
  useGroupsPartialRequest,
  useGroupUsersImport,
  useUpdateUser,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppButton, AppDropdownButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  cannotManageMultiAdmins,
  parseAccountImportNotification,
  parseImportNotification,
  parseImportWarnings,
  randomString,
} from '@/utils/helpers';
import { getBranchName } from '@/utils/helpers/branches.helper';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { FileUpload, FileUploadUploadEvent } from 'primereact/fileupload';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UsersExportModal } from '../UsersExportModal';

const StyledFileUpload = styled(FileUpload)`
  > .p-button.p-component.p-fileupload-choose {
    padding: var(--medium-padding) var(--small-padding);
    width: 100%;
    border: 0;
    background-color: transparent;
    color: var(--black-main);
    text-align: left;
    border-radius: 0;

    &:hover {
      color: var(--black-main) !important;
      border-color: var(--gray-pale) !important;
      background: var(--gray-pale) !important;
    }

    .p-button-label {
      font-weight: 400;
    }
  }
`;

type UserDatatableProps = {
  defaultFilters?: FiltersType;
  defaultTableFilters?: Record<string, any>;
  withActions?: boolean;
  withToolbar?: boolean;
  withBranches?: boolean;
  withGroups?: number;
  withAddNew?: boolean;
  setBulkModalType?: (
    value: React.SetStateAction<UsersBulk | undefined>,
  ) => void;
  additionalColumns?: DataTableColumnType[];
  toolbarAdditional?: ReactNode;
  shouldRefetch?: boolean;
  withImport?: boolean;
  branchId?: string;
  groupId?: string;
  isPhishingGroup?: boolean;
};

export const UserDatatable: React.FC<UserDatatableProps> = ({
  defaultFilters = [],
  defaultTableFilters = {},
  withActions = false,
  withToolbar = false,
  withBranches = false,
  withGroups = 0,
  withAddNew = false,
  setBulkModalType,
  additionalColumns,
  toolbarAdditional,
  shouldRefetch,
  withImport,
  branchId,
  groupId,
  isPhishingGroup,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { can } = usePermission();
  const { user } = useAuth();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const { setDialogData } = useContext(DialogContext);
  const [showExportModal, setShowExportModal] = useState(false);

  const addSortNulls = (sort: [string, 'asc' | 'desc'] | null) => {
    if (!sort) return undefined;
    const columnName = sort[0];
    const order = sort[1];

    switch (columnName) {
      case 'lastLogin':
        return order === 'asc' ? 'last' : 'first';
      default:
        return undefined;
    }
  };

  const { isLoading, users, refetch } = useAccountUsers({
    accountId: currentAccount?.id,
    take,
    skip,
    filters: [...apiFilters, ...defaultFilters],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    withBranches,
    withGroups,
    sortNulls: addSortNulls(sort),
  });

  const { branches } = useBranchesPartialRequest({
    accountId: currentAccount?.id,
    sort: ['name,asc'],
  });
  const { groups } = useGroupsPartialRequest({
    accountId: currentAccount?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();

  const navigate = useNavigate();

  const onRowClick = (event: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.USERS_EDIT](event.data.id), {
      state: {
        pathname: location.pathname,
        search: location.search,
      },
    });

  const getFilters = (t: TranslationFunctionType) => [
    {
      label: t('generic.id'),
      field: 'id',
      type: FilterTypeEnum.TEXT,
    },
    {
      label: t('generic.name'),
      field: FilterNamesEnum.NAME,
      type: FilterTypeEnum.TEXT,
      placeholder: t('user.search.name'),
    },
    {
      label: t('generic.username'),
      field: 'username',
      type: FilterTypeEnum.TEXT,
      placeholder: t('user.search.username'),
    },
    {
      label: t('generic.email'),
      field: FilterNamesEnum.USER_BY_EMAIL,
      type: FilterTypeEnum.TEXT,
      placeholder: t('user.searchEmail'),
    },
    {
      label: t('branch'),
      type: FilterTypeEnum.SELECT,
      field: FilterNamesEnum.BRANCH,
      placeholder: t('generic.select'),
      options: branches?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    ...(can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin
      ? [
          {
            label: t('groups'),
            type: FilterTypeEnum.SELECT,
            field: FilterNamesEnum.GROUPS,
            placeholder: t('generic.select'),
            options: groups?.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          },
        ]
      : []),
    {
      label: t('filter.status'),
      field: 'active',
      type: FilterTypeEnum.SELECT_ACTIVE_INACTIVE,
      placeholder: t('filter.status'),
    },
    {
      label: t('generic.role'),
      field: 'role',
      type: FilterTypeEnum.SELECT,
      placeholder: t('generic.role'),
      options: currentAccount?.isSystem
        ? systemRoleOptions(t)
        : notSystemRoleOptions(t, currentUser as User),
    },
    {
      label: t('generic.companyName'),
      field: FilterNamesEnum.COMPANY_NAME,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.companyName'),
    },
    {
      label: t('generic.country'),
      field: FilterNamesEnum.COUNTRY,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.country'),
    },
    {
      label: t('generic.officeLocation'),
      field: FilterNamesEnum.OFFICE_LOCATION,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.officeLocation'),
    },
    {
      label: t('generic.department'),
      field: FilterNamesEnum.DEPARTMENT,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.department'),
    },
    {
      label: t('generic.manager'),
      field: FilterNamesEnum.MANAGER,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.manager'),
    },
    {
      label: t('generic.managerEmail'),
      field: FilterNamesEnum.MANAGER_EMAIL,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.managerEmail'),
    },
    {
      label: t('generic.jobTitle'),
      field: FilterNamesEnum.JOB,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.jobTitle'),
    },
    {
      label: t('generic.mobilePhone'),
      field: FilterNamesEnum.MOBILE_PHONE,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.mobilePhone'),
    },
    {
      label: t('user.manually.deactivated'),
      field: FilterNamesEnum.MANUALLY_DEACTIVATED,
      type: FilterTypeEnum.SELECT_YES_NO,
      placeholder: t('generic.select'),
    },
  ];

  let columns: DataTableColumnType[] = [
    {
      field: 'id',
      header: t('generic.id'),
      sortable: true,
      filterable: false,
    },
    {
      field: 'firstName',
      header: t('generic.name'),
      sortable: true,
      filterable: false,
      render: (row: User) => row.name ?? <span>&#8212;</span>,
    },
    {
      field: 'username',
      header: t('generic.username'),
      sortable: true,
      filterable: false,
      render: (row: User) => row.username,
    },
    {
      field: 'email',
      header: t('generic.email'),
      sortable: true,
      filterable: false,
      render: (row: User) => row.email,
    },
    {
      field: 'branch',
      header: t('branch'),
      sortable: true,
      filterable: false,
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.branch ? (
            <span>{getBranchName(row.branch).reverse().join(' / ')}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    ...((can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin
      ? [
          {
            field: 'groups',
            header: t('groups'),
            sortable: true,
            filterable: false,
            render: (row: User) => (
              <FlexContainer direction="column" align="flex-start">
                {row?.groups?.length ? (
                  <span>{row.groups?.map(({ name }) => name)?.join(', ')}</span>
                ) : (
                  <div className="group-row">&#8212;</div>
                )}
              </FlexContainer>
            ),
          },
        ]
      : []) as DataTableColumnType[]),
    ...((currentAccount?.isFreeTrial && currentUser?.account.isSystem
      ? [
          {
            field: 'freeTrialEndsAt',
            header: t('user.freeTrial.endDate'),
            sortable: true,
            filterable: false,
            render: (row: User) =>
              row?.freeTrialEndsAt ? (
                <FormatDate date={row?.freeTrialEndsAt} />
              ) : (
                <span>&#8212;</span>
              ),
          },
        ]
      : []) as DataTableColumnType[]),
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: User) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'active',
      header: t('generic.active'),
      sortable: true,
      filterable: false,
      render: (row: User) =>
        row.active ? t('generic.active') : t('generic.inactive'),
    },
    {
      field: 'role',
      header: t('generic.role'),
      sortable: true,
      filterable: false,
      render: (row: User) => roleTranslations(row.role, t),
    },
    {
      field: 'companyName',
      header: t('generic.companyName'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.companyName ? (
            <span>{row?.meta?.companyName}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'country',
      header: t('generic.country'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.country ? (
            <span>{row?.meta?.country}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'officeLocation',
      header: t('generic.officeLocation'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.officeLocation ? (
            <span>{row?.meta?.officeLocation}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'department',
      header: t('generic.department'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.department ? (
            <span>{row?.meta?.department}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'manager',
      header: t('generic.manager'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.manager ? (
            <span>{row?.meta?.manager}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'managerEmail',
      header: t('generic.managerEmail'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.managerEmail ? (
            <span>{row?.meta?.managerEmail}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'jobTitle',
      header: t('generic.jobTitle'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.jobTitle ? (
            <span>{row?.meta?.jobTitle}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'mobilePhone',
      header: t('generic.mobilePhone'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.mobilePhone ? (
            <span>{row?.meta?.mobilePhone}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'lastLogin',
      header: t('user.lastLogin'),
      sortable: true,
      filterable: false,
      render: (row: User) =>
        row?.meta?.lastLogin ? (
          <FormatDate
            date={row?.meta?.lastLogin}
            format={DateFormats.TIMEDATE}
          />
        ) : (
          <span>&#8212;</span>
        ),
    },
  ];

  if (withActions) {
    columns = [
      ...columns,
      {
        field: 'actions',
        header: t('generic.actions'),
        sortable: false,
        filterable: false,
        style: {
          width: '80px',
          textAlign: 'center',
        },
        render: (row: User) => {
          if (deletingUserId === row.id) {
            return (
              <FlexContainer>
                <ProgressSpinner style={{ width: '24px', height: '24px' }} />
              </FlexContainer>
            );
          }

          return (
            <DataTableActions
              disabled={menuItems(row).length < 1 || !!deletingUserId}
              menuItems={menuItems(row)}
            />
          );
        },
      },
    ];
  }

  if (additionalColumns && additionalColumns.length > 0) {
    columns = [...columns, ...additionalColumns];
  }

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const defaultVisibleColumns = columns
    .filter(
      (column) =>
        ![
          'id',
          'companyName',
          'country',
          'officeLocation',
          'department',
          'manager',
          'jobTitle',
          'mobilePhone',
          'lastLogin',
        ].includes(column.field),
    )
    .map((column) => column.field);
  const alwaysVisibleColumns = ['username', 'actions'];
  //

  useEffect(() => {
    if (!shouldRefetch) {
      return;
    }

    refetch();
  }, [shouldRefetch]);

  const menuItems = (user: User) => {
    const menu: MenuItem[] = [];
    const cannotManageMultiAdminsBool = cannotManageMultiAdmins(
      user,
      currentUser,
    );

    if (
      can(Actions.UPDATE, Subjects.USERS) &&
      (!currentAccount?.isSystem ||
        currentUser?.role.code === SystemRoles.DEVELOPER)
    ) {
      menu.push(
        ...[
          {
            items: [
              {
                label: t('generic.edit'),
                icon: 'pi pi-pencil',
                command: () => {
                  navigate(
                    RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id),
                  );
                },
              },
              {
                label: user.active
                  ? t('generic.deactivate')
                  : t('generic.activate'),
                icon: user.active ? 'pi pi-minus-circle' : 'pi pi-check',
                command: () => {
                  return cannotManageMultiAdminsBool
                    ? setDialogData({
                        type: 'info',
                        show: true,
                        header: currentAccount?.platformSettings?.name ?? '',
                        message: t('generic.contactSupport', {
                          platformName: currentAccount?.platformSettings?.name,
                        }),
                      })
                    : setDialogData({
                        type: 'confirmation',
                        show: true,
                        header: `${
                          user.active
                            ? t('generic.deactivate')
                            : t('generic.activate')
                        } ${t('dialog.confirm')}`,
                        message: user.active
                          ? t('user.deactivate')
                          : t('user.activate'),
                        onAccept: async () =>
                          handleToggleItemsStatus(user.id, user.active),
                      });
                },
              },
            ],
          },
        ],
      );
    }
    if (
      can(Actions.DELETE, Subjects.USERS) &&
      (!currentAccount?.isSystem ||
        currentUser?.role.code === SystemRoles.DEVELOPER)
    ) {
      menu.push(
        ...[
          {
            label: t('generic.delete'),
            icon: 'pi pi-times',
            command: () =>
              cannotManageMultiAdminsBool
                ? setDialogData({
                    type: 'info',
                    show: true,
                    header: currentAccount?.platformSettings?.name ?? '',
                    message: t('generic.contactSupport', {
                      platformName: currentAccount?.platformSettings?.name,
                    }),
                  })
                : setDialogData({
                    type: 'confirmation',
                    show: true,
                    header: t('dialog.delete'),
                    message: t('dialog.delete.user'),
                    onAccept: () => handleDeleteUser(user.id),
                  }),
          },
        ],
      );
    }

    return menu;
  };

  const handleToggleItemsStatus = async (userId: string, active: boolean) => {
    try {
      await updateUser.update({
        userId: userId,
        accountId: (currentAccount as Account)?.id,
        updates: { active: !active },
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        active ? t('user.deactivated') : t('user.activated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const {
    lastMessage,
    setNotificationParam: setUserId,
    notificationParam: notificationUserId,
    isConnected,
  } = useNotifications(client.users.userNotifyUrl);

  useEffect(() => {
    if (!notificationUserId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const [deletingUserId, setDeletingUserId] = useState<string | null>(null);
  const handleDeleteUser = async (userId: string) => {
    setUserId(userId);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        deleteUser.delete({
          userId,
          accountId: (currentAccount as Account)?.id,
        });
        setDeletingUserId(userId);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setDeletingUserId(null);
    }
  };

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      !['user.delete.finished', 'user.delete.error'].includes(
        messages?.data?.event,
      )
    )
      return;

    if (messages.data.event === 'user.delete.error') {
      toast?.error(t('toast.error'), t('user.not.deleted'));
      setDeletingUserId(null);
      setUserId(undefined);
      await refetch();
    }

    if (messages.data.event === 'user.delete.finished') {
      toast?.success(t('toast.success'), t('user.deleted'));
      setDeletingUserId(null);
      setUserId(undefined);
      await refetch();
    }
  };

  const isKeycloakAuth = isKeycloakEnabled();
  const [isUploading, setIsUploading] = useState(false);

  const {
    lastMessage: lastMessageImportAccountLevel,
    setNotificationParam,
    notificationParam: notificationAccountId,
    isConnected: isConnectedImportAccountLevel,
  } = useNotifications(client.accounts.accountsNotifyUrl);

  const { upload: uploadUsersAccountLevel } = useAccountUsersImport();
  const { upload: uploadUsersBranchLevel } = useBranchUsersImport();
  const { upload: uploadUsersGroupLevel } = useGroupUsersImport();

  const {
    lastMessage: lastMessageImportBranchLevel,
    setNotificationParam: setBranchId,
    notificationParam: notificationBranchId,
    isConnected: isConnectedImportBranchesLevel,
  } = useNotifications(client.branches.branchesNotifyUrl);

  const {
    lastMessage: lastMessageImportGroupLevel,
    setNotificationParam: setGroupId,
    notificationParam: notificationGroupId,
    isConnected: isConnectedImportGroupLevel,
  } = useNotifications(client.groups.groupsNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleNotifyUsersUpload(lastMessageImportAccountLevel);
  }, [JSON.stringify(lastMessageImportAccountLevel)]);

  useEffect(() => {
    if (!notificationBranchId) return;
    handleNotifyEntityUsersUpload(
      lastMessageImportBranchLevel,
      EntityEnum.BRANCH,
    );
  }, [JSON.stringify(lastMessageImportBranchLevel)]);

  useEffect(() => {
    if (!notificationGroupId) return;
    handleNotifyEntityUsersUpload(
      lastMessageImportGroupLevel,
      EntityEnum.GROUP,
    );
  }, [JSON.stringify(lastMessageImportGroupLevel)]);

  const handleNotifyUsersUpload = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'account.users.import.finished',
        'account.users.import.failed',
        'account.users.import.warning',
      ].includes(messages?.data?.event)
    )
      return;

    parseAccountImportNotification(messages, toast, t);

    if (
      messages.data.event === 'account.users.import.warning' &&
      messages.data.payload?.errors
    ) {
      setTimeout(() => {
        toast?.multiple(parseImportWarnings(messages.data.payload.errors, t));
      }, 0);
    }

    setIsUploading(false);
    setNotificationParam(undefined);
    refetch();
  };

  const handleNotifyEntityUsersUpload = (messages: any, type: EntityEnum) => {
    if (
      !messages?.data?.event ||
      ![
        `${type}.users.import.finished`,
        `${type}.users.import.failed`,
      ].includes(messages?.data?.event)
    )
      return;

    parseImportNotification(messages, type, toast, t);

    if (
      messages.data.event === `${type}.users.import.finished` &&
      messages.data.payload?.errors.length !== 0
    ) {
      setTimeout(() => {
        toast?.multiple(parseImportWarnings(messages.data.payload.errors, t));
      }, 0);
    }

    setIsUploading(false);
    if (type === EntityEnum.BRANCH) {
      setBranchId(undefined);
    } else if (type === EntityEnum.GROUP) {
      setGroupId(undefined);
    }
    refetch();
  };

  const onUpload = async (e: FileUploadUploadEvent) => {
    setIsUploading(true);

    if (branchId) {
      setBranchId(branchId);
    } else if (groupId) {
      setGroupId(groupId);
    } else {
      setNotificationParam(currentAccount?.id);
    }

    const response = JSON.parse(e.xhr.response);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnectedImportAccountLevel.current && !branchId && !groupId) {
          clearInterval(intervalId);
          resolve(true);
        }
        if (isConnectedImportBranchesLevel.current && branchId && !groupId) {
          clearInterval(intervalId);
          resolve(true);
        }
        if (isConnectedImportGroupLevel.current && groupId && !branchId) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnectedImportAccountLevel.current && !branchId && !groupId) {
        await uploadUsersAccountLevel({
          accountId: currentAccount?.id as string,
          fileId: response.id,
          importKey: randomString(10),
        });
      }
      if (isConnectedImportBranchesLevel.current && branchId && !groupId) {
        await uploadUsersBranchLevel({
          accountId: (currentAccount as Account)?.id,
          branchId,
          fileId: response.id,
          importKey: randomString(10),
        });
      }
      if (isConnectedImportGroupLevel.current && groupId && !branchId) {
        await uploadUsersGroupLevel({
          accountId: (currentAccount as Account)?.id,
          groupId,
          fileId: response.id,
          importKey: randomString(10),
        });
      }
    } catch (e) {
      setIsUploading(false);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const itemActionMenu = useRef<any>(null);

  const toggleItemActionMenu = (event: any) => {
    if (!itemActionMenu.current) {
      return;
    }

    itemActionMenu.current.toggle(event);
  };

  const exportImportMenuItems: MenuItem[] = [
    {
      label: t('user.upload.csv'),
      icon: 'pi pi-user',
      template: () => (
        <StyledFileUpload
          mode="basic"
          name="file"
          url={client.files.getFileUploadUrl()}
          accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          maxFileSize={5e6}
          onUpload={onUpload}
          auto
          chooseLabel={t('user.upload.csv')}
          disabled={isUploading}
          withCredentials
          onBeforeSend={(event) => {
            if (user && isKeycloakAuth) {
              event.xhr.setRequestHeader(
                'Authorization',
                `Bearer ${user?.access_token}`,
              );
            }
          }}
          onBeforeUpload={(event) => {
            event.formData.append('purpose', FilePurpose.IMPORT);
          }}
        />
      ),
    },
    {
      label: t('generic.export'),
      icon: 'pi pi-download',
      command: () => setShowExportModal(true),
    },
  ];

  const addRemoveMenuItems: MenuItem[] = [
    {
      label: t('users.add'),
      icon: 'pi pi-plus',
      command: () =>
        setBulkModalType ? setBulkModalType(UsersBulk.ADD) : null,
    },
    {
      label: t('users.remove'),
      icon: 'pi pi-minus',
      command: () =>
        setBulkModalType ? setBulkModalType(UsersBulk.REMOVE) : null,
    },
  ];

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFilters(t) as FilterType[]}
          onFilter={onFilter}
          defaultValues={defaultTableFilters}
          tableName={TableNamesEnum.USERS_LIST}
        />
        <FlexContainer width="auto" gap={8}>
          {withAddNew &&
            can(Actions.CREATE, Subjects.USERS) &&
            currentAccount?.active &&
            (!currentAccount?.isSystem ||
              currentUser?.role.code === SystemRoles.DEVELOPER) && (
              <AppButton
                label={t('button.createNew')}
                severity="secondary"
                onClick={() => {
                  navigate(RedirectPaths[RedirectPathsEnum.USERS_CREATE]());
                }}
              />
            )}
          {!!setBulkModalType &&
            (!currentAccount?.isSystem ||
              currentUser?.role.code === SystemRoles.DEVELOPER) &&
            !isPhishingGroup && (
              <AppDropdownButton
                menuId="add-remove-users"
                severity="secondary"
                label={t('users.addOrRemove')}
                className="w-fit-content "
                items={addRemoveMenuItems}
                isDisabled={isBranchAdmin}
                tooltip={
                  isBranchAdmin
                    ? t('generic.feature.notAvailable.branchAdmins')
                    : undefined
                }
                tooltipOptions={{ position: 'top', showOnDisabled: true }}
              />
            )}
          {can(Actions.CREATE, Subjects.USERS) &&
            !currentAccount?.isSystem &&
            currentAccount?.active &&
            withImport && (
              <>
                <Menu
                  model={exportImportMenuItems}
                  popup
                  ref={itemActionMenu}
                  id="overlay_menu"
                  style={{ minWidth: '250px' }}
                />
                <AppButton
                  severity={isPhishingGroup ? 'success' : 'info'}
                  label={
                    isPhishingGroup
                      ? t('generic.export')
                      : t('generic.import.export')
                  }
                  aria-controls="overlay_menu"
                  onClick={
                    isPhishingGroup
                      ? () => setShowExportModal(true)
                      : toggleItemActionMenu
                  }
                  state={
                    isUploading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                  }
                  icon={isPhishingGroup ? 'pi pi-download' : undefined}
                />
              </>
            )}
          {toolbarAdditional}
        </FlexContainer>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USERS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      <DataTable
        data={users?.result}
        count={users?.count}
        isLoading={isLoading}
        toolbar={withToolbar ? toolbar : null}
        columns={columns}
        visibleColumns={visibleColumns}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
        onRowClick={
          can(Actions.UPDATE, Subjects.USERS) ? onRowClick : undefined
        }
      />
      <UsersExportModal
        defaultFilters={branchId || groupId ? [] : defaultFilters}
        filters={apiFilters}
        visible={showExportModal}
        onHide={() => setShowExportModal(false)}
        branchId={branchId}
        groupId={groupId}
        visibleColumns={visibleColumns}
      />
    </>
  );
};
