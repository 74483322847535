import { FilePurpose, languageDisplayCode, LanguagesEnum } from '@/api/enums';
import { equal } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { CourseFormState, MaterialType } from '@/client/courses';
import { File } from '@/client/files';
import { PlannerType } from '@/client/planner/types';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStateType,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { availableLanguages } from '@/common/constants/languages';
import { DialogContext } from '@/common/context';
import { createCourseSchema } from '@/components/courses/forms/validations';
import {
  FormikInput,
  FormikMultiSelect,
  FormikRichTextArea,
  FormikSwitch,
} from '@/components/form';
import { FormikInputNumber } from '@/components/form/FormikInputNumber';
import {
  AccountSelectInput,
  EfrontCoursesMultiselectInput,
} from '@/components/form/selectors';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { CourseFiles } from '@/ui/course-files';
import { AppFormAccordion } from '@/ui/form-accordion';
import { AppIconTooltip } from '@/ui/icon-tooltip';
import { ImageUpload } from '@/ui/image-upload';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { TabSwitchButtons } from '@/ui/tab-switch-buttons';
import {
  courseLanguagesOptions,
  sortAlphabetically,
  standardCourseLabelOptions,
} from '@/utils/helpers';
import { Field, Form, Formik, FormikValues } from 'formik';
import moment from 'moment';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type CourseFormProps = {
  initialValues: CourseFormState;
  state?: LoadingStateType;
  onSubmit: (data: FormikValues) => void;
  courseThumbnailData?: File;
  customAccountData?: Account;
  isCreate?: boolean;
  selfHosted?: boolean;
  defaultLanguage?: string;
};

export const CourseForm: React.FC<CourseFormProps> = ({
  initialValues,
  state,
  onSubmit,
  courseThumbnailData,
  customAccountData,
  isCreate = false,
  selfHosted,
  defaultLanguage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setDialogData } = useContext(DialogContext);
  const account = useAppSelector(selectCurrentAccount);
  const isSystem = account?.isSystem;
  const retired = !!initialValues.retired;
  const { cannot } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const disabled: boolean =
    cannot(Actions.CREATE, Subjects.COURSES) ||
    ((!!(initialValues.id && initialValues?.customAccount !== account?.id) ||
      !canUseFeature(Subjects.CUSTOM_COURSE_CREATION)) &&
      !account?.isSystem);

  const [selectedTab, setSelectedTab] = useState(
    languageDisplayCode[defaultLanguage || LanguagesEnum.EN],
  );

  const [selectedCustomAccount, setSelectedCustomAccount] =
    useState<Account | null>(null);
  const [validOptions, setValidOptions] = useState([] as string[]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (customAccountData) {
      setSelectedCustomAccount(customAccountData);
    }
  }, [customAccountData]);

  useEffect(() => {
    setValidOptions(checkValidOptions(initialValues));
  }, [initialValues]);

  // Define required translations
  const requiredTranslations = (values: FormikValues) =>
    values.courseLanguages.map(
      (languageCode: LanguagesEnum) => languageDisplayCode[languageCode],
    );

  // Generate validOptions based on the current form values
  const checkValidOptions = (values: FormikValues) => {
    return availableLanguages
      .filter((languageCode) => {
        if (values.courseTranslations[languageCode]) {
          return values.courseTranslations[languageCode].name !== '';
        }
      })
      .map((languageCode) => languageDisplayCode[languageCode]);
  };

  const setLanguageOptions = (
    languageCode: LanguagesEnum,
    validOptions: string[],
    values: FormikValues,
    setFieldValue: (field: string, value: string[]) => void,
  ) => {
    if (
      validOptions.includes(languageDisplayCode[languageCode]) &&
      values.courseLanguages &&
      !values.courseLanguages.includes(languageCode)
    ) {
      setFieldValue('courseLanguages', [
        ...(values?.courseLanguages || []),
        languageCode,
      ]);
    }
  };

  const showDescription = (
    values: FormikValues,
    languageCode: string,
  ): boolean => {
    if (
      values.type === MaterialType.COURSE &&
      values?.customAccount === account?.id &&
      canUseFeature(Subjects.CUSTOM_COURSE_CREATION)
    )
      return true;
    if (values.courseTranslations && !isSystem && !isCreate) {
      return !!values.courseTranslations[languageCode]?.description;
    }
    return true;
  };

  const cancelHandler = (values: CourseFormState) => {
    if (initialValues !== values || retired) {
      setDialogData({
        show: true,
        type: 'confirmation',
        header: t(isCreate ? 'course.cancel.create' : 'course.cancel.update'),
        message: t('course.cancel.confirm'),
        onAccept: () => navigate(RedirectPaths[RedirectPathsEnum.COURSES]()),
      });
    } else {
      navigate(RedirectPaths[RedirectPathsEnum.COURSES]());
    }
  };

  const courseLanguagesOptionTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const handleChangeCourseLanguages = (
    event: DropdownChangeEvent,
    values: CourseFormState,
    setFieldValue: (field: string, value: string[]) => void,
  ) => {
    const options = event.value;

    setFieldValue('courseLanguages', options);

    if (values?.courseFiles) {
      values.courseFiles.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!options.includes(key)) {
            delete item[key];
          }
        });
      });
    }

    Object.keys(values.courseTranslations)
      .filter((key: any) => !!values.courseTranslations[key].name)
      .forEach((key: any) => {
        if (!options.includes(key)) {
          (values.courseTranslations as any)[key].name = '';
          setValidOptions(checkValidOptions(values));
        }
      });

    if (
      !options.includes(values.defaultLanguage) &&
      values.defaultLanguage &&
      values.courseTranslations
    ) {
      (values.courseTranslations as any)[values.defaultLanguage].name = '';
      setValidOptions(checkValidOptions(values));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createCourseSchema(validOptions, t)}
      onSubmit={onSubmit}
      validateOnChange
      validateOnMount
    >
      {({ errors, setFieldValue, values, validateForm }) => {
        return (
          <FormContainer width={100}>
            {isCreate && <h1 className="mt-0 mb-5">{t('course.new')}</h1>}
            <Form className="w-full" autoComplete="off">
              <FlexContainer
                gap={24}
                width="100%"
                justify="space-between"
                align="flex-start"
              >
                <div className="w-full">
                  <FlexContainer
                    className="mb-4"
                    justify="flex-start"
                    id="course-translations-tabs"
                  >
                    <TabSwitchButtons
                      options={availableLanguages.map(
                        (languageCode) => languageDisplayCode[languageCode],
                      )}
                      requiredOptions={requiredTranslations(values)}
                      validOptions={validOptions}
                      onToggle={(option) => setSelectedTab(option as string)}
                      defaultSelected={selectedTab}
                    />
                  </FlexContainer>
                  {availableLanguages.map(
                    (languageCode) =>
                      selectedTab === languageDisplayCode[languageCode] && (
                        <div key={languageCode} className="w-full">
                          <div className="field mb-4">
                            <Field
                              disabled={disabled || retired}
                              id={`name-${languageCode}`}
                              label={t('course.name')}
                              name={`courseTranslations.${languageCode}.name`}
                              className="w-full"
                              component={FormikInput}
                              placeholder={t('course.name')}
                              required={requiredTranslations(values).includes(
                                languageCode,
                              )}
                              additionOnBlur={() => {
                                const validOptions = checkValidOptions(values);
                                setValidOptions(validOptions);
                                setLanguageOptions(
                                  languageCode,
                                  validOptions,
                                  values,
                                  setFieldValue,
                                );
                                setTimeout(async () => {
                                  await validateForm();
                                }, 0);
                              }}
                            />
                          </div>
                          {showDescription(values, languageCode) && (
                            <div className="field mb-0">
                              <Field
                                readOnly={disabled || retired}
                                id={`description-${languageCode}`}
                                label={t('generic.description')}
                                name={`courseTranslations.${languageCode}.description`}
                                className="w-full"
                                placeholder={t('generic.description')}
                                component={FormikRichTextArea}
                              />
                            </div>
                          )}
                        </div>
                      ),
                  )}
                  <div className="field mt-4 mb-0">
                    <Field
                      disabled={disabled || retired}
                      id="course-languages"
                      name="courseLanguages"
                      filter
                      label={
                        !values?.id || values?.selfHosted
                          ? t('course.files.languages')
                          : t('course.file.languages')
                      }
                      className="w-full"
                      display="chip"
                      placeholder={t('generic.select')}
                      required={!values.releaseDate}
                      onChange={(event: MultiSelectChangeEvent) => {
                        handleChangeCourseLanguages(
                          event,
                          values,
                          setFieldValue,
                        );
                        setTimeout(async () => {
                          await validateForm();
                        }, 0);
                      }}
                      component={FormikMultiSelect}
                      options={courseLanguagesOptions.sort((a, b) =>
                        sortAlphabetically(a.name, b.name),
                      )}
                      itemTemplate={courseLanguagesOptionTemplate}
                    />
                  </div>

                  {(!disabled || initialValues.customAccount) &&
                    values?.courseFiles?.map((file: any, index: number) => (
                      <CourseFiles
                        key={`module-${index}-${file?.id}`}
                        index={index}
                        values={values}
                        label={
                          canUseFeature(Subjects.MULTIPLE_FILE_COURSES)
                            ? t('course.module.number', { number: index + 1 })
                            : isCreate || selfHosted
                            ? t('generic.files')
                            : t('generic.file')
                        }
                        selfHosted={selfHosted}
                        isCreate={isCreate}
                        isDisabled={disabled || retired}
                        isModule={canUseFeature(Subjects.MULTIPLE_FILE_COURSES)}
                        setFieldValue={setFieldValue}
                        setIsSubmitDisabled={setIsSubmitDisabled}
                      />
                    ))}
                </div>

                <div className="w-full">
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('generic.image')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('courseThumbnail', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={courseThumbnailData}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled || retired}
                      required={!!values.releaseDate}
                      hasError={!!errors.courseThumbnail}
                    />
                    {!!errors.courseThumbnail && (
                      <small className="p-error">
                        {errors.courseThumbnail}
                      </small>
                    )}
                  </div>

                  {!disabled && (
                    <AppFormAccordion
                      label={
                        account?.isSystem
                          ? t('course.accordion.header')
                          : t('course.accordion.header.short')
                      }
                    >
                      <div className="field mb-4">
                        <label htmlFor="course-created-at">
                          {t('generic.creation')}
                        </label>
                        <AppCalendar
                          disabled={retired}
                          id="course-created-at"
                          name="courseCreatedAt"
                          className="w-full"
                          showIcon
                          iconPos="left"
                          placeholder={t('generic.select')}
                          maxDate={values.courseLastUpdatedAt}
                          value={values.courseCreatedAt}
                          onChange={(event: FormEvent) =>
                            setFieldValue('courseCreatedAt', event.value)
                          }
                          showButtonBar
                          onClearButtonClick={() => {
                            setFieldValue('courseCreatedAt', undefined);
                          }}
                          readOnlyInput
                        />
                      </div>
                      <div className="field mb-4">
                        <label htmlFor="course-last-updated-at">
                          {t('generic.last.updated')}
                        </label>
                        <AppCalendar
                          disabled={retired}
                          id="course-last-updated-at"
                          name="courseLastUpdatedAt"
                          className="w-full"
                          showIcon
                          iconPos="left"
                          placeholder={t('generic.select')}
                          minDate={values.courseCreatedAt}
                          value={values.courseLastUpdatedAt}
                          onChange={(event: FormEvent) =>
                            setFieldValue('courseLastUpdatedAt', event.value)
                          }
                          showButtonBar
                          onClearButtonClick={() => {
                            setFieldValue('courseLastUpdatedAt', undefined);
                          }}
                          readOnlyInput
                        />
                      </div>

                      {account?.isSystem && (
                        <>
                          <div className="field-checkbox mb-4">
                            <Field
                              disabled={
                                retired ||
                                (initialValues.customContent &&
                                  !!initialValues.id) ||
                                values.isStandardCatalogue
                              }
                              inputId="customContent"
                              name="customContent"
                              label={t('courses.custom.content')}
                              value={!!values.customAccount}
                              onChange={async (
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setFieldValue('customContent', e.target.value);
                                if (!e.target.value) {
                                  setFieldValue('customAccount', null);
                                  setSelectedCustomAccount(null);
                                }
                                setTimeout(async () => {
                                  await validateForm();
                                }, 0);
                              }}
                              component={FormikSwitch}
                            />
                          </div>

                          {values.customContent && (
                            <div className="field">
                              <AccountSelectInput
                                isDisabled={
                                  retired ||
                                  (initialValues.customContent &&
                                    !!initialValues.id) ||
                                  values.isStandardCatalogue
                                }
                                defaultValue={selectedCustomAccount}
                                onSelect={(account) => {
                                  if (account) {
                                    setFieldValue('customAccount', account.id);
                                    setSelectedCustomAccount(account);
                                  }
                                }}
                                additionalFilters={[equal('isSystem', false)]}
                              />
                              <br />
                              {!values.customAccount && (
                                <small className="p-error">
                                  {t('course.select.account')}
                                </small>
                              )}
                            </div>
                          )}

                          <label htmlFor="field-checkbox">
                            {t('generic.type')}
                          </label>

                          <FlexContainer justify="flex-start">
                            <div className="field-checkbox mt-2 mb-4">
                              <Field
                                disabled={retired || values.customContent}
                                inputId="trial"
                                name="trial"
                                label={t('course.trial')}
                                component={FormikSwitch}
                                value={values.trial}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue('trial', e.target.value);
                                }}
                              />

                              <AppIconTooltip
                                target="trial-icon"
                                content={t('course.tooltip.trial')}
                              />
                            </div>
                            <div className="field-checkbox mt-2 mb-4 ml-4">
                              <Field
                                disabled={retired || values.customContent}
                                inputId="isStandardCatalogue"
                                name="isStandardCatalogue"
                                label={t('course.isStandardCatalogue')}
                                component={FormikSwitch}
                                value={values.isStandardCatalogue}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setFieldValue(
                                    'isStandardCatalogue',
                                    e.target.value,
                                  );

                                  if (!e.target.value) {
                                    setFieldValue('releaseDate', null);
                                  }
                                }}
                              />

                              <AppIconTooltip
                                target="isStandardCatalogue-icon"
                                content={t(
                                  'course.tooltip.isStandardCatalogue',
                                )}
                              />
                            </div>
                          </FlexContainer>
                          {values.isStandardCatalogue && (
                            <CheckboxesContainer
                              label={t('course.standardCatalogue.settings')}
                            >
                              <div className="field w-8 mb-0">
                                <Field
                                  id="priority"
                                  label={t('course.standardCatalogue.priority')}
                                  name="priority"
                                  className="w-full white"
                                  component={FormikInputNumber}
                                  min={0}
                                  allowEmpty
                                  onChange={(e: InputNumberChangeEvent) =>
                                    setFieldValue('priority', e.value, true)
                                  }
                                  showButtons
                                />
                              </div>
                              <div className="field w-8 mb-0">
                                <Field
                                  id="label"
                                  tooltip={t(
                                    'course.standardCatalogue.label.tooltip',
                                  )}
                                  display="chip"
                                  showClear
                                  label={t('course.standardCatalogue.label')}
                                  name="label"
                                  filter
                                  className="w-full white"
                                  component={FormikMultiSelect}
                                  maxSelectedLabels={3}
                                  placeholder={t('generic.select')}
                                  options={standardCourseLabelOptions(
                                    t,
                                    !!values.releaseDate,
                                  )}
                                  disabled={disabled}
                                />
                              </div>
                              <div className="field w-8 mb-0">
                                <label
                                  htmlFor="course-release-date"
                                  id="release-date-label"
                                >
                                  {t('course.releaseDate')}
                                </label>
                                <AppIconTooltip
                                  target="release-date-label-icon"
                                  content={t('course.releaseDate.tooltip')}
                                />
                                <AppCalendar
                                  id="course-release-date"
                                  name="releaseDate"
                                  className="w-full white-input"
                                  showIcon
                                  iconPos="left"
                                  placeholder={t('generic.select')}
                                  value={values.releaseDate}
                                  minDate={new Date()}
                                  maxDate={moment(
                                    initialValues.releaseDate,
                                  ).toDate()}
                                  onChange={(event: FormEvent) =>
                                    setFieldValue(
                                      'releaseDate',
                                      moment(event?.value?.toString())
                                        .utc(true)
                                        .toDate(),
                                    )
                                  }
                                  showButtonBar
                                  onClearButtonClick={() => {
                                    setFieldValue('releaseDate', null);
                                  }}
                                  readOnlyInput
                                  disabled={values.label?.includes(
                                    PlannerType.ONBOARDING_PLAN,
                                  )}
                                />
                              </div>
                            </CheckboxesContainer>
                          )}
                          <div className="field w-full mb-4">
                            <label htmlFor="eFrontCourses">
                              {`${t('courses.efront')} (${
                                values?.eFrontCourses?.length ?? 0
                              })`}
                            </label>
                            <EfrontCoursesMultiselectInput
                              isDisabled={retired}
                              className="w-full"
                              onChange={(courses) => {
                                setFieldValue('eFrontCourses', courses);
                              }}
                              selectedOptions={values.eFrontCourses}
                            />
                          </div>
                          <div className="field mb-4">
                            <Field
                              disabled={disabled || retired}
                              id="hubspotProperty"
                              label={t('course.hubspotProperty')}
                              name="hubspotProperty"
                              className="w-full"
                              component={FormikInput}
                              placeholder={t('course.hubspotProperty')}
                            />
                          </div>
                          <div className="field mb-4">
                            <Field
                              disabled={disabled || retired}
                              id="hubspotDateProperty"
                              label={t('course.hubspotDateProperty')}
                              name="hubspotDateProperty"
                              className="w-full"
                              component={FormikInput}
                              placeholder={t('course.hubspotDateProperty')}
                            />
                          </div>
                        </>
                      )}
                    </AppFormAccordion>
                  )}
                </div>
              </FlexContainer>
              {!disabled && !initialValues.retired && (
                <FlexContainer justify="space-between">
                  {canUseFeature(Subjects.MULTIPLE_FILE_COURSES) && (
                    <div className="mb-5">
                      <AppButton
                        severity="secondary"
                        type="outlined"
                        label={t('course.add.module')}
                        className="mt-5 mr-3"
                        state={state}
                        onClick={() =>
                          setFieldValue('courseFiles', [
                            ...values.courseFiles,
                            {},
                          ])
                        }
                      />
                    </div>
                  )}
                  <div className="mb-5">
                    <AppButton
                      severity="secondary"
                      type="outlined"
                      label={t('button.cancel')}
                      className="mt-5 mr-3"
                      state={state}
                      onClick={() => cancelHandler(values)}
                    />
                    <AppButton
                      isSubmit
                      severity="success"
                      label={isCreate ? t('button.create') : t('button.save')}
                      className="mt-5"
                      state={state}
                      isDisabled={
                        !!Object.keys(errors).length || isSubmitDisabled
                      }
                    />
                  </div>
                </FlexContainer>
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
