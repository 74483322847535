import { Account, AccountSSOConfig } from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import {
  FormikInput,
  FormikRadio,
  FormikWrappedSwitch,
} from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ssoSchema } from './validations/sso';

type SSOFormProps = {
  initialValues: AccountSSOConfig;
  onSubmit: (data: FormikValues) => void;
  state?: LoadingStateType;
  account?: Account;
  disabled?: boolean;
};
export const SSOForm: React.FC<SSOFormProps> = ({
  initialValues,
  onSubmit,
  state,
  account,
  disabled,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [initialValues]);

  return (
    <Formik
      key={key}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ssoSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors }) => (
        <FormContainer>
          <div className="w-full">
            <Form>
              <CheckboxesContainer
                label={t('account.SSO')}
                className="toggle-container mb-4"
              >
                <Field
                  inputId="isSSO"
                  name="isSSO"
                  label={t('generic.enable')}
                  value={values.isSSO}
                  component={FormikWrappedSwitch}
                  disabled={disabled}
                />
              </CheckboxesContainer>

              {values.isSSO && (
                <>
                  <CheckboxesContainer
                    label={t('generic.configuration')}
                    className="toggle-container mb-4"
                  >
                    <FlexContainer gap={24} justify="flex-start">
                      <div className="field-radio">
                        <Field
                          id="isManual"
                          type="radio"
                          name="isManual"
                          value={true}
                          label={t('sso.manualConfig')}
                          shapeType="circle"
                          checked={values.isManual}
                          component={FormikRadio}
                          disabled={disabled}
                        />
                      </div>
                      <div className="field-radio">
                        <Field
                          id="isManual"
                          type="radio"
                          name="isManual"
                          value={false}
                          label={t('sso.importConfig')}
                          shapeType="circle"
                          checked={!values.isManual}
                          component={FormikRadio}
                          disabled={disabled}
                        />
                      </div>
                    </FlexContainer>
                  </CheckboxesContainer>
                  <div className="field w-full">
                    <Field
                      id="displayName"
                      name="displayName"
                      label={t('sso.displayName')}
                      className="w-full"
                      value={values.displayName}
                      component={FormikInput}
                      disabled={disabled}
                    />
                  </div>

                  {values.isManual && (
                    <>
                      <div className="field w-full">
                        <Field
                          id="idpEntityId"
                          name="idpEntityId"
                          label={t('sso.idpEntityId')}
                          className="w-full"
                          value={values.idpEntityId}
                          component={FormikInput}
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="field w-full">
                        <Field
                          id="singleSignOnServiceUrl"
                          name="singleSignOnServiceUrl"
                          label={t('sso.singleSignOnServiceUrl')}
                          className="w-full"
                          value={values.singleSignOnServiceUrl}
                          component={FormikInput}
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="field w-full">
                        <Field
                          id="signingCertificate"
                          name="signingCertificate"
                          label={t('sso.signingCertificate')}
                          className="w-full"
                          value={values.signingCertificate}
                          component={FormikInput}
                          required
                          disabled={disabled}
                        />
                      </div>
                    </>
                  )}

                  {!values.isManual && (
                    <div className="field w-full">
                      <Field
                        id="importUrl"
                        name="importUrl"
                        label={t('sso.importUrl')}
                        className="w-full"
                        value={values.importUrl}
                        component={FormikInput}
                        required
                        disabled={disabled}
                      />
                    </div>
                  )}
                </>
              )}

              {!disabled && (
                <AppButton
                  isSubmit
                  severity="secondary"
                  label={t('button.save')}
                  className="w-2 min-w-min"
                  state={state}
                  isDisabled={!!Object.keys(errors).length || disabled}
                />
              )}

              {!account?.subdomain && (
                <p>{t('account.sso.subdomain.needed')}</p>
              )}
            </Form>
          </div>
        </FormContainer>
      )}
    </Formik>
  );
};
