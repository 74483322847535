import { FlexContainer } from '@/ui/styled-ui';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  padding-bottom: calc(2 * var(--default-padding));

  > div {
    position: absolute;
    bottom: calc(-1 * var(--xxsmall-padding));
  }
`;

const StyledChartContainer = styled(FlexContainer)`
  .canvas-wrapper {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      bottom: var(--xsmall-padding);
      display: block;
      background-color: var(--gray-dark);
    }
  }
`;

type SecurityCultureDashboardBarChartLoadingProps = {
  key: string;
  isMainDashboard?: boolean;
};

export const SecurityCultureDashboardBarChartLoading: React.FC<
  SecurityCultureDashboardBarChartLoadingProps
> = ({ key, isMainDashboard }) => {
  const { t } = useTranslation();

  return (
    <StyledChartContainer
      direction="column"
      key={key}
      gap={isMainDashboard ? 0 : 10}
      className="relative"
    >
      <Skeleton
        className="chart"
        shape="rectangle"
        height={isMainDashboard ? '100px' : '200px'}
        width={isMainDashboard ? '70px' : '200px'}
      />

      <Skeleton className="mt-2" shape="rectangle" height="12px" />

      {!isMainDashboard && (
        <StyledContainer direction="column" gap={20}>
          <Skeleton />
        </StyledContainer>
      )}
    </StyledChartContainer>
  );
};
