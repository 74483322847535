import { SecurityCultureSurvey } from '@/client/security-culture/types';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CardInfo } from './CardInfo';

const StyledCardContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: var(--medium-padding);

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }
    }
  }
`;

const StyledTableDiv = styled.div`
  width: 100%;

  .table-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid var(--gray-dark);
    padding: var(--xsmall-padding) 0;
    gap: var(--medium-padding);

    &:last-child {
      border-bottom: none;
    }

    .table-text {
      min-width: max-content;
      font-weight: 500;
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
    }

    .table-count {
      text-align: end;
      font-weight: 700;
      font-size: var(--body-font-size);
      line-height: var(--body-line-height);
    }
  }
`;

type SCParticipationProps = {
  survey: SecurityCultureSurvey;
};

export const SCParticipation: React.FC<SCParticipationProps> = ({ survey }) => {
  const { t } = useTranslation();
  return (
    <StyledCardContainer
      id="sc-participation-card"
      direction="column"
      gap={24}
      justify="flex-start"
      flex="0.3"
    >
      <CardInfo
        id="security-culture-participation"
        title={t('security.culture.participation')}
        tooltip={t('security.culture.participation.tooltip')}
      />
      <FlexContainer direction="column" className="mt-5" gap={32}>
        <DoughnutChart
          allCount={survey.totalParticipants || 0}
          singleCount={survey.participated || 0}
          id="sc-participation"
          cutout="70%"
          width="200px"
          isPercentCount
          isDashboard
        />
        <StyledTableDiv>
          <div className="table-row">
            <span className="table-text">
              {t('security.culture.participated.employees')}
            </span>
            <span className="table-count">{survey.participated || 0}</span>
          </div>
          <div className="table-row">
            <span className="table-text">
              {t('security.culture.all.employees')}
            </span>
            <span className="table-count">{survey.totalParticipants || 0}</span>
          </div>
        </StyledTableDiv>
      </FlexContainer>
    </StyledCardContainer>
  );
};
