import { equal, handleAxiosError, isIn } from '@/api/helpers';
import { client } from '@/client';
import {
  SecurityCultureSurvey,
  SurveyStatus,
} from '@/client/security-culture/types';
import { SECURITY_CULTURE_BOOK_DEMO } from '@/common/constants/external-urls';
import { ChangeSurveyTargetsModal } from '@/components/security-culture/modals/ChangeSurveyTargetsModal';
import { SCLegend } from '@/components/security-culture/SCLegend';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import i18n from '@/i18n';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import { ImportantIcon, TargetGroupIcon } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import moment from 'moment';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledIconWrapper = styled.span`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: var(--xxsmall-border-radius);
  background: var(--beige-main);
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background-color: var(--red-pale);

    svg path {
      fill: var(--red-main);
    }
  }
`;

const StyledDemoDialog = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--yellow-opacity);
  border-radius: var(--medium-border-radius);
  padding: var(--default-padding) var(--big-padding);
  flex: 1 1 50%;
  gap: var(--default-padding);
  margin-top: var(--default-padding);

  h3 {
    margin: 0;
    margin-inline: var(--xsmall-padding);
    text-transform: uppercase;
    font-size: var(--heading-small-font-size);
    line-height: var(--heading-small-line-height);
    font-weight: 500;
  }

  p {
    margin: 0;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    &:not(:last-child) {
      padding-bottom: var(--medium-padding);
    }
  }

  a {
    align-self: flex-end;
  }

  button .p-button-label {
    white-space: nowrap;
  }
`;

type SecurityCulturePageActionHeaderProps = {
  surveyIdToFetch?: string;
  setIsLoading?: (isLoading: boolean) => void;
  setVisibleSurvey: (survey: SecurityCultureSurvey) => void;
  setAllSurveys?: (surveys: SecurityCultureSurvey[]) => void;
  fetchInitialSurveyId?: () => void;
};

export const SecurityCulturePageActionHeader: React.FC<
  SecurityCulturePageActionHeaderProps
> = ({
  surveyIdToFetch,
  setIsLoading,
  setVisibleSurvey,
  setAllSurveys,
  fetchInitialSurveyId,
}) => {
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const [period, setPeriod] = useState<SecurityCultureSurvey | null>(null);
  const [surveys, setSurveys] = useState<SecurityCultureSurvey[]>([]);
  const [isSelectTargetGroupModalVisible, setIsSelectTargetGroupModalVisible] =
    useState(false);
  const [showDemoScreen, setShowDemoScreen] = useState<boolean>(false);

  const handleChangeSurveyTargets = async (
    surveys: string[],
    surveyId?: string,
    preselectPeriod?: boolean,
  ) => {
    try {
      const response = await client.securityCulture.getSurveys(
        currentAccount?.id,
        {
          filters: [
            isIn('id', surveys),
            ...(user?.account?.isSystem
              ? []
              : [equal('status', SurveyStatus.PUBLISHED)]),
          ],
          sort: ['date,desc'],
          take: 300,
        },
      );

      const itemsFormatted = response?.result?.map(
        (survey: SecurityCultureSurvey, index: number) => ({
          ...survey,
          dateModified:
            index === 0
              ? t('security.culture.last.measurement')
              : moment(survey.date)
                  .locale(i18n.language)
                  .format(DateFormats.FULL_MONTH_YEAR),
        }),
      );

      setSurveys(itemsFormatted || []);

      if (preselectPeriod) {
        setPeriod(itemsFormatted[0] || null);
      } else {
        setPeriod(
          itemsFormatted.find(
            (survey) => survey.id === surveyId,
          ) as SecurityCultureSurvey,
        );
      }
    } catch (e) {
      handleAxiosError(e, toast);
    }
  };

  useEffect(() => {
    if (period && surveys) {
      setVisibleSurvey(period);
      setShowDemoScreen(!!period?.isDemo);

      if (setAllSurveys) {
        setAllSurveys(surveys);
      }
    }
  }, [period, surveys]);

  const fetchSurvey = async () => {
    if (surveyIdToFetch) {
      try {
        setIsLoading && setIsLoading(true);
        const response = await client.securityCulture.getSurvey(
          currentAccount?.id,
          surveyIdToFetch,
        );

        setShowDemoScreen(!!response?.isDemo);

        // if published survey is preserved in local storage, but the status is changed back to draft
        if (response.status === SurveyStatus.DRAFT && !user?.account.isSystem) {
          fetchInitialSurveyId && fetchInitialSurveyId();
        }

        handleChangeSurveyTargets(
          [
            ...response.relatedSurveys.map(({ id }) => id || ''),
            surveyIdToFetch,
          ],
          surveyIdToFetch,
        );
      } catch (e: any) {
        if (e?.statusCode === 404) {
          fetchInitialSurveyId && fetchInitialSurveyId();
        } else {
          handleAxiosError(e as AxiosError, toast);
        }
      } finally {
        setIsLoading && setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSurvey();
  }, [surveyIdToFetch]);

  const itemTemplate = (
    option: SecurityCultureSurvey & { dateModified: string },
  ) => (
    <FlexContainer justify="space-between">
      <span>{option.dateModified}</span>
      {option?.status === SurveyStatus.PUBLISHED ? (
        <AppChip label={t('generic.published')} type="primary" />
      ) : (
        <AppChip label={t('generic.draft')} type="secondary" />
      )}
    </FlexContainer>
  );

  const DemoDialog = () => (
    <StyledDemoDialog id="sc-demo-dialog">
      <FlexContainer justify="flex-start">
        <ImportantIcon />
        <h3>{t('security.culture.demo.heading')}</h3>
      </FlexContainer>
      <FlexContainer justify="space-between" gap={24}>
        <div>
          <p>{t('security.culture.demo.text.p1')}</p>
          <p>{t('security.culture.demo.text.p2')}</p>
        </div>
        <a
          href={SECURITY_CULTURE_BOOK_DEMO}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppButton
            type="outlined"
            label={t('security.culture.demo.book')}
            icon="pi pi-calendar"
          />
        </a>
      </FlexContainer>
    </StyledDemoDialog>
  );

  return (
    <>
      {showDemoScreen && <DemoDialog />}
      <FlexContainer
        justify="space-between"
        align="flex-end"
        gap={24}
        className={showDemoScreen ? '' : 'mt-4'}
      >
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <i className="pi pi-calendar" />
          </span>
          <Dropdown
            id="sc-period-selector"
            key={period?.id}
            value={period}
            options={surveys}
            optionLabel="dateModified"
            itemTemplate={
              user?.account.isSystem && !currentAccount?.isSystem
                ? itemTemplate
                : undefined
            }
            onChange={(e: DropdownChangeEvent) => setPeriod(e.value)}
          />
        </div>
        <FlexContainer
          direction="column"
          justify="center"
          align="flex-end"
          gap={10}
          className="flex-1"
        >
          <Tooltip
            target="#target-group-action"
            position="left"
            content={t('security.culture.choose.target')}
          />
          <StyledIconWrapper
            id="target-group-action"
            onClick={() => setIsSelectTargetGroupModalVisible(true)}
          >
            <TargetGroupIcon />
          </StyledIconWrapper>
          <ChangeSurveyTargetsModal
            visible={isSelectTargetGroupModalVisible}
            currentSurvey={period}
            onTriggerRefetch={(surveys) =>
              handleChangeSurveyTargets(surveys, '', true)
            }
            onHide={() => setIsSelectTargetGroupModalVisible(false)}
          />
          <SCLegend />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};
