import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import {
  CourseAttempt,
  CourseFileModule,
  CourseStatusAttemptEnum,
} from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppContext } from '@/common/context/AppContext';
import { CourseLanguageSelectScreen } from '@/components/courses/CourseLanguageSelectScreen';
import { useCourse, useCourseAttempt } from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { ScormProvider } from '@/scorm/context/ScormContext';
import { selectCurrentUser, setCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import ScormPDFViewer from '@/ui/pdf-viewer/ScormPDFViewer';
import { FlexContainer } from '@/ui/styled-ui';

import { FileExtensions, FileType } from '@/client/files';
import { Subjects } from '@/client/users';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import {
  filterFilesByLanguageAndExtension,
  getFileType,
  isUserLanguageAvailableInCourse,
} from '@/utils/helpers';
import { headingLargeFontSize } from '@/utils/helpers/css-variables.helper';
import { isMobile } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import OrientationWarning from '../components/OrientationWarning';
import { ScormActionHeader } from '../components/ScormActionHeader';
import { ScormVersion } from '../types';

const StyledFlexContainer = styled(FlexContainer)`
  word-break: break-word;
  min-height: 100%;

  &.stretch {
    height: 100%;
  }

  .min-score-text {
    margin: 0;
    font-size: var(--default-font-size);
  }

  @media screen and (max-width: 424px) {
    &.stretch {
      height: auto;
    }
  }
`;

const StyledButton = styled(AppButton)`
  width: 50px;
  height: 50px;
  padding: 0 !important;

  &.minimize-button,
  &.exit-button,
  &.next-button {
    position: absolute;
    right: var(--default-padding);
    top: var(--medium-padding);
    z-index: 999;
  }

  &.next-button {
    right: calc(var(--default-padding) * 3);
  }

  &.minimize-button {
    background: var(--purple-middle);
    border-color: var(--purple-middle);
  }
`;

const StyledNextButton = styled(AppButton)`
  position: absolute;
  right: -48px;
  top: 50%;
`;

const StyledSpinnerDiv = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--white-main);
  padding: var(--default-padding);
  border-radius: var(--default-border-radius);
  opacity: 0.8;
  z-index: 9;
`;

const StyledContentContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  width: 70%;

  &.mobile {
    position: static;
  }

  &::before {
    content: '';
    width: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: block;
  }

  @media screen and (max-width: 767px) {
    & {
      width: 100%;
    }
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

const StyledVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

const customCoursePropertyValueMap: Record<string, string | boolean> = {
  True: true,
  False: false,
};

export const ScormPlayerPage: React.FC = () => {
  const { t } = useTranslation();
  const { triggerIsFullScreen } = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { canUseFeature } = useFeatureFlag();
  const { id: courseId, attemptId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);
  const [status, setStatus] = useState<CourseStatusAttemptEnum>();
  const [score, setScore] = useState<number>();
  const [isScormStarted, setIsScormStarted] = useState<boolean>(false);
  const [isUrlLoading, setIsUrlLoading] = useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [isSubmittingStatus, setIsSubmittingStatus] = useState<boolean>(false);
  const [isBellowThreshold, setIsBellowThreshold] = useState<boolean>(false);
  const docStyle = getComputedStyle(document.documentElement);

  const [modules, setModules] = useState<CourseFileModule[]>([]);
  const [currentModule, setCurrentModule] = useState<number>(0);

  useEffect(() => {
    if (!triggerIsFullScreen) return;

    if (isMobile()) {
      triggerIsFullScreen(true);
    }

    return () => {
      triggerIsFullScreen(false);
    };
  }, []);

  useEffect(() => {
    // Refetch user data so it is certain that changes like minimum score are up to date
    setIsUserLoading(true);
    const fetchUser = async () => {
      const user = await client.default.getMe();
      dispatch(setCurrentUser(user));
    };
    fetchUser();
    setIsUserLoading(false);
  }, []);

  useEffect(() => {
    if (!currentUser?.firstName || !frameLoaded || !isScormStarted) return;

    let interval: any = null;

    const setUserData = () => {
      if (
        typeof (iframeRef.current?.contentWindow as any)?.GetPlayer ===
        'undefined'
      ) {
        return;
      }

      const player = (iframeRef.current?.contentWindow as any)?.GetPlayer();

      if (player) {
        player.SetVar('Firstname', currentUser?.firstName);
        player.SetVar('ITperson', currentUser?.email);
        player.SetVar('useremail', currentUser?.email);
        player.SetVar('usermanager', currentUser?.meta?.manager ?? '');

        if (
          modules?.length &&
          modules[currentModule]?.scormDetails?.properties &&
          Object.keys(modules[currentModule]?.scormDetails?.properties || {})
            .length > 0
        ) {
          Object.entries(modules[currentModule]?.scormDetails?.properties || {})
            .filter(
              ([, v]) =>
                !!modules[currentModule]?.scormDetails?.properties && !!v,
            )
            .forEach(([k, v]) => {
              let value: string | boolean = v;

              if (customCoursePropertyValueMap[v]) {
                value = customCoursePropertyValueMap[v];
              }

              player.SetVar(k, value);
            });
        }

        if (interval) clearInterval(interval);
      }
    };

    interval = setInterval(setUserData, 200);
  }, [
    frameLoaded,
    currentUser?.firstName,
    currentUser?.email,
    isScormStarted,
    modules?.[currentModule]?.scormDetails?.properties,
  ]);

  // Fetch course data
  const { course, isLoading: isCourseLoading } = useCourse({
    courseId,
    staleTime: Infinity,
  });

  const {
    courseAttempt: scormCourseAttempt,
    isLoading: isAttemptLoading,
    refetch: refetchAttempt,
  } = useCourseAttempt({
    courseId,
    attemptId,
  });

  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  const [attemptLanguage, setAttemptLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  useEffect(() => {
    if (
      (course?.selfHosted && course?.courseLanguages?.length === 1) ||
      !currentUser ||
      !course ||
      isAttemptLoading
    ) {
      return;
    }

    // Set initial preselected language if not available set to English
    setSelectedLanguage(
      scormCourseAttempt?.language
        ? scormCourseAttempt.language
        : currentUser?.language &&
          course &&
          isUserLanguageAvailableInCourse(
            course?.courseFiles,
            currentUser?.language as LanguagesEnum,
          )
        ? (currentUser?.language as LanguagesEnum)
        : course.defaultLanguage || LanguagesEnum.EN,
    );
  }, [currentUser, course, scormCourseAttempt]);

  useEffect(() => {
    setStatus(scormCourseAttempt?.status);
    setScore(scormCourseAttempt?.score);
  }, [scormCourseAttempt]);

  useEffect(() => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      setIsScormStarted(true);
    }

    if (scormCourseAttempt?.language) {
      setIsScormStarted(true);
    }

    fetchData();
  }, [course, scormCourseAttempt]);

  useEffect(() => {
    fetchData();
    setCurrentModule(0);
  }, [selectedLanguage]);

  useEffect(() => {
    if (attemptLanguage) {
      const saveAttemptLanguage = async () => {
        if (course?.id && attemptId && attemptLanguage) {
          const attempt = await client.learnerCourses.updateCourseAttempt({
            courseId: course.id,
            attemptId: attemptId,
            language: attemptLanguage,
          });

          if (attempt.id !== attemptId) {
            navigate(
              RedirectPaths[RedirectPathsEnum.COURSE_PLAY](
                course.id,
                attempt.id,
              ),
              { replace: true },
            );
          }
        }
      };
      saveAttemptLanguage();
    }
  }, [attemptLanguage]);

  useEffect(() => {
    if (attemptId !== scormCourseAttempt?.id) {
      refetchAttempt();
    }
  }, [attemptId]);

  const getLanguage = () => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      return course?.courseLanguages[0];
    }

    if (course && !course?.selfHosted) {
      return LanguagesEnum.EN;
    }

    return selectedLanguage;
  };

  const fetchData = async () => {
    const courseLanguage = getLanguage();
    setAttemptLanguage(courseLanguage);

    if (course && courseLanguage) {
      const materials = filterFilesByLanguageAndExtension(
        course.courseFiles,
        courseLanguage,
        [...FileExtensions.PDF, ...FileExtensions.VIDEO],
      );

      const scormFiles = filterFilesByLanguageAndExtension(
        course.courseFiles,
        courseLanguage,
        FileExtensions.SCORM,
      );

      try {
        setIsUrlLoading(true);

        const scorms = await Promise.all(
          scormFiles.map(async (file) => {
            if (!file?.id) return file;

            const scormDetails = await client.courses.getCourseUrl(
              course.id,
              file?.id,
            );
            return { ...file, scormDetails };
          }),
        );

        // Combine materials and scorms and sort by priority
        const modules = [...materials, ...scorms]
          .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
          .map((module) => ({
            ...module,
            type: getFileType(module.file?.extension),
          }));

        setModules(modules);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsUrlLoading(false);
      }
    }
  };

  return (
    <>
      {(!currentUser ||
        isAttemptLoading ||
        isCourseLoading ||
        isUserLoading) && (
        <FlexContainer direction="column" className="h-full">
          <FlexContainer direction="column" className="mt-5 flex-1">
            <ProgressSpinner />
            <h3>{t('course.loading')}</h3>
          </FlexContainer>
        </FlexContainer>
      )}

      {!isAttemptLoading &&
        !isCourseLoading &&
        currentUser &&
        course &&
        !isUserLoading && (
          <StyledFlexContainer
            direction="column"
            align="flex-start"
            justify="flex-start"
            className={!isScormStarted && course.selfHosted ? 'stretch' : ''}
            gap={24}
          >
            {!isMobile() && (
              <ScormActionHeader
                course={course}
                status={status}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                isScormStarted={isScormStarted}
                isDisabled={isSubmittingStatus}
              />
            )}

            {/* VIDEO */}
            {isScormStarted &&
              modules?.[currentModule]?.file &&
              modules?.[currentModule]?.type === FileType.VIDEO && (
                <StyledContentContainer>
                  <StyledVideo
                    src={modules?.[currentModule]?.file?.signedUrl}
                    controls
                    autoPlay
                    onEnded={() =>
                      currentModule !== modules.length - 1 &&
                      setCurrentModule(currentModule + 1)
                    }
                  />
                </StyledContentContainer>
              )}

            {/* PDF */}
            {isScormStarted &&
              modules?.[currentModule]?.file &&
              modules?.[currentModule]?.type === FileType.PDF && (
                <ScormPDFViewer
                  file={modules[currentModule].file}
                  hideNextButton={currentModule === modules.length - 1}
                  onComplete={() => setCurrentModule(currentModule + 1)}
                />
              )}

            {/* SCORM */}
            {(isScormStarted || !course.selfHosted) &&
              !isUrlLoading &&
              !isBellowThreshold &&
              !!modules?.length &&
              modules?.[currentModule]?.scormDetails &&
              modules?.[currentModule]?.type === FileType.SCORM && (
                <ScormProvider
                  course={course}
                  attempt={scormCourseAttempt as CourseAttempt}
                  version={
                    modules[currentModule].scormDetails?.version as ScormVersion
                  }
                  user={currentUser}
                  setStatus={setStatus}
                  setScore={setScore}
                  setIsSubmittingStatus={setIsSubmittingStatus}
                  setIsBellowThreshold={setIsBellowThreshold}
                  isPreview={false}
                  isLastModule={currentModule === modules.length - 1}
                  setNextModule={() => setCurrentModule(currentModule + 1)}
                >
                  <StyledContentContainer
                    className={isMobile() ? 'mobile' : undefined}
                  >
                    {isSubmittingStatus && (
                      <StyledSpinnerDiv>
                        <ProgressSpinner />
                        <span>{t('course.processing.results')}</span>
                      </StyledSpinnerDiv>
                    )}

                    {frameLoaded && <OrientationWarning />}

                    {isMobile() && (
                      <>
                        {status === CourseStatusAttemptEnum.COMPLETED &&
                          currentModule !== modules.length - 1 && (
                            <StyledButton
                              className="next-button"
                              size="xs"
                              icon="pi pi-chevron-right"
                              isDisabled={isSubmittingStatus}
                              onClick={() =>
                                setCurrentModule(currentModule + 1)
                              }
                            />
                          )}
                        <StyledButton
                          className="exit-button"
                          size="xs"
                          icon="pi pi-times"
                          isDisabled={isSubmittingStatus}
                          onClick={() =>
                            navigate(
                              RedirectPaths[
                                RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                              ](),
                            )
                          }
                        />
                      </>
                    )}

                    <StyledIframe
                      src={modules?.[currentModule]?.scormDetails?.url}
                      ref={iframeRef}
                      style={{
                        visibility:
                          isSubmittingStatus &&
                          (canUseFeature(Subjects.SKIP_COURSE_SURVEY) ||
                            canUseFeature(
                              Subjects.COURSE_MINIMUM_SCORE_REQUIREMENT,
                            ))
                            ? 'hidden'
                            : 'visible',
                      }}
                      onLoad={() => setFrameLoaded(true)}
                    />
                    {status === CourseStatusAttemptEnum.COMPLETED &&
                      currentModule !== modules.length - 1 &&
                      !isMobile() && (
                        <StyledNextButton
                          size="xs"
                          icon="pi pi-chevron-right"
                          isDisabled={isSubmittingStatus}
                          onClick={() => setCurrentModule(currentModule + 1)}
                        />
                      )}
                  </StyledContentContainer>
                </ScormProvider>
              )}

            {/* RETAKE SCREEN */}
            {isBellowThreshold &&
              !!currentUser?.account?.courseMinimumScore &&
              canUseFeature(Subjects.COURSE_MINIMUM_SCORE_REQUIREMENT) && (
                <FlexContainer direction="column" gap={20} className="m-auto">
                  <h1
                    style={{
                      fontSize: headingLargeFontSize(docStyle),
                    }}
                    className="mb-5"
                  >
                    {t('course.retake.heading', {
                      percent: currentUser.account.courseMinimumScore,
                    })}
                  </h1>
                  <p className="min-score-text">{t('course.yourScore')}</p>
                  <DoughnutChart
                    id="score"
                    width="150"
                    cutout="90%"
                    isPercentCount
                    singleCount={score || 0}
                    allCount={100}
                    minScore={currentUser.account.courseMinimumScore}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('course.retake.p', {
                        percent: `<strong>${currentUser.account.courseMinimumScore}%</strong>`,
                      }),
                    }}
                    className="min-score-text text-center mt-5"
                  />
                  <AppButton
                    label={t('course.retake')}
                    icon="pi pi-refresh"
                    iconPos="right"
                    isDisabled={isSubmittingStatus}
                    onClick={() => setIsBellowThreshold(false)}
                  />
                </FlexContainer>
              )}

            {/* LANGUAGE SELECT SCREEN */}
            {!isScormStarted &&
              course.selfHosted &&
              !scormCourseAttempt?.language && (
                <CourseLanguageSelectScreen
                  course={course}
                  language={selectedLanguage}
                  isLoading={isUrlLoading}
                  isDisabled={!modules?.length}
                  setIsCourseStarted={setIsScormStarted}
                  setSelectedLanguage={setSelectedLanguage}
                />
              )}
          </StyledFlexContainer>
        )}
    </>
  );
};
