import { EMAIL_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const testEmailSchema = (
  t: TranslationFunctionType,
  confirmWhitelisting?: boolean,
) =>
  object().shape(
    {
      emailType: string().oneOf(['custom', 'admin']),
      email: string()
        .required(t('error.requiredField'))
        .matches(EMAIL_REGEX, 'error.invalidEmail'),
      firstName: string().nullable(),
      lastName: string().nullable(),
      position: string().nullable(),
      admin: object().when(['emailType'], ([emailType], schema) =>
        emailType === 'admin'
          ? schema.required(t('error.testEmail.admin'))
          : schema,
      ),
      isWhitelisted: confirmWhitelisting
        ? boolean().oneOf([true], t('error.requiredField'))
        : boolean().nullable(),
    },
    [['admin', 'emailType']],
  );
