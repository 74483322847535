import { equal } from '@/api/helpers';
import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { AppContext } from '@/common/context/AppContext';
import { FlexContainer } from '@/ui/styled-ui';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LazyLoadingMultiselect } from '../generic';

const StyledFlexContainer = styled(FlexContainer)`
  width: max-content;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--xxsmall-padding);
  label {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: normal;
    color: var(--black-main);
  }
  .p-circle .p-radiobutton-box {
    border-color: var(--gray-dark) !important;
  }
`;

type AccountSelectInputProps = {
  onChange: (accounts: Account[]) => void;
  selectedOptions?: Account[];
  additionalFilters?: FiltersType;
  courseId?: string;
  isUnenroll?: boolean;
  getOnlyUnavailableAccounts?: boolean;
  forceRefetch?: boolean;
  isDisabled?: boolean;
  isFeatureFlag?: boolean;
} & HTMLAttributes<HTMLInputElement>;

export const AccountsMultiselectInput: React.FC<AccountSelectInputProps> = ({
  selectedOptions,
  onChange,
  additionalFilters,
  courseId,
  isUnenroll,
  getOnlyUnavailableAccounts,
  forceRefetch,
  isDisabled,
  isFeatureFlag,
}) => {
  const { t } = useTranslation();
  const { state, clearTrigger } = useContext(AppContext);
  const [refetchKey, setRefetchKey] = useState<number>(0);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>(
    selectedOptions || [],
  );
  const [featureFlagsFilters, setFeatureFlagsFilters] = useState<{
    all?: FiltersType;
    active?: FiltersType;
    freeTrial?: FiltersType;
  }>({ all: [] });

  const fetchUnavailableAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.courses.getUnavailableAccounts(params, courseId);

  const fetchAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        isUnenroll
          ? await client.courses.getCourseAccounts(params, courseId)
          : await client.accounts.getAccounts(params);

  useEffect(() => {
    if (!forceRefetch && !state.isAccountsLengthChanged) {
      return;
    }

    setRefetchKey(refetchKey + 1);

    if (state.isAccountsLengthChanged && clearTrigger) {
      clearTrigger();
    }
  }, [forceRefetch, state.isAccountsLengthChanged]);

  useEffect(() => {
    setSelectedAccounts(selectedOptions || []);
    onChange(selectedOptions as Account[]);
  }, [selectedOptions]);

  const filterTemplateAddition = (
    <FlexContainer gap={12} className="mt-2" align="flex-start">
      <StyledFlexContainer justify="flex-start" className="p-multiselect-item">
        <RadioButton
          className="p-circle"
          inputId="showAllFilter"
          name="showAllFilter"
          checked={!!featureFlagsFilters?.all}
          onChange={(e: RadioButtonChangeEvent) => {
            setFeatureFlagsFilters({
              all: e.checked ? [] : undefined,
            });
          }}
        />
        <label htmlFor="showAllFilter">{t('generic.showAll')}</label>
      </StyledFlexContainer>
      <StyledFlexContainer justify="flex-start" className="p-multiselect-item">
        <RadioButton
          className="p-circle"
          inputId="activeFilter"
          name="activeFilter"
          checked={!!featureFlagsFilters?.active}
          onChange={(e: RadioButtonChangeEvent) => {
            setFeatureFlagsFilters({
              active: e.checked
                ? [equal('active', true), equal('isFreeTrial', false)]
                : undefined,
            });
          }}
        />
        <label htmlFor="activeFilter">{t('feature.filter.active')}</label>
      </StyledFlexContainer>
      <StyledFlexContainer justify="flex-start" className="p-multiselect-item">
        <RadioButton
          className="p-circle"
          inputId="freeTrialFilter"
          name="freeTrialFilter"
          checked={!!featureFlagsFilters?.freeTrial}
          onChange={(e: RadioButtonChangeEvent) => {
            setFeatureFlagsFilters({
              freeTrial: e.checked ? [equal('isFreeTrial', true)] : undefined,
            });
          }}
        />
        <label htmlFor="freeTrialFilter">{t('feature.filter.freeTrial')}</label>
      </StyledFlexContainer>
    </FlexContainer>
  );

  return (
    <LazyLoadingMultiselect
      fetchService={
        getOnlyUnavailableAccounts ? fetchUnavailableAccounts : fetchAccounts
      }
      selectedValues={selectedAccounts || []}
      handleOnChange={onChange}
      filter
      filterPlaceholder={t('accounts.search')}
      optionLabel="name"
      placeholder={t('accounts.select')}
      refetchKey={refetchKey}
      isDisabled={isDisabled}
      className="w-full"
      maxSelectedItems={5}
      filterTemplateAddition={
        isFeatureFlag ? filterTemplateAddition : undefined
      }
      additionalFilters={[
        ...(additionalFilters ?? []),
        ...(isFeatureFlag ? Object.values(featureFlagsFilters)[0] ?? [] : []),
      ]}
      panelClassName={isFeatureFlag ? 'bulk' : undefined}
    />
  );
};
