import {
  languageDisplayCode,
  languageDisplayLabel,
  LanguagesEnum,
} from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { Course } from '@/client/courses';
import { HubspotProperty } from '@/client/hubspot/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { isUserLanguageAvailableinMaterial } from '@/utils/helpers';
import { downloadFileByUrl } from '@/utils/helpers/download.helper';
import { isMobile } from '@/utils/helpers/ui.helper';
import { hubspotTimestamp, hubspotTrack } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledImageCard = styled.div`
  &.poster-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-dark);
    border-radius: var(--small-border-radius);
    overflow: hidden;
    background-color: var(--white-main);
    position: relative;
    padding: var(--default-padding);

    &.with-actions {
      padding: var(--default-padding) var(--big-padding);
    }

    &.mobile {
      padding: var(--medium-padding);
    }
    &.disabled {
      opacity: 0.6;
      pointer-events: none !important;
    }

    .thumbnail-container {
      width: 100%;
      max-width: 270px;
      height: 384px;

      &:has(.landscape) {
        max-width: 350px;
      }
      position: relative;
      display: flex;
      align-items: stretch;
      overflow: hidden;
      margin-bottom: var(--default-padding);

      .image-card-thumbnail {
        border-radius: var(--xxxsmall-border-radius);

        &.landscape {
          background-size: contain;
        }

        &.portrait {
          background-size: cover;
        }
        background-position: center center;
        background-repeat: no-repeat;
        background-color: var(--gray-main);
      }
    }

    .actions {
      justify-content: flex-end;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: var(--small-padding);
    }

    .buttons {
      width: 270px;
      overflow: hidden;
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
`;

const StyledAppButton = styled(AppButton)`
  &.p-button {
    padding: 0 var(--default-padding);
    border-radius: var(--default-border-radius);

    .p-button-icon {
      font-size: var(--small-font-size);
    }
  }
`;

const ActionsButton = styled(AppButton)`
  &.p-button {
    .p-button-text {
      background-color: var(--white-main) !important;
    }
  }
`;

const StyledDropdownLang = styled(Dropdown)`
  flex-grow: 1;
  min-width: 100px;
  .p-dropdown-label {
    padding-right: 0;
  }
`;

export type PosterCardProps = {
  poster: Course;
  isLearnerView?: boolean;
  additionalClass?: string;
  isDisabled?: boolean;
  withActions?: boolean;
};

export const PosterCard: React.FC<PosterCardProps> = ({
  poster,
  isLearnerView,
  additionalClass,
  isDisabled,
  withActions,
  ...rest
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const currentUser = useAppSelector(selectCurrentUser);
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();

  const [isLandscape, setIsLandscape] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const imageUrl = poster?.courseThumbnail?.signedUrl;

    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setIsLandscape(img.naturalWidth > img.naturalHeight + 100);
        setIsImageLoaded(true);
      };
    } else {
      setIsImageLoaded(true);
    }
  }, [poster?.courseThumbnail?.signedUrl]);

  const posterLanguageOptions = poster.courseLanguages.map((x) => ({
    label: languageDisplayLabel[x],
    value: x,
  }));

  const itemActionMenu = useRef<any>(null);
  const toggleItemActionMenu = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (!itemActionMenu.current) {
      return;
    }
    itemActionMenu.current.toggle(event);
  };

  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguagesEnum | undefined
  >(
    currentUser?.language &&
      isUserLanguageAvailableinMaterial(
        poster.courseLanguages,
        currentUser?.language as LanguagesEnum,
      )
      ? (currentUser?.language as LanguagesEnum)
      : poster.defaultLanguage,
  );

  const items = [
    {
      label: account?.isSystem ? t('generic.edit') : t('generic.view.details'),
      icon: account?.isSystem ? 'pi pi-pencil' : 'pi pi-bars',
      command: () => {
        navigate(RedirectPaths[RedirectPathsEnum.POSTER_EDIT](poster.id));
      },
    },
  ];

  const className = classNames(
    'poster-card',
    withActions ? 'with-actions' : '',
    isMobile() ? 'mobile' : '',
    {
      disabled: isDisabled,
    },
    additionalClass,
  );

  const downloadHandler = async (
    selectedLanguage: LanguagesEnum | undefined,
  ) => {
    if (!selectedLanguage) return;

    try {
      const fetchedPoster = await client.courses.getCourse(poster.id);
      const courseFile = fetchedPoster.courseFiles.find(
        (file) => file.language === selectedLanguage,
      );

      if (!courseFile) return;

      if (courseFile.file) {
        downloadFileByUrl(courseFile.file);

        hubspotTrack(HubspotProperty.POSTERS_DOWNLOADED, hubspotTimestamp());

        toast?.success(t('toast.success'), t('material.download.success'));
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <StyledImageCard id={poster.id} className={className} {...rest}>
      {withActions && account?.isSystem && (
        <FlexContainer className="actions">
          <Menu model={items} popup ref={itemActionMenu} id="image_card_menu" />
          <ActionsButton
            className="poster-actions"
            icon="pi pi-ellipsis-h"
            type="text"
            aria-controls="image_card_menu"
            onClick={toggleItemActionMenu}
            severity="secondary"
          />
        </FlexContainer>
      )}

      <div className="thumbnail-container">
        {isImageLoaded ? (
          <label
            className={`${
              isLandscape ? 'landscape' : 'portrait'
            } image-card-thumbnail flex-1`}
            style={{
              backgroundImage: `url(${CSS.escape(
                poster.courseThumbnail?.signedUrl ?? PagePlaceholderImage,
              )}`,
            }}
          />
        ) : (
          <Skeleton height="100%" />
        )}
      </div>

      <FlexContainer className="buttons" gap={12} justify="space-between">
        <StyledDropdownLang
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          valueTemplate={(option) => (
            <>{option?.value && <>{languageDisplayCode[option.value]}</>}</>
          )}
          itemTemplate={(option) => (
            <>
              {option && (
                <>{`${languageDisplayCode[option.value]} | ${option.label}`}</>
              )}
            </>
          )}
          options={posterLanguageOptions}
          placeholder={t('materials.selectLanguage')}
        />

        <StyledAppButton
          icon="pi pi-download"
          size="sm"
          label={t('generic.download')}
          severity="secondary"
          onClick={() => downloadHandler(selectedLanguage)}
          isDisabled={!selectedLanguage || !isImageLoaded}
        />
      </FlexContainer>
    </StyledImageCard>
  );
};
