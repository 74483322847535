import { Account, AccountCourseDelivery } from '@/client/accounts';
import {
  Integrations,
  UserManagementFormIndexValueMapping,
  UserManagementMapping,
  UserManagementMeta,
} from '@/client/accounts/types/account-user-management.type';
import { MaterialType } from '@/client/courses';
import { SystemRoles, User } from '@/client/users';
import { TranslationFunctionType } from '@/common/types';
import { v4 } from 'uuid';

export const accountHasDocuments = (account: Account | null): boolean =>
  !!account?.availableCoursesByType &&
  !!Object.keys(account?.availableCoursesByType).find(
    (x) => (MaterialType.RESOURCE as string) === x,
  );

export const courseDeliveryLmsOptions = (
  t: TranslationFunctionType,
  isFilter?: boolean,
) => [
  ...(isFilter
    ? [
        {
          label: t('cp.app'),
          value: AccountCourseDelivery.CP_APP,
        },
      ]
    : []),
  {
    label: t('account.courseDelivery.thirdParty'),
    value: AccountCourseDelivery.THIRD_PARTY,
  },
  {
    label: t('account.courseDelivery.videbarometer'),
    value: AccountCourseDelivery.VIDENBAROMETER,
  },
];

export const displayLmsOption = (t: TranslationFunctionType) => ({
  [AccountCourseDelivery.CP_APP]: t('cp.app'),
  [AccountCourseDelivery.THIRD_PARTY]: t('account.courseDelivery.thirdParty'),
  [AccountCourseDelivery.VIDENBAROMETER]: t(
    'account.courseDelivery.videbarometer',
  ),
});

export const branchAdminCheck = (
  user: User | null,
  currentAccount: Account | null,
) =>
  !!user?.role.code &&
  [SystemRoles.ADMIN, SystemRoles.MULTIPLE_ACCOUNTS_ADMIN].includes(
    user?.role.code,
  ) &&
  !!user.branch?.id &&
  user.account.id === currentAccount?.id;

export const cannotManageMultiAdmins = (
  selectedUser?: User,
  currentUser?: User | null,
) =>
  Boolean(
    selectedUser?.role?.code === SystemRoles.MULTIPLE_ACCOUNTS_ADMIN &&
      currentUser &&
      ![SystemRoles.DEVELOPER, SystemRoles.SUPER_ADMIN].includes(
        currentUser?.role.code,
      ),
  );

export const getAccountIntegration = (
  account?: Account | null,
): UserManagementMeta | undefined =>
  account?.activeDirectoryIntegration?.find(
    (integration) => integration.active,
  );

export const transformMappingValues = (
  data: UserManagementMapping[],
  initialMapping: UserManagementFormIndexValueMapping,
  currentAccount: Account | null,
  account: Account,
): UserManagementFormIndexValueMapping => {
  if (!data?.length) return initialMapping;

  const mappedObject: UserManagementFormIndexValueMapping = {};

  data.map((value) => {
    mappedObject[v4()] = {
      ...value,
      ...(!value.branches.length && currentAccount ? { account: account } : {}),
    };
  });
  return mappedObject;
};
