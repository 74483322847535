import { Account, AccountGoogleSSOConfig } from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import { FormikInput, FormikWrappedSwitch } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { googleSSOSchema } from './validations/google-sso';

type GoogleSSOFormProps = {
  initialValues: AccountGoogleSSOConfig;
  onSubmit: (data: FormikValues) => void;
  state?: LoadingStateType;
  account?: Account;
  disabled?: boolean;
};
export const GoogleSSOForm: React.FC<GoogleSSOFormProps> = ({
  initialValues,
  onSubmit,
  state,
  account,
  disabled,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [initialValues]);

  return (
    <Formik
      key={key}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={googleSSOSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors }) => (
        <FormContainer>
          <div className="w-full">
            <Form>
              <CheckboxesContainer
                label={t('account.SSO')}
                className="toggle-container mb-4"
              >
                <Field
                  inputId="isSSO"
                  name="isSSO"
                  label={t('generic.enable')}
                  value={values.isSSO}
                  component={FormikWrappedSwitch}
                  disabled={disabled}
                />
              </CheckboxesContainer>

              {values.isSSO && (
                <>
                  <div className="field w-full">
                    <Field
                      id="displayName"
                      name="displayName"
                      label={t('google.sso.displayName')}
                      className="w-full"
                      value={values.displayName}
                      component={FormikInput}
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="clientId"
                      name="clientId"
                      label={t('google.sso.clientId')}
                      className="w-full"
                      value={values.clientId}
                      component={FormikInput}
                      disabled={disabled}
                      required
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="clientSecret"
                      name="clientSecret"
                      label={t('google.sso.clientSecret')}
                      className="w-full"
                      value={values.clientSecret}
                      component={FormikInput}
                      disabled={disabled}
                      required
                    />
                  </div>
                </>
              )}

              {!disabled && (
                <AppButton
                  isSubmit
                  severity="secondary"
                  label={t('button.save')}
                  className="w-2 min-w-min"
                  state={state}
                  isDisabled={!!Object.keys(errors).length || disabled}
                />
              )}

              {!account?.subdomain && (
                <p>{t('account.sso.subdomain.needed')}</p>
              )}
            </Form>
          </div>
        </FormContainer>
      )}
    </Formik>
  );
};
