import { equal, notEqual } from '@/api/helpers';
import { FilterCondtitionType } from '@/api/types';
import { Account } from '@/client/accounts';
import {
  Course,
  MakeAvailableCoursesToAccountFormValues,
  MaterialType,
} from '@/client/courses';
import { accountToCoursesAvailabilitySchema } from '@/components/courses/validations/course-availability';
import { CoursesMultiselectInput } from '@/components/form/selectors';
import { useUnavailableCoursesToAccount } from '@/hooks/query';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Form, Formik } from 'formik';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--small-padding);
  margin-left: -42px;
  label {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: normal;
    color: var(--red-main);
  }
`;

const StyledDialog = styled(Dialog)`
  width: 550px;
`;

type AccountAvailabilityModalProps = {
  onSubmit: (data: MakeAvailableCoursesToAccountFormValues) => void;
  account: Account;
  availableCoursesLength?: number;
  isMaterial?: boolean;
} & DialogProps;

export const AccountAvailabilityModal: React.FC<
  AccountAvailabilityModalProps
> = ({
  onSubmit,
  onHide,
  account,
  visible,
  availableCoursesLength,
  isMaterial,
}) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>('');

  const standartCatalogueCoursesFilters = [
    equal('isStandardCatalogue', true),
    equal('retired', false),
    isMaterial
      ? notEqual('type', MaterialType.COURSE)
      : equal('type', MaterialType.COURSE),
  ] as FilterCondtitionType[];

  const coursesFilters = [
    equal('retired', false),
    equal('account', null),
    isMaterial
      ? notEqual('type', MaterialType.COURSE)
      : equal('type', MaterialType.COURSE),
  ] as FilterCondtitionType[];

  const {
    courses: standardCatalogue,
    isLoading: isStandardCatalogueLoading,
    refetch: refetchStandardCatalogue,
  } = useUnavailableCoursesToAccount({
    enabled: false,
    filters: standartCatalogueCoursesFilters,
    accountId: account?.id,
  });

  useEffect(() => {
    if (!visible) return;
    refetchStandardCatalogue();
  }, [availableCoursesLength]);

  const [initialValues] = useState<MakeAvailableCoursesToAccountFormValues>({
    courses: [],
    isCatalogueEnabled: false,
    isPosterCatalogueEnabled: false,
  });

  const areAllStandardSelected = (selectedCourses: Course[]) => {
    const checkIfAllFromCatalogueisSelected =
      standardCatalogue?.length &&
      standardCatalogue
        ?.filter((course) => course.type !== MaterialType.POSTER)
        .every((standardCourse) =>
          selectedCourses.find((course) => course.id === standardCourse.id),
        );

    return !!checkIfAllFromCatalogueisSelected;
  };

  const areAllStandardPostersSelected = (selectedCourses: Course[]) => {
    const checkIfAllFromCatalogueisSelected =
      standardCatalogue?.length &&
      standardCatalogue
        ?.filter((course) => course.type === MaterialType.POSTER)
        .every((standardCourse) =>
          selectedCourses.find((course) => course.id === standardCourse.id),
        );

    return !!checkIfAllFromCatalogueisSelected;
  };

  const handleCatalogueChange = (
    isEnabled: boolean,
    courses: Course[],
    setFieldValue: (field: string, value: any) => void,
    isPoster?: boolean,
  ) => {
    if (!standardCatalogue?.length) return;

    if (isEnabled) {
      const notSelectedStandardCatalogueCourses = standardCatalogue.filter(
        (course) =>
          !courses.find((x) => x.id === course.id) &&
          course.type !== MaterialType.POSTER,
      );

      const notSelectedStandardCataloguePosters = standardCatalogue.filter(
        (course) =>
          !courses.find((x) => x.id === course.id) &&
          course.type === MaterialType.POSTER,
      );

      setFieldValue('courses', [
        ...courses,
        ...(isPoster
          ? notSelectedStandardCataloguePosters
          : notSelectedStandardCatalogueCourses),
      ]);
    } else {
      const selectedCoursesWithoutStandardCatalogueCourses = courses.filter(
        (course) =>
          !standardCatalogue
            .filter((course) =>
              isPoster
                ? course.type === MaterialType.POSTER
                : course.type !== MaterialType.POSTER,
            )
            .find((x) => course.id === x.id),
      );

      setFieldValue('courses', selectedCoursesWithoutStandardCatalogueCourses);
    }
  };

  const handleOnValueChange = (
    courses: Course[],
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('isCatalogueEnabled', areAllStandardSelected(courses));
    setFieldValue(
      'isPosterCatalogueEnabled',
      areAllStandardPostersSelected(courses),
    );

    setFieldValue('courses', courses);
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('generic.accounts.available')}</h1>}
      onHide={onHide}
      onShow={refetchStandardCatalogue}
      draggable={false}
      data-testid="account-to-courses-availability-modal"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={accountToCoursesAvailabilitySchema(t)}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => {
          const filterTemplate = ({ filterOptions }: any) => {
            return (
              <div className="p-dropdown-filter-container w-full">
                <span className="p-input-icon-right w-full">
                  <i className="pi pi-search" />
                  <InputText
                    className="p-multiselect-filter p-inputtext p-component w-full"
                    placeholder={
                      isMaterial
                        ? t('material.search.name')
                        : t('courses.search.name')
                    }
                    defaultValue={filterValue}
                    autoFocus
                    onChange={(e: FormEvent) => filterOptions.filter(e)}
                    onBlur={(e) => setFilterValue(e.target.value)}
                  />
                </span>
                <StyledFlexContainer
                  justify="flex-start"
                  className="p-multiselect-item"
                >
                  <Checkbox
                    disabled={
                      !standardCatalogue?.find(
                        (course) => course.type !== MaterialType.POSTER,
                      )
                    }
                    inputId="isStandardCatalogueEnabled"
                    name="isStandardCatalogueEnabled"
                    checked={values.isCatalogueEnabled}
                    onChange={(e: CheckboxChangeEvent) => {
                      setFieldValue('isCatalogueEnabled', e.checked);
                      handleCatalogueChange(
                        !!e.checked,
                        values.courses,
                        setFieldValue,
                      );
                    }}
                  />
                  <label htmlFor="isStandardCatalogueEnabled">
                    {isMaterial
                      ? t('resources.isStandardCatalogue.select')
                      : t('course.isStandardCatalogue.select')}
                  </label>
                </StyledFlexContainer>
                {isMaterial && (
                  <StyledFlexContainer
                    justify="flex-start"
                    className="p-multiselect-item"
                  >
                    <Checkbox
                      disabled={
                        !standardCatalogue?.find(
                          (course) => course.type === MaterialType.POSTER,
                        )
                      }
                      inputId="isPosterCatalogueEnabled"
                      name="isPosterCatalogueEnabled"
                      checked={values.isPosterCatalogueEnabled}
                      onChange={(e: CheckboxChangeEvent) => {
                        setFieldValue('isPosterCatalogueEnabled', e.checked);
                        handleCatalogueChange(
                          !!e.checked,
                          values.courses,
                          setFieldValue,
                          true,
                        );
                      }}
                    />
                    <label htmlFor="isPosterCatalogueEnabled">
                      {t('posters.isStandardCatalogue.select')}
                    </label>
                  </StyledFlexContainer>
                )}
              </div>
            );
          };

          return (
            <Form>
              <div className="field w-full mb-4">
                <label htmlFor="courses">
                  {`${values?.courses?.length ?? 0} ${
                    isMaterial ? t('materials.selected') : t('courses.selected')
                  }`}
                </label>

                <CoursesMultiselectInput
                  className="w-full"
                  accountId={account?.id}
                  additionalFilters={coursesFilters}
                  selectedOptions={values.courses}
                  filterTemplate={filterTemplate}
                  panelClassName="bulk"
                  placeholder={
                    isMaterial ? t('materials.select') : t('courses.select')
                  }
                  isDisabled={isStandardCatalogueLoading}
                  onChange={(courses) =>
                    handleOnValueChange(courses, setFieldValue)
                  }
                />
              </div>

              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                  data-testid="account-to-courses-availability-cancel-form"
                />
                <AppButton
                  label={t('generic.accounts.available')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                  data-testid="account-to-courses-availability-submit-form"
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
