import { UserOrigin } from '@/client/users/types/origins.enum';
import { useSignOut } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { LoadingPage } from '@/pages/LoadingPage';
import { selectCurrentUser } from '@/store/features/users';
import { revokeHubspotCookies } from '@/utils/hubspot';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const LogoutPage = () => {
  const { signoutSilent, signoutRedirect, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const [redirect, setRedirect] = useState<boolean>(false);
  const { request } = useSignOut();

  const signout = async () => {
    await request();

    revokeHubspotCookies();

    if (
      currentUser?.account?.meta?.isSSO &&
      currentUser.origin !== UserOrigin.MANUAL
    ) {
      sessionStorage.clear();
      await signoutRedirect();
    } else {
      if (isAuthenticated) {
        await signoutSilent();
      }

      sessionStorage.clear();

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setRedirect(true);
    }
  };

  useEffect(() => {
    signout();
  }, [isAuthenticated]);

  if (!redirect) {
    return <LoadingPage message={t('auth.signOut.wait')} />;
  }

  const deployPath = process.env.REACT_APP_PREVIEW_DEPLOY_PATH;
  const basename = deployPath ? `/${deployPath}` : '';

  window.location.href = window.location.origin + basename;

  return null;
};

export default LogoutPage;
