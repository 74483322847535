import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import {
  ListSystemEmailTemplatesRequest,
  ListSystemEmailTemplatesResponse,
  SaveSystemEmailTemplate,
  SendTestSystemEmail,
  SystemEmailTemplate,
  UpdateSystemEmailTemplate,
} from '@/client/system-emails';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const systemEmailsTemplatesService = (client: AxiosInstance) => {
  const BASE_SYSTEM_EMAILS_TEMPLATES_URL = '/system-email-templates';

  const getSystemEmailsTemplates = async (
    params: ListSystemEmailTemplatesRequest,
  ) => {
    try {
      const result = await client.get<
        ListSystemEmailTemplatesResponse,
        AxiosResponse<ListSystemEmailTemplatesResponse>
      >(BASE_SYSTEM_EMAILS_TEMPLATES_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSystemEmail = async (
    emailId: string,
  ): Promise<SystemEmailTemplate> => {
    try {
      const result = await client.get<
        SystemEmailTemplate,
        AxiosResponse<SystemEmailTemplate>
      >(`${BASE_SYSTEM_EMAILS_TEMPLATES_URL}/${emailId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveSystemEmailTemplate = async (
    request: SaveSystemEmailTemplate,
  ): Promise<SystemEmailTemplate> => {
    try {
      const result = await client.post<
        SystemEmailTemplate,
        AxiosResponse<SystemEmailTemplate>
      >(BASE_SYSTEM_EMAILS_TEMPLATES_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateSystemEmailTemplate = async (
    request: UpdateSystemEmailTemplate,
  ): Promise<SystemEmailTemplate> => {
    try {
      const result = await client.patch<
        SystemEmailTemplate,
        AxiosResponse<SystemEmailTemplate>
      >(
        `${BASE_SYSTEM_EMAILS_TEMPLATES_URL}/${request.emailId}`,
        omit(request, 'emailId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteSystemEmailTemplate = async (
    emailId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${BASE_SYSTEM_EMAILS_TEMPLATES_URL}/${emailId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const sendTestSystemEmail = async (
    request: SendTestSystemEmail,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${BASE_SYSTEM_EMAILS_TEMPLATES_URL}/test-email`, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const systemEmailNotifyUrl = (notifyId: string) =>
    `${client.defaults.baseURL}system-email-templates/notify/${notifyId}`;

  return {
    getSystemEmailsTemplates,
    getSystemEmail,
    saveSystemEmailTemplate,
    updateSystemEmailTemplate,
    deleteSystemEmailTemplate,
    sendTestSystemEmail,
    systemEmailNotifyUrl,
  };
};
