import { SECURITY_CULTURE_BOOK_DEMO } from '@/common/constants/external-urls';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { SCLockIcon } from '@/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledCardContainer = styled(FlexContainer)<{ isDemo?: boolean }>`
  grid-area: sc;
  width: 100%;
  min-width: min-content;
  background-color: var(--red-pale);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: var(--medium-padding);

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }
    }
  }
`;

export const RedParagraph = styled.p`
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 700;
  margin-top: var(--medium-padding);
  margin-bottom: 0;
`;
export const StyledH3 = styled.h3`
  font-size: var(--heading-small-font-size);
  line-height: var(--heading-small-line-height);
  font-weight: 700;
  margin: var(--xsmall-padding);
`;

export const StyledP = styled.p`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height-19);
  margin: 0;
  margin-bottom: var(--medium-padding);
  text-align: center;
`;

export const SecurityCultureDemoScore = () => {
  const { t } = useTranslation();

  return (
    <StyledCardContainer
      id="sc-score-card"
      direction="column"
      justify="center"
      align="center"
    >
      <SCLockIcon />
      <RedParagraph>{t('security.culture.notMeasured')}</RedParagraph>
      <StyledH3>{t('security.culture')}</StyledH3>
      <StyledP
        dangerouslySetInnerHTML={{
          __html: t('security.culture.demo.dashboard.p'),
        }}
      />

      <a
        href={SECURITY_CULTURE_BOOK_DEMO}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppButton
          label={t('security.culture.demo.book.dashboard')}
          icon="pi pi-calendar"
        />
      </a>
    </StyledCardContainer>
  );
};
