import classNames from 'classnames';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import styled from 'styled-components';

const StyledInlineMenu = styled(Menu)`
  padding: 0;

  .p-menuitem-link {
    font-size: var(--xsmall-font-size);
    padding: var(--small-padding) var(--default-padding);
    text-transform: uppercase;

    span {
      color: var(--black-main);
      line-height: var(--xsmall-line-height);
    }

    &:hover {
      background-color: var(--gray-pale);
    }
  }

  .active {
    .p-menuitem-link {
      background-color: var(--gray-pale);

      span {
        color: var(--red-main);
      }
    }
  }
`;

export type InlineMenuProps = {
  items: MenuItem[];
  isDisabled?: boolean;
  additionalClass?: string;
};

export const InlineMenu: React.FC<InlineMenuProps> = ({
  items,
  isDisabled,
  additionalClass,
  ...rest
}) => {
  const className = classNames(
    {
      disabled: isDisabled,
    },
    additionalClass,
  );

  return <StyledInlineMenu model={items} className={className} {...rest} />;
};
