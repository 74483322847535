import { LineChart } from '@/ui/charts/line-chart';
import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CardInfo } from './CardInfo';

const StyledCardContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: var(--medium-padding);

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }
    }
  }
`;

type SCOverTimeProps = {
  overTimeParsed: {
    values: number[];
    labels: string[];
  };
};

export const SCOverTime: React.FC<SCOverTimeProps> = ({ overTimeParsed }) => {
  const { t } = useTranslation();
  return (
    <StyledCardContainer id="sc-over-time-card" direction="column" gap={24}>
      <CardInfo
        id="security-culture-over-time"
        title={t('security.culture.overTime')}
        tooltip={t('security.culture.overTime.tooltip')}
      />
      <LineChart
        id="sc-over-time"
        values={overTimeParsed.values}
        labels={overTimeParsed.labels}
      />
    </StyledCardContainer>
  );
};
