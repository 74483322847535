import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { AccountSSOConfig } from '@/client/accounts';
import { LoadingStateType, LoadingStatuses } from '@/common/constants';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccount,
  useImportSSOConfig,
  useSSOConfig,
  useUpdateSSOConfig,
} from '@/hooks/query';
import { useToast } from '@/hooks/useToast';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

export const useSSOHandler = (id: string) => {
  const toast = useToast();
  const { ssoConfig, isLoading, refetch } = useSSOConfig({ accountId: id });
  const { account, isLoading: isAccountLoading } = useAccount({
    accountId: id,
  });
  const [loadingState, setLoadingState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );
  const [initialValues, setInitialValues] = useState<AccountSSOConfig>({
    isSSO: false,
    isManual: true,
    idpEntityId: '',
    singleSignOnServiceUrl: '',
    signingCertificate: '',
    displayName: '',
  });

  useEffect(() => {
    if (!ssoConfig || isLoading || isAccountLoading) return;

    setInitialValues({
      isSSO: account?.meta?.isSSO || false,
      isManual: true,
      idpEntityId: ssoConfig.idpEntityId || '',
      singleSignOnServiceUrl: ssoConfig.singleSignOnServiceUrl || '',
      signingCertificate: ssoConfig.signingCertificate || '',
      displayName: ssoConfig.displayName || '',
    });
  }, [ssoConfig, account]);

  const {
    lastMessage: notifications,
    setNotificationParam: setAccountId,
    notificationParam: notificationAccountId,
    isConnected,
  } = useNotifications(client.accounts.ssoNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleSSONotification(notifications);
  }, [JSON.stringify(notifications)]);

  const handleSSONotification = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['sso.save.success', 'sso.save.error'].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'sso.save.success') {
      toast?.success(t('toast.success'), t('account.sso.config.updated'), 5000);
      await refetch();
    }

    if (messages?.data?.event === 'sso.save.error') {
      toast?.error(t('toast.error'), messages?.data?.payload?.error, 5000);
    }

    setLoadingState(LoadingStatuses.IDLE);
    setAccountId(undefined);
  };

  const importSSOConfig = useImportSSOConfig();
  const handleImportSSOConfig = async (data: AccountSSOConfig) => {
    await importSSOConfig.create({
      accountId: id as string,
      displayName: data.isSSO ? data.displayName : undefined,
      importUrl: data.isSSO ? data.importUrl : undefined,
      isSSO: data.isSSO,
    });
    setLoadingState(LoadingStatuses.LOADING);
  };

  const updateSSOConfig = useUpdateSSOConfig();
  const handleUpdateSSOConfig = async (data: AccountSSOConfig) => {
    await updateSSOConfig.update({
      accountId: id as string,
      displayName: data.isSSO ? data.displayName : undefined,
      idpEntityId: data.isSSO ? data.idpEntityId : undefined,
      singleSignOnServiceUrl: data.isSSO
        ? data.singleSignOnServiceUrl
        : undefined,
      signingCertificate: data.isSSO ? data.signingCertificate : undefined,
      isSSO: data.isSSO,
    });
    setLoadingState(LoadingStatuses.LOADING);
  };

  const handleSubmit = async (data: AccountSSOConfig) => {
    if (id) {
      setAccountId(id);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnected.current) {
          if (data?.isManual) {
            await handleUpdateSSOConfig(data);
          } else {
            await handleImportSSOConfig(data);
          }
        }
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  return {
    initialValues,
    account,
    isLoading,
    isAccountLoading,
    loadingState,
    handleSubmit,
    setLoadingState,
  };
};
