import { LanguagesEnum } from '@/api/enums';
import { Account } from '@/client/accounts';
import { AccountGoogleWorkspaceFormValues } from '@/client/accounts/types/account-google-workspace.type';
import { LoadingStateType } from '@/common/constants';
import { FormikCheckbox, FormikInput, FormikTextarea } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { UserManagementGroupSelection } from '../UserManagementGroupSelection';
import { googleWorkspaceSchema } from './validations/google-workspace';

const StyledField = styled(Field)`
  &.p-inputtextarea {
    min-height: 250px !important;
  }
`;

type GoogleWorkspaceFormProps = {
  initialValues: AccountGoogleWorkspaceFormValues;
  onSubmit: (data: AccountGoogleWorkspaceFormValues) => void;
  onSync: () => void;
  state?: LoadingStateType;
  syncState?: LoadingStateType;
  account: Account;
  disabled?: boolean;
};
export const GoogleWorkspaceForm: React.FC<GoogleWorkspaceFormProps> = ({
  initialValues,
  onSubmit,
  state,
  syncState,
  onSync,
  account,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={googleWorkspaceSchema(
        !!initialValues?.hasClientSecret,
        t,
      )}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, touched, setFieldTouched }) => {
        const [cantSync, setCantSync] = useState(false);
        useEffect(() => {
          const initialValuesWithoutActive = {
            ...initialValues,
            active: null,
          };
          const valuesWithoutActive = { ...values, active: null };
          setCantSync(
            JSON.stringify(initialValuesWithoutActive) !==
              JSON.stringify(valuesWithoutActive),
          );
        }, [Object.values(values)]);

        return (
          <FormContainer width={100}>
            <FlexContainer
              gap={40}
              width="100%"
              justify="space-between"
              align="flex-start"
              wrap="wrap"
            >
              <div className="flex-1">
                <Form>
                  <div className="field w-full">
                    <Field
                      id="clientId"
                      name="clientId"
                      label={t('google.clientId')}
                      className="w-full"
                      value={values.clientId}
                      component={FormikInput}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="clientEmail"
                      name="clientEmail"
                      label={t('google.clientEmail')}
                      className="w-full"
                      value={values.clientEmail}
                      component={FormikInput}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="adminEmail"
                      name="adminEmail"
                      label={t('google.adminEmail')}
                      className="w-full"
                      value={values.adminEmail}
                      component={FormikInput}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="universeDomain"
                      name="universeDomain"
                      label={t('google.universeDomain')}
                      className="w-full"
                      value={values.universeDomain}
                      component={FormikInput}
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <StyledField
                      id="privateKey"
                      name="privateKey"
                      label={t('google.privateKey')}
                      className="w-full"
                      value={values.privateKey}
                      component={FormikTextarea}
                      placeholder={
                        values.hasClientSecret
                          ? t('google.privateKey.set')
                          : undefined
                      }
                      disabled={disabled}
                      required={!values.hasClientSecret}
                      style={{ minHeight: '250px' }}
                    />
                  </div>

                  {!disabled && (
                    <AppButton
                      isSubmit
                      severity="secondary"
                      label={t('button.save')}
                      className="w-2 min-w-min"
                      state={state}
                      isDisabled={!!Object.keys(errors).length || disabled}
                    />
                  )}
                </Form>
              </div>
              <div className="flex-1">
                <CheckboxesContainer>
                  <div className="field-checkbox mb-0">
                    <span id="toggle-wrapper">
                      <Field
                        inputId="active"
                        name="active"
                        label={t('account.google.enable.sync')}
                        value={values.active}
                        component={FormikCheckbox}
                        disabled={
                          !account?.meta?.isMigratedOutsideOfEFront || disabled
                        }
                      />
                    </span>
                  </div>
                  {values.active && (
                    <>
                      {Object.keys(values.mapping).map((key: string) => (
                        <UserManagementGroupSelection
                          title={t('google.mapGroup', {
                            platformName: account?.platformSettings?.name,
                          })}
                          groupLabel={t('google.groupId')}
                          key={key}
                          index={key}
                          values={values.mapping as any}
                          group={values.mapping[key]}
                          account={account}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched as any}
                          setFieldTouched={setFieldTouched}
                          disabled={disabled}
                        />
                      ))}

                      <FlexContainer align="flex-end" justify="space-between">
                        <div>
                          <AppButton
                            icon="pi pi-plus"
                            label={t('azure.newGroup')}
                            severity="secondary"
                            className="white-background"
                            onClick={() =>
                              setFieldValue('mapping', {
                                ...values.mapping,
                                [v4()]: {
                                  groupId: '',
                                  account: account,
                                  branches: [],
                                  groups: [],
                                  language: LanguagesEnum.EN,
                                },
                              })
                            }
                            isDisabled={disabled}
                          />
                        </div>
                        {initialValues.hasGoogleWorkspace && !disabled && (
                          <AppButton
                            icon="pi pi-sync"
                            type="outlined"
                            label={t('azure.force.sync')}
                            className="mt-4 ml-3"
                            onClick={onSync}
                            state={syncState}
                            isDisabled={cantSync || disabled}
                          />
                        )}
                      </FlexContainer>
                    </>
                  )}
                </CheckboxesContainer>
              </div>
            </FlexContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
