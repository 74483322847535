import {
  languageDisplayCode,
  languageDisplayLabel,
  LanguagesEnum,
} from '@/api/enums';
import { Account } from '@/client/accounts';
import {
  CourseAvailabilityOptionsEnum,
  CourseEntityScheduleEnum,
  CourseFilesType,
  CourseStatusAttemptEnum,
  CourseTranslationsType,
  MaterialType,
  ModifiedCourseFilesType,
  ModifiedCourseTranslationsType,
  PosterCategory,
  UserStatusEnum,
} from '@/client/courses';
import { File, FileExtensions, FileType } from '@/client/files';
import { PlannerType } from '@/client/planner/types';
import { Actions, Subjects, User } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { availableLanguages } from '@/common/constants/languages';
import { TranslationFunctionType } from '@/common/types';
import { usePermission } from '@/hooks/usePermission';
import { t } from 'i18next';
import { object, string } from 'yup';
import { branchAdminCheck } from './account.helper';

export const documentContentTypeOptions = (t: TranslationFunctionType) => [
  {
    label: t('material.type.customMaterial'),
    value: MaterialType.CUSTOM_MATERIAL,
  },
  {
    label: t('material.type.resource'),
    value: MaterialType.RESOURCE,
  },
];

export const courseAvailabilityOptions = (t: TranslationFunctionType) => [
  {
    label: t('courses.availability.markedAsAuto'),
    value: CourseAvailabilityOptionsEnum.AUTO,
  },
  {
    label: t('courses.availability.custom'),
    value: CourseAvailabilityOptionsEnum.CUSTOM,
  },
];

export const coursesEnrollToOptions = (
  t: TranslationFunctionType,
  currentUser?: User | null,
  currentAccount?: Account | null,
  visibleForBranchAdmin = false,
) => {
  const { can } = usePermission();

  const isBranchAdmin =
    currentUser &&
    currentAccount &&
    branchAdminCheck(currentUser, currentAccount);

  return [
    ...(isBranchAdmin && !visibleForBranchAdmin
      ? []
      : [
          {
            label: t('account'),
            value: CourseEntityScheduleEnum.ACCOUNT,
          },
        ]),
    {
      label: t('branches'),
      value: CourseEntityScheduleEnum.BRANCH,
    },
    ...(can(Actions.UPDATE, Subjects.COURSE_GROUP_SCHEDULES) && !isBranchAdmin
      ? [
          {
            label: t('groups'),
            value: CourseEntityScheduleEnum.GROUP,
          },
        ]
      : []),
    {
      label: t('users'),
      value: CourseEntityScheduleEnum.USER,
    },
  ];
};

export const inheritedEntityType = (t: TranslationFunctionType) => [
  {
    label: t('account'),
    value: CourseEntityScheduleEnum.ACCOUNT,
  },
  {
    label: t('branch'),
    value: CourseEntityScheduleEnum.BRANCH,
  },
];

export const getScheduleEntity = (source: CourseEntityScheduleEnum) => {
  switch (source) {
    case CourseEntityScheduleEnum.ACCOUNT:
      return t('account');
    case CourseEntityScheduleEnum.BRANCH:
      return t('branch');
    case CourseEntityScheduleEnum.GROUP:
      return t('group');
    case CourseEntityScheduleEnum.USER:
      return t('user');
  }
};

export const courseLanguagesOptions = [
  {
    name: languageDisplayLabel[LanguagesEnum.ZH],
    label: languageDisplayCode[LanguagesEnum.ZH],
    value: LanguagesEnum.ZH,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.CZ],
    label: languageDisplayCode[LanguagesEnum.CZ],
    value: LanguagesEnum.CZ,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.DK],
    label: languageDisplayCode[LanguagesEnum.DK],
    value: LanguagesEnum.DK,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.NL],
    label: languageDisplayCode[LanguagesEnum.NL],
    value: LanguagesEnum.NL,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.EN],
    label: languageDisplayCode[LanguagesEnum.EN],
    value: LanguagesEnum.EN,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.FI],
    label: languageDisplayCode[LanguagesEnum.FI],
    value: LanguagesEnum.FI,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.FR],
    label: languageDisplayCode[LanguagesEnum.FR],
    value: LanguagesEnum.FR,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.DE],
    label: languageDisplayCode[LanguagesEnum.DE],
    value: LanguagesEnum.DE,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.KL],
    label: languageDisplayCode[LanguagesEnum.KL],
    value: LanguagesEnum.KL,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.IT],
    label: languageDisplayCode[LanguagesEnum.IT],
    value: LanguagesEnum.IT,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.LV],
    label: languageDisplayCode[LanguagesEnum.LV],
    value: LanguagesEnum.LV,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.LT],
    label: languageDisplayCode[LanguagesEnum.LT],
    value: LanguagesEnum.LT,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.NO],
    label: languageDisplayCode[LanguagesEnum.NO],
    value: LanguagesEnum.NO,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.PL],
    label: languageDisplayCode[LanguagesEnum.PL],
    value: LanguagesEnum.PL,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.PG],
    label: languageDisplayCode[LanguagesEnum.PG],
    value: LanguagesEnum.PG,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.ES],
    label: languageDisplayCode[LanguagesEnum.ES],
    value: LanguagesEnum.ES,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.SE],
    label: languageDisplayCode[LanguagesEnum.SE],
    value: LanguagesEnum.SE,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.TR],
    label: languageDisplayCode[LanguagesEnum.TR],
    value: LanguagesEnum.TR,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.VI],
    label: languageDisplayCode[LanguagesEnum.VI],
    value: LanguagesEnum.VI,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.AR],
    label: languageDisplayCode[LanguagesEnum.AR],
    value: LanguagesEnum.AR,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.UA],
    label: languageDisplayCode[LanguagesEnum.UA],
    value: LanguagesEnum.UA,
  },
  {
    name: languageDisplayLabel[LanguagesEnum.RO],
    label: languageDisplayCode[LanguagesEnum.RO],
    value: LanguagesEnum.RO,
  },
];

export const getTranslationByLanguage = (
  translations: CourseTranslationsType[],
  language: LanguagesEnum,
  fallbackLang?: LanguagesEnum,
) => {
  const translation = translations.find(
    (object) => object.language === language,
  );

  // Fallback to default course language for missing translations
  const fallbackTranslation = translations.find(
    (object) => object.language === (fallbackLang || LanguagesEnum.EN),
  );

  return {
    name: translation?.name || fallbackTranslation?.name,
    description: translation?.description || fallbackTranslation?.description,
  };
};

export const priorityLanguagesOrder = [
  languageDisplayCode[LanguagesEnum.EN],
  languageDisplayCode[LanguagesEnum.DK],
  languageDisplayCode[LanguagesEnum.SE],
  languageDisplayCode[LanguagesEnum.NO],
];

export const sortLanguagesWithPriority = (languages: string[]) => {
  return languages?.sort((a, b) => {
    const priorityA = priorityLanguagesOrder.indexOf(a);
    const priorityB = priorityLanguagesOrder.indexOf(b);

    if (priorityA !== -1 || priorityB !== -1) {
      if (priorityB === -1) return -1;
      if (priorityA === -1) return 1;

      return priorityA - priorityB;
    }

    return a.localeCompare(b);
  });
};

export const getCourseLanguages = (languages: string[]) => {
  const sortedLanguages = sortLanguagesWithPriority(
    languages?.map((languageCode: string) => languageDisplayCode[languageCode]),
  );
  return sortedLanguages?.join(', ') || '';
};

export const getTranslatedCourseStatusAttempt = (
  status: CourseStatusAttemptEnum | undefined,
) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS: {
      return t('course.attempt.status.inProgress');
    }
    case CourseStatusAttemptEnum.COMPLETED: {
      return t('course.attempt.status.completed');
    }
    case CourseStatusAttemptEnum.FAILED: {
      return t('course.attempt.status.failed');
    }
    default: {
      return undefined;
    }
  }
};

export const getAwarenessReportStatusOptions = (t: TranslationFunctionType) => [
  {
    label: t('course.attempt.status.inProgress'),
    value: UserStatusEnum.IN_PROGRESS,
  },
  {
    label: t('course.attempt.status.completed'),
    value: UserStatusEnum.COMPLETED,
  },
  {
    label: t('course.attempt.status.failed'),
    value: UserStatusEnum.FAILED,
  },
  {
    label: t('course.attempt.status.enrolled'),
    value: UserStatusEnum.ENROLLED,
  },
];

export const getCourseAttemptProgress = (attempt: any) => {
  if (!attempt?.status) {
    return 0;
  }

  // progress_measure have a value between 0 and 1
  const progressMeasure = (attempt?.payload?.progress_measure || 0) * 100;

  return attempt.status !== CourseStatusAttemptEnum.COMPLETED
    ? progressMeasure
    : 100;
};

// This is how data comes from BE, but we transform it to make use of our validation schema
export const modifyCourseTranslations = (
  courseTranslations: CourseTranslationsType[],
) => {
  const modifiedTranslations = {} as any;

  courseTranslations.forEach((item) => {
    const { language, name, description } = item;
    modifiedTranslations[language] = { name, description };
  });

  return modifiedTranslations;
};

export const modifyCourseFiles = (courseFiles: CourseFilesType[]) => {
  const modifiedFiles: ModifiedCourseFilesType = [];

  const sortedCourseFiles = courseFiles.sort(
    (a, b) => (Number(a.priority) || 0) - (Number(b.priority) || 0),
  );

  sortedCourseFiles.forEach((item) => {
    const { language, file, fileMeta, priority } = item;

    const index = priority || 0;
    if (!modifiedFiles[index]) {
      modifiedFiles[index] = {};
    }

    modifiedFiles[index][language] = { file, fileMeta, priority };
  });

  return modifiedFiles;
};

export const parseModifiedCourseFiles = (
  modifiedFiles: ModifiedCourseFilesType,
  data: { selfHosted: boolean; courseLanguages: string[] },
) => {
  const result: CourseFilesType[] = [];

  modifiedFiles.forEach((courseFile, index) => {
    Object.keys(courseFile).forEach((language) => {
      const fileData = courseFile[language];

      if (
        // Check if the language is available in the course for self hosted courses
        (data.selfHosted &&
          data.courseLanguages.includes(language) &&
          fileData?.file) ||
        (!data.selfHosted && fileData?.file) // Skip the previous check if its CC hosted
      ) {
        result.push({
          language,
          file: fileData.file.id as unknown as File,
          priority: index,
        });
      }
    });
  });

  return result;
};

export const sortAlphabetically = (a: any, b: any) => {
  if (a === '' || b === '') return;
  const firstLang = a.split('[')[1];
  const secondLang = b.split('[')[1];

  return firstLang.localeCompare(secondLang);
};

export const isUserLanguageAvailableInCourse = (
  courseFiles: CourseFilesType[],
  lang: LanguagesEnum,
) => courseFiles?.some((file) => file.language === lang);

export const isUserLanguageAvailableinMaterial = (
  availableLanguages: LanguagesEnum[],
  lang: LanguagesEnum,
) => availableLanguages.includes(lang);

export const generateCourseTranslationsInitialValues = () => {
  return modifyCourseTranslations(
    availableLanguages.map((language) => ({
      language,
      name: '',
      description: '',
    })),
  );
};

export const parseModifiedCourseTranslations = (
  modifiedTranslations: ModifiedCourseTranslationsType,
) => {
  return Object.keys(modifiedTranslations).map((language) => ({
    language,
    name: modifiedTranslations[language]?.name || '',
    description: modifiedTranslations[language]?.description || '',
  }));
};

export const generateCourseTranslationsValidationSchema = (
  validOptions: string[],
  t: TranslationFunctionType,
) => {
  const schema = {} as any;

  availableLanguages.forEach((language) => {
    schema[language] = object().shape({
      name: !validOptions.length
        ? string().required(t('error.requiredField'))
        : string().nullable(),
      description: string().nullable(),
    });
  });

  return object()
    .shape(schema)
    .test((value, ctx) => {
      const languages = ctx.parent.courseLanguages;
      const isRequired = languages.some(
        (language: any) => !value[language]?.name,
      );

      const hasEmptyTranslations: boolean = languages.some(
        (language: any) =>
          !value[language]?.name && availableLanguages.includes(language),
      );
      return !hasEmptyTranslations || !isRequired;
    });
};

export const standardCourseLabelOptions = (
  t: TranslationFunctionType,
  isReleaseDateSet: boolean,
) => [
  {
    label: t('planner.type.onboardingPlan'),
    value: PlannerType.ONBOARDING_PLAN,
    disabled: isReleaseDateSet,
  },
  {
    label: t('account.trainingPlan'),
    value: PlannerType.TRAINING_PLAN,
  },
];

export const materialByTypeCreatePath = (type: MaterialType) =>
  RedirectPaths[
    type === MaterialType.RESOURCE
      ? RedirectPathsEnum.RESOURCES_CREATE
      : type === MaterialType.POSTER
      ? RedirectPathsEnum.POSTER_CREATE
      : RedirectPathsEnum.CUSTOM_MATERIALS_CREATE
  ]();

export const updateMaterialByTypePath = (type: MaterialType, id: string) =>
  RedirectPaths[
    type === MaterialType.RESOURCE
      ? RedirectPathsEnum.RESOURCES_EDIT
      : type === MaterialType.POSTER
      ? RedirectPathsEnum.POSTER_EDIT
      : RedirectPathsEnum.CUSTOM_MATERIALS_EDIT
  ](id);

export const materialByTypeListPath = (type: MaterialType) =>
  RedirectPaths[
    type === MaterialType.RESOURCE
      ? RedirectPathsEnum.RESOURCES
      : type === MaterialType.POSTER
      ? RedirectPathsEnum.POSTERS
      : type === MaterialType.CUSTOM_MATERIAL
      ? RedirectPathsEnum.CUSTOM_MATERIALS
      : RedirectPathsEnum.COURSES
  ]();

export const posterCategoriesOptions = (t: TranslationFunctionType) => [
  {
    label: t(`posters.${PosterCategory.COURSE_POSTERS}`),
    value: PosterCategory.COURSE_POSTERS,
  },
  {
    label: t(`posters.${PosterCategory.CYBERSECURITY}`),
    value: PosterCategory.CYBERSECURITY,
  },
  {
    label: t(`posters.${PosterCategory.GDPR}`),
    value: PosterCategory.GDPR,
  },
  {
    label: t(`posters.${PosterCategory.INFOGRAPHICS}`),
    value: PosterCategory.INFOGRAPHICS,
  },
  {
    label: t(`posters.${PosterCategory.INFO_SCREEN}`),
    value: PosterCategory.INFO_SCREEN,
  },
];

export const materialTypeOptions = (t: TranslationFunctionType) => [
  { label: t('posters'), value: MaterialType.POSTER },
  { label: t('resources'), value: MaterialType.RESOURCE },
  { label: t('custom.materials'), value: MaterialType.CUSTOM_MATERIAL },
];

export const filterFilesByLanguageAndExtension = (
  files: CourseFilesType[],
  language: LanguagesEnum,
  extensions: string[],
) =>
  files.filter((file) => {
    const matchesLanguage = file.language === language;
    const matchesExtension = extensions.includes(file.file?.extension || '');
    return matchesLanguage && matchesExtension;
  });

export const getFileType = (extension?: string) => {
  if (!extension) return undefined;

  for (const [type, extensions] of Object.entries(FileExtensions)) {
    if (extensions.includes(extension)) {
      return type as keyof typeof FileType;
    }
  }
  return undefined;
};
