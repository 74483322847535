import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { Account, AccountADFormValues } from '@/client/accounts';
import { LoadingStateType, LoadingStatuses } from '@/common/constants';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useAccount,
  useADConfig,
  useForceADSync,
  useSaveADConfig,
} from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { transformMappingValues } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

export const useAzureADHandler = (id: string) => {
  const toast = useToast();
  const { account, isLoading: isAccountLoading } = useAccount({
    accountId: id,
  });
  const {
    accountAd,
    refetch,
    isLoading: isADLoading,
  } = useADConfig({ accountId: id });
  const currentAccount = useAppSelector(selectCurrentAccount);
  const dispatch = useAppDispatch();
  const forceADSync = useForceADSync();
  const saveADConfig = useSaveADConfig();

  const [syncState, setSyncState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );
  const [initialValues, setInitialValues] = useState<AccountADFormValues>({
    active: false,
    clientId: '',
    clientSecret: '',
    hasClientSecret: undefined,
    tenantId: '',
    mapping: {
      [v4()]: {
        groupId: '',
        account: account,
        branches: [],
        groups: [],
        language: LanguagesEnum.EN,
      },
    },
    isMigratedOutsideOfEFront: false,
    hasAd: false,
  });

  const {
    lastMessage,
    notificationParam: accountId,
    setNotificationParam: setAccountId,
  } = useNotifications(client.adSync.adSyncNotifyUrl);

  useEffect(() => {
    if (!accountId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  useEffect(() => {
    if (!account || currentAccount?.id !== account.id) return;

    updateCurrentAccount(account);
  }, [account, currentAccount]);

  const updateCurrentAccount = (account: Account) => {
    if (JSON.stringify(account) !== JSON.stringify(currentAccount)) {
      dispatch(setCurrentAccount(account));
    }
  };

  useEffect(() => {
    if (!accountAd || !account) return;

    setInitialValues({
      active: accountAd.active || false,
      clientId: accountAd.config?.clientId || '',
      clientSecret: accountAd.config?.clientSecret || '',
      hasClientSecret: !!accountAd.config?.hasClientSecret,
      tenantId: accountAd.config?.tenantId || '',
      mapping: transformMappingValues(
        accountAd.mapping,
        initialValues.mapping,
        currentAccount,
        account,
      ),
      isMigratedOutsideOfEFront: !!account.meta?.isMigratedOutsideOfEFront,
      hasAd:
        !!accountAd.config?.clientId &&
        (!!accountAd.config?.clientSecret ||
          !!accountAd.config?.hasClientSecret) &&
        !!accountAd.config?.tenantId,
    });
  }, [accountAd, account]);

  const handleForceADSync = async (accountId: string) => {
    if (!accountId) return;

    try {
      setSyncState(LoadingStatuses.LOADING);

      setAccountId(accountId);
      await forceADSync.sync(accountId);

      toast?.info(t('toast.info'), t('job.created'));
    } catch (e) {
      setSyncState(LoadingStatuses.IDLE);
      setAccountId(undefined);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleSyncNotification = (
    messages: { data: any; lastEventId: string } | null,
  ) => {
    if (
      !messages?.data?.event ||
      messages.data.event === 'active-directory.account.sync.started' ||
      !['active-directory.account.synced', 'ping'].includes(
        messages?.data?.event,
      ) ||
      (messages?.data?.event === 'ping' && Number(messages?.lastEventId) <= 10)
    ) {
      return;
    }

    if (messages.data.event === 'ping') {
      toast?.info(t('sync.status.pending'), t('sync.inProgress'));
      return;
    }

    if (messages?.data?.error) {
      toast?.error(
        t('toast.error'),
        t('sync.data.error', { error: messages?.data?.error }),
      );
    }

    if (
      messages.data.event === 'active-directory.account.synced' &&
      !messages?.data?.error
    ) {
      toast?.success(t('toast.success'), t('account.azure.synced'));
    }

    setSyncState(LoadingStatuses.IDLE);
    setAccountId(undefined);
    refetch();
  };

  const handleUpdateAzureAD = async (data: AccountADFormValues) => {
    await saveADConfig.create({
      updates: {
        active: data.active,
        config: {
          clientId: data.clientId,
          clientSecret: data?.clientSecret ? data.clientSecret : undefined,
          tenantId: data.tenantId,
        },
        mapping: data.active
          ? Object.values(data.mapping).map((value) => ({
              groupId: value.groupId,
              branches: value.account
                ? []
                : value.branches.map((branch) => ({ id: branch.id })),
              groups: value.groups.map((group) => ({ id: group.id })),
              language: value.language,
            }))
          : undefined,
      },
      accountId: id as string,
    });
  };

  const handleSubmit = async (data: AccountADFormValues) => {
    try {
      if (!id || !data) return;

      if (data.active) {
        setSyncState(LoadingStatuses.LOADING);
        setAccountId(id);
      }

      await handleUpdateAzureAD(data);

      toast?.success(t('toast.success'), t('account.azure.updated'));
      refetch();
    } catch (e) {
      setSyncState(LoadingStatuses.IDLE);
      setAccountId(undefined);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return {
    initialValues,
    syncState,
    saveADConfig,
    accountAd,
    handleSubmit,
    handleForceADSync,
    isAccountLoading,
    isADLoading,
  };
};
