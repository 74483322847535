import { TranslationFunctionType } from '@/common/types';
import { useOverviewReports } from '@/hooks/query';
import { FormatDate } from '@/ui/date';
import { CardReports, FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import styled from 'styled-components';

const StyledCardReportsContainer = styled(CardReports)`
  &#o1 {
    grid-area: o1;
  }
  &#o2 {
    grid-area: o2;
  }
  &#o3 {
    grid-area: o3;
  }
  display: flex;
  flex-direction: column;
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;
`;

type OverviewReportsPropsType = {
  accountId: string;
  t: TranslationFunctionType;
};

export const OverviewReports = ({ accountId, t }: OverviewReportsPropsType) => {
  const { overviewReports, isLoading } = useOverviewReports({ accountId });
  let activePhishingDate;

  if (overviewReports) {
    activePhishingDate = overviewReports.nextPhishingCampaignDate ? (
      <>
        {t('generic.from')}{' '}
        <FormatDate date={overviewReports.nextPhishingCampaignDate} />
      </>
    ) : null;
  }

  return (
    <>
      <StyledCardReportsContainer
        id="o1"
        isLoading={isLoading}
        title={t('reports.active.users')}
        count={overviewReports?.activeUsers}
      />
      <StyledCardReportsContainer
        id="o2"
        isLoading={isLoading}
        title={t('reports.past.campaigns')}
        count={overviewReports?.pastPhishingCampaigns}
      />
      <StyledCardReportsContainer
        id="o3"
        isLoading={isLoading}
        title={t('reports.active.campaigns')}
        count={overviewReports?.activePhishingCampaigns}
        description={activePhishingDate}
      />
    </>
  );
};
