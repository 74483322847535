import { Conditions } from '@/api/enums';
import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  Course,
  MakeAvailableCoursesToAccountFormValues,
  MaterialType,
} from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { AccountAvailabilityModal } from '@/components/courses/modals/AccountAvailabilityModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import {
  useAccount,
  useCourses,
  useMakeAccountsUnavailable,
  useMakeCoursesAvailableToAccount,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import DatatableThumbnail from '@/ui/datatable-thumbnail/DatatableThumbnail';
import { FlexContainer } from '@/ui/styled-ui';
import { getCourseLanguages } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountHeader } from './AccountHeader';

const pathItems = (
  account: Account,
  currentAccount: Account,
  t: (key: string, placeholder?: { name: string }) => string,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: currentAccount?.name,
      url: !currentAccount?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
  ];
  if (currentAccount?.isSystem && account) {
    items.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  items.push({
    label: t('courses'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSES](account?.id),
    template: AppBreadCrumbTemplate,
  });

  return items;
};

export const AccountCoursesPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { setDialogData } = useContext(DialogContext);
  const { id } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { account } = useAccount({ accountId: id });

  const [showMakeAvailableModal, setShowMakeAvailableModal] = useState(false);

  const { skip, take, sort, apiFilters, onSort, setSkip, onPage } = useTable();

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 500),
    [],
  );

  const { isLoading, courses, refetch } = useCourses({
    take,
    skip,
    search: multiSearchValue,
    filters: [
      ...apiFilters,
      ...[
        equal('type', MaterialType.COURSE),
        {
          field: 'availableToAccounts',
          condition: Conditions.EQUAL,
          value: id,
        },
      ],
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const saveAvailableCoursesToAccount = useMakeCoursesAvailableToAccount();
  const saveUnavailableAccounts = useMakeAccountsUnavailable();

  const menuItems = (course: Course) => {
    const menu: MenuItem[] = [
      {
        label: t('generic.accounts.unavailable'),
        icon: 'pi pi-ban',
        command: () =>
          setDialogData({
            show: true,
            type: 'confirmation',
            header: t('dialog.makeUnavailable'),
            message: t('dialog.makeUnavailable.confirm'),
            onAccept: () => handleSubmitUnavailable(course),
          }),
      },
    ];

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('course'),
      sortable: true,
      filterable: false,
      render: (row: Course) => (
        <FlexContainer gap={12}>
          {row?.courseThumbnail?.signedUrl && (
            <DatatableThumbnail
              className={
                moment(row?.releaseDate).isAfter(moment(), 'day')
                  ? 'upcoming-course'
                  : ''
              }
              url={row?.courseThumbnail?.signedUrl}
            />
          )}
          <FlexContainer direction="column" align="flex-start">
            <span>{row?.name}</span>
            {!!row?.courseLanguages?.length && (
              <span className="secondary-text">
                <span>{t('courses.available.languages')}: </span>
                {getCourseLanguages(row?.courseLanguages)}
              </span>
            )}
          </FlexContainer>
        </FlexContainer>
      ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: Course) => (
        <DataTableActions
          disabled={menuItems(row).length < 1}
          menuItems={menuItems(row)}
        />
      ),
    },
  ];

  const toolbar = (
    <DataTableToolbar>
      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          onInput={debouncedSetMultiSearchValue}
          placeholder={t('courses.search.name')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
      <AppButton
        id="make-courses-available-btn"
        label={t('button.makeCoursesAvailable')}
        severity="secondary"
        onClick={() => setShowMakeAvailableModal(true)}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](e.data.id));

  const handleSubmitAvailable = async (
    data: MakeAvailableCoursesToAccountFormValues,
  ) => {
    if (account) {
      try {
        await saveAvailableCoursesToAccount.add({
          courses: data?.courses?.map((course) => course.id),
          accountId: account?.id,
        });
        toast?.success(t('toast.success'), t('courses.available.added'));
        refetch();
        setShowMakeAvailableModal(false);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const handleSubmitUnavailable = async (course: Course) => {
    if (account) {
      try {
        await saveUnavailableAccounts.remove({
          courseId: course?.id,
          accounts: [account?.id],
        });
        toast?.success(t('toast.success'), t('courses.unavailable.added'));
        refetch();
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  return (
    <>
      {!currentAccount || !account ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          <AppBreadCrumb model={pathItems(account, currentAccount, t)} />
          <AccountHeader selectedAccount={account} />

          {account && (
            <AccountAvailabilityModal
              visible={showMakeAvailableModal}
              onSubmit={handleSubmitAvailable}
              onHide={() => setShowMakeAvailableModal(false)}
              account={account}
              availableCoursesLength={courses?.count}
            />
          )}

          <DataTable
            data={courses?.result}
            count={courses?.count as number}
            isLoading={isLoading}
            toolbar={toolbar}
            columns={columns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
            onRowClick={onRowClick}
          />
        </>
      )}
    </>
  );
};
