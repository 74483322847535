import { SurveyCategory } from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppButton } from '@/ui/buttons';
import { BarChart } from '@/ui/charts/bar-chart';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import { parseFloatingNumber } from '@/utils/helpers/ui.helper';
import { isNull } from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  padding-bottom: calc(2 * var(--default-padding));
  position: relative;

  > div {
    position: absolute;
    bottom: calc(-1 * var(--xxsmall-padding));
  }
`;

const StyledChartContainer = styled(FlexContainer)<{ isDemo?: boolean }>`
  .canvas-wrapper {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      bottom: var(--xsmall-padding);
      display: block;
      background-color: var(--gray-dark);
    }
  }

  .demo-overlay {
    position: relative;
    ${({ isDemo }) =>
      isDemo &&
      `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--red-pale);
      opacity: 0.5;
      z-index: 1;
    }
  `}
  }
`;

type SecurityCultureDashboardBarChartProps = {
  item: SurveyCategory;
  surveyId?: string;
  maxValue?: number;
  isLoading?: boolean;
  isMainDashboard?: boolean;
  isDemo?: boolean;
  isEmpty?: boolean;
};

export const SecurityCultureDashboardBarChart: React.FC<
  SecurityCultureDashboardBarChartProps
> = ({
  item,
  surveyId,
  maxValue,
  isLoading,
  isMainDashboard,
  isDemo,
  isEmpty,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const change = item.result - item.previousResult;
  const changeString =
    change > 0
      ? `+ ${parseFloatingNumber(change)}`
      : `- ${parseFloatingNumber(Math.abs(change))}`;

  return (
    <StyledChartContainer
      id={`sc-category-${item.name}`}
      direction="column"
      key={item.id}
      gap={isMainDashboard ? 0 : 10}
      className="relative"
      isDemo={isDemo}
    >
      {isLoading ? (
        <Skeleton
          className="chart"
          shape="rectangle"
          height={isMainDashboard ? '100px' : '200px'}
          width={isMainDashboard ? '70px' : '200px'}
        />
      ) : (
        <BarChart
          id={`sc-scores-${item.id}`}
          value={parseFloatingNumber(item.result)}
          secondaryValue={parseFloatingNumber(item.benchmarkResult)}
          displayXAxis={false}
          height={isMainDashboard ? '100px' : '200px'}
          width={isMainDashboard ? '70px' : '200px'}
          tooltip={
            !isNull(item.previousResult)
              ? t('security.culture.change.after.last.measurement', {
                  change: changeString,
                })
              : undefined
          }
          defaultTooltip={t('security.culture.target.group')}
          benchmarkTooltip={t('security.culture.other.organisations')}
          maxValue={maxValue}
          isMainDashboard={isMainDashboard}
          isDemo={isDemo || isEmpty}
        />
      )}
      <p
        className="m-0"
        style={
          isMainDashboard
            ? { fontSize: '12px', fontWeight: 500, minWidth: 'max-content' }
            : undefined
        }
      >
        {t(`security.culture.category.${item.name}.title`)}
        <i
          id={`sc-scores-${item.id}-info`}
          className="pi pi-info-circle ml-1"
          style={isMainDashboard ? { fontSize: '12px' } : undefined}
        />
      </p>
      <Tooltip
        target={`#sc-scores-${item.id}-info`}
        position="bottom"
        content={t(`security.culture.category.${item.name}.tooltip`)}
      />
      {!isMainDashboard && (
        <StyledContainer direction="column" gap={20}>
          <AppButton
            type="outlined"
            label={t('button.view.more')}
            onClick={() =>
              surveyId &&
              navigate(
                RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_CATEGORY](
                  surveyId,
                  item.name,
                ),
              )
            }
          />
          {item.isLow && (
            <div className="flex justify-content-center">
              <AppChip label={t('generic.low')} type="error" />
            </div>
          )}
        </StyledContainer>
      )}
    </StyledChartContainer>
  );
};
