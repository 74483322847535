import classNames from 'classnames';
import { RadioButton, RadioButtonProps } from 'primereact/radiobutton';
import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

export type FormikRadioProps = {
  field: {
    name: string;
    value: boolean;
    onChange: () => void;
    onBlur: () => void;
  };
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
  shapeType: 'square' | 'circle';
};

export const FormikRadio: React.FC<FormikRadioProps & RadioButtonProps> = ({
  field,
  form,
  label,
  required,
  className,
  shapeType = 'square',
  ...rest
}) => {
  return (
    <StyledLabel
      html-for={field.name}
      className={classNames({
        'p-error': !!form.errors[field.name],
      })}
    >
      <RadioButton
        {...field}
        {...rest}
        className={classNames(className, {
          'p-circle': shapeType === 'circle',
        })}
      />
      {label}
      {required ? <span className="red"> *</span> : ''}
    </StyledLabel>
  );
};
