import { handleAxiosError } from '@/api/helpers';
import { useAccount, useUpdateTableSettings } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AxiosError } from 'axios';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { useEffect } from 'react';
import {
  DataTableColumnsMultiselectProps,
  StyledMultiselect,
} from './DataTableColumnsMultiselect';
import { ColumnsMultiselectOptionType } from './types';
import { getSelectableColumns } from './utils';

export const DataTableAPIColumnsMultiSelect: React.FC<
  DataTableColumnsMultiselectProps
> = ({
  columns,
  visibleColumns,
  setVisibleColumns,
  tableName,
  alwaysVisibleColumns,
  defaultVisibleColumns,
}) => {
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { account, isLoading } = useAccount({
    accountId: currentAccount?.id,
  });
  const { update: updateTableSettings } = useUpdateTableSettings();

  const options: ColumnsMultiselectOptionType[] = getSelectableColumns(
    columns,
    alwaysVisibleColumns,
  );
  const preSelectedOpenColumns =
    account?.personalizedTableViews?.find((s) => s.key === tableName)
      ?.columns ?? defaultVisibleColumns;

  useEffect(() => {
    if (!isLoading) {
      setVisibleColumns(preSelectedOpenColumns);
    }
  }, [JSON.stringify(preSelectedOpenColumns), isLoading]);

  const handleSelectedColumnsChange = async (e: MultiSelectChangeEvent) => {
    const modifiedOptions: string[] = [
      ...e.value.filter(
        (option: string) => !alwaysVisibleColumns.includes(option),
      ),
      ...alwaysVisibleColumns,
    ];

    if (account) {
      try {
        await updateTableSettings({
          accountId: account.id,
          personalizedTableViews: [
            ...(account.personalizedTableViews ?? []).filter(
              (view) => view.key !== tableName,
            ),
            {
              key: tableName,
              columns: modifiedOptions,
            },
          ],
        });
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }

    setVisibleColumns(modifiedOptions);
  };

  return (
    <>
      {!!options.length && !isLoading && (
        <StyledMultiselect
          id="visible-columns"
          options={options}
          value={visibleColumns}
          optionLabel="label"
          optionValue="value"
          onChange={handleSelectedColumnsChange}
        />
      )}
    </>
  );
};
