import { ListSurveyCategoriesResponse } from '@/client/security-culture/types';
import { FlexContainer } from '@/ui/styled-ui';
import { SCDemoCategoriesData } from '@/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CardInfo } from './CardInfo';
import { SCLegend } from './SCLegend';
import { SecurityCultureDashboardBarChart } from './SecurityCultureDashboardBarChart';
import { SecurityCultureDashboardBarChartLoading } from './SecurityCultureDashboardBarChartLoading';

const StyledCardContainer = styled(FlexContainer)<{
  isMainDashboard?: boolean;
  isDemo?: boolean;
}>`
  justify-content: space-between;
  grid-area: sb;
  background-color: ${({ isDemo }) =>
    isDemo ? 'var(--red-pale)' : 'var(--beige-main)'};
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: ${({ isMainDashboard }) =>
        isMainDashboard ? 0 : 'var(--medium-padding)'};

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

type SCScoreBarsProps = {
  categories: ListSurveyCategoriesResponse | undefined;
  surveyId: string | undefined;
  isLoading?: boolean;
  isMainDashboard?: boolean;
  isDemo?: boolean;
  isEmpty?: boolean;
};

export const SCScoreBars: React.FC<SCScoreBarsProps> = ({
  categories,
  surveyId,
  isLoading = false,
  isMainDashboard,
  isDemo,
  isEmpty,
}) => {
  const { t } = useTranslation();
  return (
    <StyledCardContainer
      id="sc-score-bars-card"
      isMainDashboard={isMainDashboard}
      direction="column"
      gap={24}
      className="stretch-children"
      isDemo={isDemo}
    >
      <CardInfo
        id="security-culture-score-bars"
        title={
          isMainDashboard
            ? t('security.culture')
            : t('security.culture.score.bars')
        }
        redTitle={
          isDemo || isEmpty ? t('security.culture.notMeasured') : undefined
        }
        subtitle={
          isMainDashboard
            ? t('security.culture.category.score.subtitle')
            : undefined
        }
        tooltip={
          isMainDashboard
            ? t('security.culture.score.bars.tooltip.main')
            : t('security.culture.score.bars.tooltip')
        }
        style={{ fontSize: '14px', fontWeight: 500 }}
        isDemo={isDemo}
      />
      <FlexContainer
        direction="row"
        justify="flex-start"
        className="children"
        align="flex-start"
      >
        {isEmpty || isDemo ? (
          SCDemoCategoriesData.map((item) => (
            <SecurityCultureDashboardBarChart
              item={item}
              key={item.id}
              maxValue={
                SCDemoCategoriesData.reduce(
                  (max, item) => (item.result > max ? item.result : max),
                  0,
                ) + 2
              }
              isMainDashboard={isMainDashboard}
              isDemo={isDemo}
              isEmpty={isEmpty}
            />
          ))
        ) : isLoading || (!categories && !isEmpty && !isDemo) ? (
          Array.from(Array(6)).map((x) => (
            <SecurityCultureDashboardBarChartLoading key={x} isMainDashboard />
          ))
        ) : (
          <>
            {categories?.result.length ? (
              categories.result.map((item: any) => (
                <SecurityCultureDashboardBarChart
                  item={item}
                  surveyId={surveyId}
                  key={item.id}
                  maxValue={
                    categories?.result?.reduce(
                      (max, item) => (item.result > max ? item.result : max),
                      0,
                    ) + 2
                  }
                  isLoading={isLoading}
                  isMainDashboard={isMainDashboard}
                />
              ))
            ) : (
              <p className="mx-auto">{t('security.culture.no.data')}</p>
            )}
          </>
        )}
      </FlexContainer>
      {isMainDashboard && <SCLegend isMainDashboard isDemo={isDemo} />}
    </StyledCardContainer>
  );
};
