import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { Group } from '@/client/groups';
import { Role } from '@/client/roles';
import { UserOrigin } from './origins.enum';
import { User } from './user.type';

export type ListUsersRequest = ListingRequest;
export type ListRoleRequest = ListingRequest;
export type ListUsersResponse = ListingResponse<User>;
export type ListRoleResponse = ListingResponse<Role>;

export type UserFormState = {
  firstName?: string;
  lastName?: string;
  username?: string;
  name?: string;
  email?: string;
  password?: string;
  role?: Role;
  active?: boolean;
  eFrontSync?: boolean;
  branch?: Branch;
  groups?: Group[];
  language?: string;
  isManual?: boolean;
  assignedAccounts?: Account[];
  origin?: UserOrigin;
  expiresOn?: Date;
  isTemporary: boolean;
  freeTrialCheck?: boolean;
  freeTrialEndsAt?: Date;
  manuallyDeactivated?: boolean;
  manuallyDeactivatedByUserName?: string;
  manuallyDeactivatedOn?: Date;
};

export type SaveUserRequest = {
  accountId: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password?: string;
  active?: true;
  role: string;
  eFrontSync?: boolean;
  branch?: string;
  groups?: string[];
  language?: string;
  origin?: UserOrigin;
  assignedAccounts?: string[];
  expiresOn?: Date;
  freeTrialEndsAt?: Date;
};

export type UpdateUser = {
  email?: string;
  role?: Role;
  active?: boolean;
  firstName?: string;
  lastName?: string;
  username?: string;
  password?: string;
  branch?: string;
  groups?: string[];
  companyName?: string;
  department?: string;
  manager?: string;
  managerEmail?: string;
  country?: string;
  jobTitle?: string;
  mobilePhone?: string;
  officeLocation?: string;
  origin?: UserOrigin;
  assignedAccounts?: string[];
  expiresOn?: Date;
  freeTrialEndsAt?: Date;
  isTemporaryPassword?: boolean;
};

export type UpdateUserRequest = {
  userId: string;
  accountId: string;
  updates: UpdateUser;
};

export type DeleteUserRequest = {
  userId: string;
  accountId: string;
};

export type ExportAccountUsersRequest = {
  filters: FiltersType | undefined;
  exportKey: string;
  accountId: string;
  columns?: string[];
};

export type UsersExportFormValues = {
  isAllData: boolean;
  areAllColumns: boolean;
};

export enum UsersBulk {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum EntityEnum {
  USER = 'user',
  BRANCH = 'branch',
  GROUP = 'group',
  ACCOUNT = 'account',
}
