import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SSOForm } from '@/components/accounts/forms/SSOForm';
import { useSSOHandler } from '@/hooks/integrations/useSSOConfig.hook';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AccountHeader } from './AccountHeader';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
): MenuItem[] => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('sso'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountSSOPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { can } = usePermission();
  const {
    initialValues,
    account,
    isLoading,
    isAccountLoading,
    loadingState,
    handleSubmit,
  } = useSSOHandler(id as string);

  return (
    <>
      {isLoading || isAccountLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && (
            <AppBreadCrumb
              model={getPathItems(account, currentAccount, can, t)}
            />
          )}

          <AccountHeader selectedAccount={account} />

          <h3 className="mt-4 mb-4">{t('sso.config')}</h3>

          <SSOForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            state={loadingState}
            account={account}
            disabled={
              !account?.subdomain ||
              (account?.isSystem &&
                currentUser?.role.code !== SystemRoles.DEVELOPER)
            }
          />
        </>
      )}
    </>
  );
};
