import { FilterNamesEnum } from '@/client/helpers';
import {
  CourseCompletionReportsType,
  userAwarenessReportsType,
} from '@/client/reports';
import {
  SurveyCategory,
  SurveyCategoryEnum,
} from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';

type GetCourseCompletionReportsPropsType = {
  courseCompletionReports: CourseCompletionReportsType;
  t: TranslationFunctionType;
};

export const getCourseCompletionReports = ({
  courseCompletionReports,
  t,
}: GetCourseCompletionReportsPropsType) => {
  return [
    {
      text: t('reports.courses.available'),
      count: courseCompletionReports.availableCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.COURSES](),
      },
    },
    {
      text: t('reports.courses.enrolled'),
      count: courseCompletionReports.enrolledCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE](),
        filters: {},
      },
    },
    {
      text: t('reports.courses.total.enrollments'),
      count: courseCompletionReports.totalEnrolments,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.courses.total.completions'),
      count: courseCompletionReports.totalCompletions,
      // TODO: Change by status `completed` and redirect to awareness when BE support this.
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
  ];
};

type getUserAwarenessReportsPropsType = {
  userAwarenessReports: userAwarenessReportsType;
  t: TranslationFunctionType;
};

export const getUserAwarenessReports = ({
  userAwarenessReports,
  t,
}: getUserAwarenessReportsPropsType) => {
  return [
    {
      text: t('reports.users.with.courses.enrolled'),
      count: userAwarenessReports.usersWithEnrolledCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.users.without.courses'),
      count: userAwarenessReports.usersWithoutCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'no' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.users.completed.all.courses'),
      count: userAwarenessReports.usersWithCompletedCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_COMPLETED_ALL_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.users.not.completed.all.courses'),
      count: userAwarenessReports.usersWithoutCompletedCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_COMPLETED_ALL_COURSES]: 'no' },
        isDashboardLink: true,
      },
    },
  ];
};

export const scormColumnsWidth = {
  THREE: 1696,
  TWO: 1279,
  ONE: 865,
};

export const templatesColumnsWidth = {
  ONE: 1330,
};

export const postersColumnsWidth = {
  THREE: 1950,
  TWO: 1600,
  ONE: 1200,
};

export const SCDemoCategoriesData: SurveyCategory[] = [
  {
    id: 'colleagues-demo',
    name: SurveyCategoryEnum.COLLEAGUES,
    result: 3.0,
    previousResult: 0,
    benchmarkResult: 3.87,
    isLow: false,
  },
  {
    id: 'management-demo',
    name: SurveyCategoryEnum.MANAGEMENT,
    result: 3.7,
    previousResult: 0,
    benchmarkResult: 4.12,
    isLow: false,
  },
  {
    id: 'psychological-safety-demo',
    name: SurveyCategoryEnum.PSYCHOLOGICAL_SAFETY,
    result: 4,
    previousResult: 0,
    benchmarkResult: 4.5,
    isLow: false,
  },
  {
    id: 'resources-demo',
    name: SurveyCategoryEnum.RESOURCES,
    result: 3.1,
    previousResult: 0,
    benchmarkResult: 3.63,
    isLow: false,
  },
  {
    id: 'responsibility-demo',
    name: SurveyCategoryEnum.RESPONSIBILITY,
    result: 3.8,
    previousResult: 0,
    benchmarkResult: 4.15,
    isLow: false,
  },
  {
    id: 'time-demo',
    name: SurveyCategoryEnum.TIME,
    result: 3.1,
    previousResult: 0,
    benchmarkResult: 3.77,
    isLow: false,
  },
];
