export const CYBERPEDIA_URL =
  'https://www.cyberpilot.io/cyberpedia/new-platform-cyberpilot-app';
export const SECURITY_CULTURE_BOOK_DEMO =
  'https://www.cyberpilot.io/book-security-culture-demo-from-cp-app';
export const PHISHING_BOOK_DEMO_EN =
  'https://www.cyberpilot.io/book-phishing-demo-bpo';
export const PHISHING_BOOK_DEMO_DK =
  'https://www.cyberpilot.io/da/book-phishing-demo-bpo';
export const CYBERPEDIA_AD_AND_SSO_URL =
  'https://www.cyberpilot.io/cyberpedia/whitelisting-ad-integration-and-single-sign-on';
export const PHISHING_WHITELISTING_GUIDE =
  'https://www.cyberpilot.io/cyberpedia/whitelisting-overview-for-phishing-simulations';
