import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import {
  Account,
  AccountGoogleSSOConfig,
  displayAccountSyncStatus,
} from '@/client/accounts';
import { AccountGoogleWorkspaceFormValues } from '@/client/accounts/types/account-google-workspace.type';

import { client } from '@/client';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  LoadingStateType,
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { CYBERPEDIA_AD_AND_SSO_URL } from '@/common/constants/external-urls';
import { TranslationFunctionType } from '@/common/types';
import { GoogleSSOForm } from '@/components/accounts/forms/GoogleSSOForm';
import { GoogleWorkspaceForm } from '@/components/accounts/forms/GoogleWorkspaceForm';
import { SSOForm } from '@/components/accounts/forms/SSOForm';
import { useSSOHandler } from '@/hooks/integrations/useSSOConfig.hook';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAccount } from '@/hooks/query';
import {
  useForceGoogleWorkspaceSync,
  useGoogleSSOConfig,
  useGoogleWorkspaceConfig,
  useSavGoogleWorkspaceConfig,
  useUpdateGoogleSSOConfig,
} from '@/hooks/query/account-google-workspace.hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { InlineMenu } from '@/ui/inline-menu';
import { FlexContainer } from '@/ui/styled-ui';
import { transformMappingValues } from '@/utils/helpers';
import { AxiosError } from 'axios';
import moment from 'moment';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { AccountHeader } from './AccountHeader';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
): MenuItem[] => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('account.google'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS_GOOGLE](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountGooglePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const dispatch = useAppDispatch();
  const [loadingState, setLoadingState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );

  const { account, isLoading: isAccountLoading } = useAccount({
    accountId: id,
  });
  const {
    accountGoogleWorkspace,
    refetch: refetchGoogleWorkspaceConfig,
    isLoading: isGoogleWorkspaceLoading,
  } = useGoogleWorkspaceConfig({ accountId: id });
  const {
    ssoConfig,
    isLoading,
    refetch: refetchGoogleSSOConfig,
  } = useGoogleSSOConfig({
    accountId: id,
    enabled: canUseFeature(Subjects.GOOGLE_SSO),
  });
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const menuItems = [
    { label: t('user.manage') },
    ...(canUseFeature(Subjects.GOOGLE_SSO) ? [{ label: t('sso') }] : []),
  ];

  const items = menuItems.map((item, index) => ({
    ...item,
    className: activeIndex === index ? 'active' : '',
    command: () => setActiveIndex(index),
  }));

  const [googleWorkspaceInitialValues, setGoogleWorkspaceInitialValues] =
    useState<AccountGoogleWorkspaceFormValues>({
      active: false,
      clientId: '',
      clientEmail: '',
      adminEmail: '',
      privateKey: '',
      hasClientSecret: false,
      hasGoogleWorkspace: false,
      mapping: {
        [v4()]: {
          groupId: '',
          account: account,
          branches: [],
          groups: [],
          language: LanguagesEnum.EN,
        },
      },
    });

  useEffect(() => {
    if (!accountGoogleWorkspace || !account) return;

    setGoogleWorkspaceInitialValues({
      active: accountGoogleWorkspace.active || false,
      clientId: accountGoogleWorkspace.config?.clientId || '',
      clientEmail: accountGoogleWorkspace.config?.clientEmail || '',
      adminEmail: accountGoogleWorkspace.config?.adminEmail || '',
      universeDomain: accountGoogleWorkspace.config?.universeDomain || '',
      hasClientSecret: !!accountGoogleWorkspace.config?.hasClientSecret,
      privateKey: accountGoogleWorkspace.config?.privateKey || '',
      mapping: transformMappingValues(
        accountGoogleWorkspace.mapping,
        googleWorkspaceInitialValues.mapping,
        currentAccount,
        account,
      ),
      hasGoogleWorkspace:
        !!accountGoogleWorkspace.config?.clientId &&
        (!!accountGoogleWorkspace.config?.privateKey ||
          !!accountGoogleWorkspace.config?.hasClientSecret),
    });
  }, [accountGoogleWorkspace, account]);

  const {
    lastMessage,
    notificationParam: accountId,
    setNotificationParam: setAccountId,
  } = useNotifications(client.googleWorkspace.syncNotifyUrl);

  const forceSync = useForceGoogleWorkspaceSync();
  const [syncState, setSyncState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );

  const handleForceSync = async (accountId: string) => {
    if (!accountId) return;

    try {
      setSyncState(LoadingStatuses.LOADING);

      setAccountId(accountId);
      await forceSync.sync(accountId);

      toast?.info(t('toast.info'), t('job.created'));
    } catch (e) {
      setSyncState(LoadingStatuses.IDLE);
      setAccountId(undefined);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleSyncNotification = (
    messages: { data: any; lastEventId: string } | null,
    refetch: () => void,
    successMessage: string,
  ) => {
    if (
      !messages?.data?.event ||
      messages.data.event === 'google-workspace.account.sync.started' ||
      !['google-workspace.account.synced', 'ping'].includes(
        messages?.data?.event,
      ) ||
      (messages?.data?.event === 'ping' && Number(messages?.lastEventId) <= 10)
    ) {
      return;
    }

    if (messages.data.event === 'ping') {
      toast?.info(t('sync.status.pending'), t('sync.inProgress'));
      return;
    }

    if (messages?.data?.error) {
      toast?.error(
        t('toast.error'),
        t('sync.data.error', { error: messages?.data?.error }),
      );
    }

    if (
      messages.data.event === 'google-workspace.account.synced' &&
      !messages?.data?.error
    ) {
      toast?.success(t('toast.success'), successMessage);
    }

    setSyncState(LoadingStatuses.IDLE);
    setLoadingState(LoadingStatuses.IDLE);
    setAccountId(undefined);
    refetch();
  };

  useEffect(() => {
    if (!accountId) return;
    handleSyncNotification(
      lastMessage,
      refetchGoogleWorkspaceConfig,
      t('account.google.synced'),
    );
  }, [JSON.stringify(lastMessage)]);

  useEffect(() => {
    if (!account || currentAccount?.id !== account.id) return;

    updateCurrentAccount(account);
  }, [account, currentAccount]);

  const updateCurrentAccount = (account: Account) => {
    if (JSON.stringify(account) !== JSON.stringify(currentAccount)) {
      dispatch(setCurrentAccount(account));
    }
  };

  const saveGoogleWorkspaceConfig = useSavGoogleWorkspaceConfig();
  const handleUpdateGoogleWorkspace = async (
    data: AccountGoogleWorkspaceFormValues,
  ) => {
    await saveGoogleWorkspaceConfig.create({
      updates: {
        active: data.active,
        config: {
          clientId: data.clientId,
          clientEmail: data?.clientEmail,
          adminEmail: data.adminEmail,
          privateKey: data?.privateKey ? data.privateKey : undefined,
          universeDomain: data.universeDomain,
        },
        mapping: data.active
          ? Object.values(data.mapping).map((value) => ({
              groupId: value.groupId,
              branches: value.account
                ? []
                : value.branches.map((branch) => ({ id: branch.id })),
              groups: value.groups.map((group) => ({ id: group.id })),
              language: value.language,
            }))
          : undefined,
      },
      accountId: id as string,
    });
  };

  const handleSubmitGoogleWorkspaceConfig = async (
    data: AccountGoogleWorkspaceFormValues,
  ) => {
    try {
      if (!id || !data) return;

      await handleUpdateGoogleWorkspace(data);

      toast?.success(t('toast.success'), t('account.google.updated'));
      refetchGoogleWorkspaceConfig();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const [initialGoogleSSOValues, setInitialGoogleSSOValues] =
    useState<AccountGoogleSSOConfig>({
      isSSO: false,
      displayName: '',
      clientId: '',
      clientSecret: '',
    });

  useEffect(() => {
    if (!ssoConfig || isLoading || isAccountLoading) return;

    setInitialGoogleSSOValues({
      isSSO: account?.meta?.isSSO || false,
      displayName: ssoConfig.displayName || '',
      clientId: ssoConfig.clientId || '',
      clientSecret: ssoConfig.clientSecret || '',
    });
  }, [ssoConfig, account]);

  const {
    lastMessage: notifications,
    setNotificationParam: setAccountIdForSSO,
    notificationParam: accountIdForSSO,
    isConnected,
  } = useNotifications(client.googleWorkspace.syncNotifyUrl);

  useEffect(() => {
    if (!accountIdForSSO) return;
    handleSyncNotification(
      notifications,
      refetchGoogleSSOConfig,
      t('account.google.sso.synced'),
    );
  }, [JSON.stringify(notifications)]);

  const updateSSOConfig = useUpdateGoogleSSOConfig();
  const handleUpdateSSOConfig = async (data: AccountGoogleSSOConfig) => {
    await updateSSOConfig.update({
      accountId: id as string,
      displayName: data.isSSO ? data.displayName : undefined,
      clientId: data.isSSO ? data.clientId : undefined,
      clientSecret: data.isSSO ? data.clientSecret : undefined,
    });
    setLoadingState(LoadingStatuses.LOADING);
  };

  const handleGoogleSSOSubmit = async (data: AccountGoogleSSOConfig) => {
    if (id) {
      setAccountIdForSSO(id);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnected.current) {
          await handleUpdateSSOConfig(data);
        }
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  return (
    <>
      {isLoading || isAccountLoading || isGoogleWorkspaceLoading || !account ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          {currentAccount && (
            <AppBreadCrumb
              model={getPathItems(account, currentAccount, can, t)}
            />
          )}

          <AccountHeader selectedAccount={account} />

          <h3 className="mt-4 mb-4">{t('account.google.config')}</h3>
          <FlexContainer
            className="mb-5"
            gap={24}
            justify="flex-start"
            align="flex-start"
          >
            <InlineMenu items={items} />
            <div>
              {activeIndex === 0 && (
                <p className="m-0">
                  {t('account.google.integration.userManagement.description', {
                    platformName: account?.platformSettings?.name,
                  })}
                </p>
              )}
              {activeIndex === 1 && (
                <p className="m-0">
                  {t('account.google.integration.sso.description', {
                    platformName: account?.platformSettings?.name,
                  })}
                </p>
              )}
              <p className="m-0">
                {t('generic.readReadMoreHere', {
                  platformName: account?.platformSettings?.name,
                })}
                <span className="ml-1 mr-1">&gt;</span>
                <a
                  href={CYBERPEDIA_AD_AND_SSO_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {CYBERPEDIA_AD_AND_SSO_URL}
                </a>
              </p>
            </div>
          </FlexContainer>
          {activeIndex === 0 && id && (
            <>
              {accountGoogleWorkspace?.meta?.lastSync &&
                accountGoogleWorkspace?.meta?.status && (
                  <Message
                    severity="info"
                    text={`${t('sync.lastSync')} ${moment(
                      accountGoogleWorkspace?.meta?.lastSync,
                    ).format(DateFormats.TIMEDATE)}, ${displayAccountSyncStatus(
                      accountGoogleWorkspace?.meta?.status,
                      t,
                    )}`}
                  />
                )}
              {accountGoogleWorkspace?.meta?.lastSyncErrorLog && (
                <Message
                  className="mt-2"
                  severity="warn"
                  text={accountGoogleWorkspace?.meta?.lastSyncErrorLog}
                />
              )}
              <div className="mt-4">
                <GoogleWorkspaceForm
                  initialValues={googleWorkspaceInitialValues}
                  syncState={syncState}
                  onSync={() => handleForceSync(id)}
                  account={account}
                  onSubmit={handleSubmitGoogleWorkspaceConfig}
                />
              </div>
            </>
          )}
          {activeIndex === 1 && (
            <>
              <h3>{t('sso.config')}</h3>
              <GoogleSSOForm
                initialValues={initialGoogleSSOValues}
                onSubmit={handleGoogleSSOSubmit}
                state={loadingState}
                account={account}
                disabled={
                  !account?.subdomain ||
                  (account?.isSystem &&
                    currentUser?.role.code !== SystemRoles.DEVELOPER)
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};
