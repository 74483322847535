import { GroupCondition } from '@/api/enums';
import { equal, group, like, nested } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { MaterialType } from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { UserAwareness, UsersReportType } from '@/client/reports';
import { Actions, Subjects } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import {
  DataTable,
  DataTableAPIColumnsMultiSelect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useBranchesPartialRequest,
  useGroups,
  useUsersAwarenessReport,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { ReportsActionHeader } from '@/pages/reports/ReportsActionHeader';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { FormatDate } from '@/ui/date';
import { CompletionDate } from '@/ui/date/CompletionDate';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  getAwarenessReportStatusOptions,
} from '@/utils/helpers';
import { getBranchName } from '@/utils/helpers/branches.helper';
import {
  determineAttemptStatusColor,
  determineScoreColor,
} from '@/utils/helpers/ui.helper';
import { debounce, uniqueId } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AwarenessReportDatatable: React.FC = () => {
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { can } = usePermission();
  const { t } = useTranslation();
  const isBranchAdmin = branchAdminCheck(currentUser, account);

  const [exportFilters, setExportFilters] = useState<FiltersType | undefined>();
  const [exportSorting, setExportSorting] = useState<
    [string, 'asc' | 'desc'] | null
  >();

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    accountId: account?.id,
    sort: ['name,asc'],
  });

  const { groups, isLoading: isGroupsLoading } = useGroups({
    accountId: account?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  const columns: DataTableColumnType[] = [
    {
      field: 'user.name',
      sortField: 'user.firstName',
      exportName: 'name',
      header: t('user'),
      sortable: true,
      filterable: false,
      className: 'vertical-top',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          <span>{row?.user?.name}</span>
          <span className="secondary-text mt-1">{row?.user?.email}</span>
        </FlexContainer>
      ),
    },
    {
      field: 'user.branch.name',
      exportName: 'branch',
      header: t('branch'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_BRANCH,
        placeholder: t('filter.branch'),
        type: FilterTypeEnum.MULTI_SELECT,
        options: branches?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => {
        const id = uniqueId('branch');
        return (
          <>
            {row?.user.branch?.id ? (
              <>
                {row?.user.branch.parent?.id && (
                  <Tooltip
                    position="mouse"
                    target={`.${id}`}
                    content={getBranchName(row.user.branch)
                      .reverse()
                      .join(' / ')}
                  />
                )}
                <div className={id} style={{ minWidth: 'max-content' }}>
                  {row.user.branch.name}
                </div>
              </>
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </>
        );
      },
    },
    ...(can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin
      ? [
          {
            field: 'user.groupNames',
            exportName: 'groups',
            header: t('group'),
            sortable: true,
            filterable: true,
            filters: {
              placeholder: t('filter.group'),
              field: FilterNamesEnum.USER_GROUPS,
              type: FilterTypeEnum.MULTI_SELECT,
              options: groups?.result?.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            },
            className: 'no-padding',
            render: (row: UserAwareness) => {
              return (
                <>
                  {row?.user.groups?.length ? (
                    row.user.groups.map((group, index) => (
                      <div key={index} style={{ minWidth: 'max-content' }}>
                        {group.name}
                      </div>
                    ))
                  ) : (
                    <div className="group-row">&#8212;</div>
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      field: 'user.meta.companyName',
      header: t('generic.companyName'),
      exportName: 'companyName',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_COMPANY_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.companyName'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.companyName ? (
            <span>{row?.user.meta.companyName}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'user.meta.country',
      header: t('generic.country'),
      exportName: 'country',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_COUNTRY,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.country'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user?.meta?.country ? (
            <span>{row.user.meta.country}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'user.meta.officeLocation',
      header: t('generic.officeLocation'),
      exportName: 'officeLocation',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_OFFICE_LOCATION,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.officeLocation'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.officeLocation ? (
            <span>{row?.user.meta?.officeLocation}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'user.meta.department',
      header: t('generic.department'),
      exportName: 'department',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_DEPARTMENT,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.department'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.department ? (
            <span>{row?.user.meta?.department}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'user.meta.manager',
      header: t('generic.manager'),
      exportName: 'manager',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_MANAGER,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.manager'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.manager ? (
            <span>{row?.user.meta?.manager}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'user.meta.jobTitle',
      header: t('generic.jobTitle'),
      exportName: 'jobTitle',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_JOB,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.jobTitle'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.jobTitle ? (
            <span>{row?.user.meta?.jobTitle}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'user.meta.mobilePhone',
      header: t('generic.mobilePhone'),
      exportName: 'mobilePhone',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_MOBILE_PHONE,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.mobilePhone'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.user.meta?.mobilePhone ? (
            <span>{row?.user.meta?.mobilePhone}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'course.name',
      header: t('reports.courses.or.materials.enrolled'),
      exportName: 'enrolledCourse',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.AWARENESS_REPORTS_COURSE_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.search'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => row?.course?.name,
    },
    {
      field: 'enrollmentDate',
      header: t('generic.enrollDate'),
      exportName: 'enrollDate',
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row?.enrollmentDate ? (
            <FormatDate
              format={DateFormats.TIMEDATE}
              date={row.enrollmentDate}
            />
          ) : (
            <span>&#8212;</span>
          )}
        </>
      ),
    },
    ...(account?.courseDueDays
      ? [
          {
            field: 'dueDate',
            header: t('generic.dueDate'),
            exportName: 'dueDate',
            sortable: true,
            filterable: true,
            filters: {
              label: t('filter.have.overdueCourses'),
              field: FilterNamesEnum.AWARENESS_REPORTS_HAS_DUE_COURSES,
              type: FilterTypeEnum.SELECT_YES_NO,
              placeholder: t('generic.select'),
            },
            className: 'no-padding',
            render: (row: UserAwareness) => (
              <>
                {row?.dueDate ? (
                  <FormatDate
                    format={DateFormats.TIMEDATE}
                    date={row.dueDate}
                  />
                ) : (
                  <span>&#8212;</span>
                )}
              </>
            ),
          },
        ]
      : []),
    {
      field: 'bestAttempt.completed',
      header: t('reports.courses.completionDate'),
      exportName: 'completionDate',
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) =>
        row?.bestAttempt?.completed ? (
          <CompletionDate completionDate={row.bestAttempt.completed} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    // TODO: Uncomment when we find a way to capture the time spent in SCORM files
    /*{
      field: 'timeSpent',
      header: t('reports.timeSpent'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row.course?.timeSpent
            ? convertMinutesIntoHours(row.course?.timeSpent, t)
            : `0 ${t('generic.minutes')}`}
        </>
      ),
    },
    */
    {
      field: 'bestAttempt.status',
      header: t('generic.status'),
      exportName: 'status',
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.AWARENESS_REPORTS_STATUS,
        type: FilterTypeEnum.MULTI_SELECT,
        options: getAwarenessReportStatusOptions(t),
        placeholder: t('generic.status.search'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) =>
        determineAttemptStatusColor(row.bestAttempt?.status, t),
    },
    {
      field: 'bestAttempt.score',
      header: t('reports.score'),
      exportName: 'score',
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row?.course?.type !== MaterialType.COURSE ? (
            <span>&#8212;</span>
          ) : row?.bestAttempt?.score ? (
            determineScoreColor(row.bestAttempt?.status, row.bestAttempt?.score)
          ) : (
            determineScoreColor('enrolled', 0)
          )}
        </>
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter(
      (column) =>
        ![
          'companyName',
          'country',
          'officeLocation',
          'department',
          'manager',
          'jobTitle',
          'mobilePhone',
        ].includes(column.exportName || column.field),
    )
    .map((column) => column.exportName || column.field);
  const alwaysVisibleColumns = ['name'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
          tableName={TableNamesEnum.AWARENESS_REPORTS}
        />
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={t('reports.awareness.search')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>

      <DataTableAPIColumnsMultiSelect
        columns={columns}
        tableName={TableNamesEnum.AWARENESS_REPORTS}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );
  const multiSearchFilters = multiSearchValue.length
    ? [
        group(GroupCondition.OR, [
          nested('user', [like('email', multiSearchValue)]),
          nested('user', [like('name', multiSearchValue)]),
        ]),
      ]
    : [];

  const onSuccess = () => {
    setExportFilters([...apiFilters, ...multiSearchFilters]);
    setExportSorting(sort);
  };

  const addSortNulls = (sort: [string, 'asc' | 'desc'] | null) => {
    if (!sort) return undefined;
    const columnName = sort[0];
    const order = sort[1];

    switch (columnName) {
      case 'bestAttempt.status':
        return order === 'asc' ? 'last' : 'first';
      case 'bestAttempt.score':
        return order === 'asc' ? 'first' : 'last';
      default:
        return undefined;
    }
  };

  const { isLoading, usersAwarenessReport } = useUsersAwarenessReport(
    account?.id || '',
    {
      take,
      skip,
      sortNulls: addSortNulls(sort),
      filters: [
        ...apiFilters,
        ...multiSearchFilters,
        group(GroupCondition.OR, [
          nested('course', [equal('type', MaterialType.COURSE)]),
          nested('course', [equal('type', MaterialType.CUSTOM_MATERIAL)]),
        ]),
      ],
      sort: sort && sort?.length > 0 ? [sort.join(',')] : [],
    },
    onSuccess,
  );

  return (
    <>
      <ReportsActionHeader
        type={UsersReportType.AWARENESS}
        visibleColumns={visibleColumns}
        filters={exportFilters}
        sort={exportSorting}
      />
      <DataTable
        dataKey="dataId"
        rowGroupMode="rowspan"
        groupRowsBy="user.name"
        data={usersAwarenessReport?.result.map((x, i) => ({
          ...x,
          dataId: `${x.id}-${x.course?.id}-${i}`,
        }))}
        count={usersAwarenessReport?.count as number}
        isLoading={isLoading || isBranchesLoading || isGroupsLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        classNames="rowspan-datatable"
      />
    </>
  );
};
