import { Account, SyncStatusEnum } from './account.type';

export type UserManagementMapping = {
  groupId: string;
  branches: UserManagementMappingEntity[];
  groups: UserManagementMappingEntity[];
  language: string | null;
};

export type UserManagementMappingEntity = {
  id: string;
  name: string;
};

export type UserManagementMeta = {
  active: boolean;
  status: SyncStatusEnum;
  lastSync: Date;
  lastSyncErrorLog: string;
  source: Integrations;
};

export type UserManagementFormIndexValueMapping = {
  [index: string]: UserManagementFormMapping;
};

export type UserManagementFormMapping = {
  groupId: string;
  account?: Account;
  branches: UserManagementMappingEntity[];
  groups: UserManagementMappingEntity[];
  language: string | null;
};

export enum Integrations {
  ACTIVE_DIRECTORY = 'active-directory',
  GOOGLE_WORKSPACE = 'google-workspace',
}
