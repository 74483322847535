import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const googleSSOSchema = (t: TranslationFunctionType) =>
  object({
    displayName: string().nullable(),
    clientId: string().required(t('error.requiredField')),
    clientSecret: string().required(t('error.requiredField')),
    isSSO: boolean(),
  });
