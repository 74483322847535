import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { HubspotProperty } from '@/client/hubspot/types';
import {
  SecurityCultureSurvey,
  SurveyStatus,
} from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import {
  SCFeedback,
  SCOverTime,
  SCParticipation,
  SCScoreBars,
  SecurityCultureScore,
} from '@/components/security-culture';
import {
  useSurveyCategories,
  useSurveyFeedback,
} from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import i18n from '@/i18n';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { parseFloatingNumber } from '@/utils/helpers/ui.helper';
import { hubspotTimestamp, hubspotTrack } from '@/utils/hubspot';
import { t } from 'i18next';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SecurityCulturePageActionHeader } from './SecurityCulturePageActionHeader';

const StyledContainer = styled(FlexContainer)`
  @media screen and (max-width: 1450px) {
    & {
      flex-wrap: wrap;

      > div {
        flex: none;
      }
    }
  }
`;

const pathItems = (account: Account) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCultureSurveyPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const queryParams = new URLSearchParams(location.search);
  const [surveyIdToFetch, setSurveyIdToFetch] = useState(
    queryParams.get('surveyId') || undefined,
  );

  const [survey, setVisibleSurvey] = useState<SecurityCultureSurvey>();
  const [allSurveys, setAllSurveys] = useState<SecurityCultureSurvey[]>([]);
  const [overTimeParsed, setOverTimeParsed] = useState<{
    values: number[];
    labels: string[];
  }>({ values: [], labels: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);

  const {
    categories,
    isLoading: IsCategoriesLoading,
    refetch: refetchCategories,
  } = useSurveyCategories({
    surveyId: survey?.id,
    accountId: currentAccount?.id,
  });
  const {
    feedback,
    isLoading: IsFeedbackLoading,
    refetch: refetchFeedback,
  } = useSurveyFeedback({
    take: 3,
    accountId: currentAccount?.id,
    surveyId: survey?.id,
    sort: [['priority', 'asc'].join(',')],
  });

  useEffect(() => {
    const allSurveysReversed = allSurveys?.slice()?.reverse();
    const overTimeParsed = allSurveysReversed.reduce(
      (object, survey) => {
        if (survey.score !== null && survey.score !== undefined) {
          object.values.push(parseFloatingNumber(survey.score));
          object.labels.push(
            moment(survey.date)
              .locale(i18n.language)
              .format(DateFormats.FULL_MONTH_YEAR),
          );
        }

        return object;
      },
      { values: [] as number[], labels: [] as string[] },
    );

    if (survey?.isDemo) {
      // Hardcode over time chart data for demo surveys
      overTimeParsed.values.unshift(...[50, 60]);
      overTimeParsed.labels.unshift(
        ...[
          moment(survey.date)
            .add(-12, 'month')
            .locale(i18n.language)
            .format(DateFormats.FULL_MONTH_YEAR),
          moment(survey.date)
            .add(-6, 'month')
            .locale(i18n.language)
            .format(DateFormats.FULL_MONTH_YEAR),
        ],
      );
    }

    setOverTimeParsed(overTimeParsed);

    refetchCategories();
    refetchFeedback();

    hubspotTrack(HubspotProperty.VISITED_SECURITY_CULTURE, hubspotTimestamp());
  }, [survey]);

  const fetchInitialSurveyId = async () => {
    try {
      setIsLoading(true);
      const response = await client.securityCulture.getSurveys(
        currentAccount?.id,
        {
          sort: ['date,desc'],
          filters: currentUser?.account.isSystem
            ? []
            : [equal('status', SurveyStatus.PUBLISHED)],
          take: 1,
        },
      );

      if (response.result[0]) {
        setSurveyIdToFetch(response.result[0].id);
      } else {
        setShowEmptyScreen(true);
      }
    } catch (e) {
      handleAxiosError(e, toast);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!surveyIdToFetch) {
      fetchInitialSurveyId();
    }
  }, []);

  return (
    <FlexContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      height="100%"
    >
      <div className="w-full pb-4">
        <AppBreadCrumb model={pathItems(currentAccount as Account)} />
        <FlexContainer justify="space-between">
          <h1 className="mb-0">{t('security.culture')}</h1>
        </FlexContainer>
        <FlexContainer direction="column" gap={24}>
          <SecurityCulturePageActionHeader
            surveyIdToFetch={surveyIdToFetch}
            setIsLoading={setIsLoading}
            setVisibleSurvey={(survey: SecurityCultureSurvey) => {
              if (survey.id) {
                navigate(`?surveyId=${survey.id}`, { replace: true });
              }
              setVisibleSurvey(survey);
            }}
            setAllSurveys={setAllSurveys}
            fetchInitialSurveyId={fetchInitialSurveyId}
          />

          {survey &&
            !IsCategoriesLoading &&
            !IsFeedbackLoading &&
            !isLoading && (
              <>
                <StyledContainer
                  align="stretch"
                  justify="space-between"
                  gap={24}
                >
                  <SecurityCultureScore survey={survey} />
                  <SCOverTime overTimeParsed={overTimeParsed} />
                </StyledContainer>

                <SCScoreBars categories={categories} surveyId={survey.id} />

                <StyledContainer gap={24} align="stretch">
                  <SCParticipation survey={survey} />
                  <SCFeedback
                    feedback={feedback}
                    surveyId={survey.id as string}
                  />
                </StyledContainer>
              </>
            )}
        </FlexContainer>
      </div>

      {(IsCategoriesLoading || IsFeedbackLoading || isLoading || !survey) &&
        !showEmptyScreen && (
          <FlexContainer direction="column" className="m-auto">
            <ProgressSpinner />
            <h3>{t('security.culture.survey.loading')}</h3>
          </FlexContainer>
        )}

      {!isLoading &&
        !IsCategoriesLoading &&
        !IsFeedbackLoading &&
        !allSurveys?.length &&
        showEmptyScreen && (
          <FlexContainer direction="column" className="m-auto">
            <h3>{t('security.culture.no.surveys')}</h3>
          </FlexContainer>
        )}
    </FlexContainer>
  );
};
